import { useState } from 'react';
import PropTypes from 'prop-types';
import FormControl from '@mui/material/FormControl';
import MenuItem from '@mui/material/MenuItem';
import MuiSelect from '@mui/material/Select';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme) => ({
  tablePagination: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    gap: '12px',
  },
  label: {
    fontFamily: 'Open Sans',
    fontWeight: 400,
    fontSize: '14px',
    lineHeight: '143%',
    color: theme.palette.secondary.darkBlue,
  },
  selection: {
    marginLeft: '12px',
    '& .MuiFormLabel-root': {
      fontFamily: 'Open Sans !important',
      fontWeight: 600,
      fontSize: '1.15rem',
      lineHeight: '19px',
      color: ({ disabled }) =>
        disabled ? theme.palette.neutrals.greyMed : theme.palette.secondary.darkBlue,
    },
    '& .MuiInputLabel-root.Mui-focused': {
      color: theme.palette.secondary.darkBlue,
    },
    '& .MuiInputLabel-root.Mui-error': {
      color: theme.palette.ui.error,
    },
    '& .MuiInput-input': {
      display: 'flex',
      alignItems: 'center',
      height: '24px !important',
      backgroundColor: '#FFFFFF',
      borderRadius: '8px !important',
      border: '1px solid #FFFFFF',
      padding: 0,
      paddingLeft: '12px',
      fontSize: '14px',
      fontWeight: 400,
      lineHeight: '150%',
      letterSpacing: '0.15px',
      color: theme.palette.secondary.darkBlue,
      '&:focus': {
        backgroundColor: '#FFFFFF',
        border: ({ error }) =>
          !error
            ? `1px solid ${theme.palette.primary.purple}`
            : `1px solid ${theme.palette.ui.error}`,
      },
    },
    '& .MuiInputBase-root': {
      width: '86px',
      fontFamily: 'Open Sans',
      '&:before': {
        borderBottom: 'none !important',
      },
      '&:after': {
        borderBottom: 'none',
      },
    },
    '& .MuiSvgIcon-root': {
      position: 'absolute',
      right: '8px',
      color: theme.palette.primary.purple,
      '&:hover': {
        cursor: 'pointer',
      },
    },
    '& .Mui-focused .MuiSvgIcon-root': {
      transform: 'rotate(180deg)',
    },
  },
  selectOptions: {
    backgroundColor: '#FFFFFF',
    padding: '0 !important',
    '& .MuiMenuItem-root': {
      height: '40px',
      '&:hover': {
        backgroundColor: '#E8E4F9 !important',
      },
    },
    '& .Mui-selected': {
      backgroundColor: `${theme.palette.secondary.lightPurple} !important`,
    },
  },
  paper: {
    marginTop: '4px !important',
    borderRadius: '4px !important',
  },
}));

const TablePagination = ({ label, value, options, onChangeRowsPerPage, ...props }) => {
  const styles = useStyles();
  const [rowsPerPage, setRowsPerPage] = useState(value || options[0]);

  const handleChange = (event) => {
    const { value } = event.target;
    setRowsPerPage(value);
    onChangeRowsPerPage(value);
  };

  return (
    <div className={styles.tablePagination} id="TablePagination">
      <div className={styles.label}>{label}</div>
      <FormControl variant="standard" className={styles.selection}>
        <MuiSelect
          {...props}
          aria-describedby="table-pagniation"
          IconComponent={ExpandMoreIcon}
          classes={{ popoverPaper: styles.paper }}
          MenuProps={{
            classes: {
              paper: styles.paper,
            },
            MenuListProps: {
              classes: {
                root: styles.selectOptions,
              },
            },
          }}
          value={rowsPerPage}
          onChange={handleChange}
          id="TablePagination_MuiSelect"
        >
          {options.map((option) => (
            <MenuItem key={option} value={option} id={`TablePagination_menuItem`}>
              {option}
            </MenuItem>
          ))}
        </MuiSelect>
      </FormControl>
    </div>
  );
};

TablePagination.propTypes = {
  label: PropTypes.string,
  value: PropTypes.number,
  options: PropTypes.arrayOf(PropTypes.number),
  onChangeRowsPerPage: PropTypes.func.isRequired,
};

TablePagination.defaultProps = {
  label: 'View Per Page',
  value: null,
  options: [10, 25, 50, 100],
};

export default TablePagination;
