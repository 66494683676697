import { disableGlobalCursorStyles, Panel, PanelGroup } from 'react-resizable-panels';

import { ResizeHandle } from './ResizeHandle';
import styles from './styles.module.css';
import { RESIZE_SAVE_KEY } from '@/utils';
import { useEffect } from 'react';

export default function ResizeGroupPanel({
  saveId = RESIZE_SAVE_KEY,
  direction = 'vertical',
  firstPanel = null,
  showFirstPanel = true,
  showLastPanel = true,
  className = '',
  children,
}) {
  const resizeHandleClass =
    direction === 'vertical' ? styles.verticalResizeHandle : styles.horizontalResizeHandle;

  useEffect(() => disableGlobalCursorStyles(), []);

  return (
    <PanelGroup autoSaveId={saveId} direction={direction} id={saveId} className={className}>
      {showFirstPanel && (
        <Panel className={styles.Panel} collapsible order={1}>
          {firstPanel}
        </Panel>
      )}
      {showLastPanel && (
        <>
          {showFirstPanel && <ResizeHandle id={saveId} className={resizeHandleClass} />}
          <Panel className={styles.Panel} collapsible defaultSize={60} order={2}>
            {children}
          </Panel>
        </>
      )}
    </PanelGroup>
  );
}
