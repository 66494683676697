import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: 'fit-content',
    height: '22px !important',
    border: '1px solid #d3d3d3',
    borderRadius: '8px !important',
    padding: '0px 8px !important',
    backgroundColor: '#fff',
    cursor: 'pointer',
    '&:focus': {
      border: '1px solid #5d36ef !important',
      outlineStyle: 'none',
    },
  },
  selected: {
    border: '1px solid #5d36ef',
  },
  selectedContainer: {
    backgroundColor: '#ebe5ff !important',
  },
  moreIcon: {
    display: 'flex',
    alignItems: 'center',
    marginLeft: '6px',
    color: theme.palette.primary.purple,
    transform: 'rotate(0deg)',
  },
  open: {
    transform: 'rotate(180deg)',
  },
  paper: {
    marginTop: '8px',
    minWidth: '293px !important',
    maxHeight: '500px !important',
    padding: '8px 0px 8px 12px',
    borderRadius: '8px !important',
    overflow: 'hidden !important',
    boxShadow: '0px 8px 25px rgba(0, 0, 0, 0.15) !important',
  },
  popoverContent: {
    display: 'flex',
    flexDirection: 'column',
    position: 'relative',
    height: '100%',
  },
  searchRoot: {
    marginBottom: '12px !important',
    width: 'calc(100% - 20px)',
  },
  inputRoot: {
    borderRadius: '8px !important',
    border: '1px solid rgba(211, 211, 211, 0.6) !important',
  },
  inputInput: {
    height: '22px !important',
    borderRadius: '8px !important',
    padding: '9px 12px !important',
    borderColor: 'transparent !important',
  },
  inputNotchedOutline: {
    borderColor: 'transparent !important',
  },
  inputFocused: {
    border: `1px solid ${theme.palette.primary.purple} !important`,
  },
  menuContainer: {
    display: 'flex',
    flexDirection: 'column',
    overflowY: 'auto',
    maxHeight: 350,
    '&::-webkit-scrollbar': {
      width: '16px',
    },
    '&::-webkit-scrollbar-track': {
      borderRadius: '100px',
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundClip: 'padding-box',
      borderRadius: '100px',
      border: '5px solid rgba(0, 0, 0, 0.01)',
      minHeight: '80px',
    },
  },
  title: {
    fontWeight: '600 !important',
    fontSize: '14px !important',
    lineHeight: '150% !important',
    letterSpacing: '0.15px !important',
    marginBottom: '8px !important',
    color: `${theme.palette.secondary.darkBlue} !important`,
  },
  menuItem: {
    padding: '4px 0px 4px 0px !important',
    background: 'transparent !important',
  },
  button: {
    paddingLeft: '0 !important',
    paddingRight: '22px !important',
    fontSize: '16px !important',
    fontWeight: '700 !important',
  },
  buttonContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
}));
