import { Stack } from "@mui/material";
import TextField from "../TextField";

const PAGE_NAME = "GoogleAds";

export const GoogleAds = ({
  username,
  onUsernameChange,
  showError,
  disabled,
}) => {
  const handleChangeUsername = (e) => {
    onUsernameChange(e.target.value);
  };

  return (
    <Stack spacing={1} id={`${PAGE_NAME}_container`}>
      <TextField
        label="User Name*"
        value={username ?? ""}
        error={showError && !username}
        helperText={showError && !username ? "Please provide your name." : null}
        onChange={handleChangeUsername}
        id={`${PAGE_NAME}_userName`}
        size="small"
        disabled={disabled}
      />
    </Stack>
  );
};
