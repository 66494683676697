import { useNavigate } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import { useAuth0 } from '@auth0/auth0-react';
import cx from 'clsx';
import { Logout } from '@mui/icons-material';

import { useSettingsStore, userStore } from '@/store';
import { Typography, Button } from '@/components';
import { clearToken, isMobileDetected } from '@/utils';
import { BASE_ROUTES } from '@/utils/types';

import styles from './TopNavigation.module.css';

const LINK_TABS = [
  { title: 'Tickets', link: BASE_ROUTES.main },
  { title: 'Organizations', link: BASE_ROUTES.organizations },
  { title: 'Users', link: BASE_ROUTES.users },
  { title: 'Bulk', link: BASE_ROUTES.bulk },
  { title: 'Schedules', link: BASE_ROUTES.schedules },
  { title: 'Reports', link: BASE_ROUTES.reports },
];

const TopNavigation = () => {
  const { logout } = useAuth0();
  const { routerStore } = useSettingsStore();
  const currentUser = userStore.currentUser;
  const navigate = useNavigate();

  const handleClick = (e, pageLink) => {
    e.preventDefault();
    if (pageLink !== routerStore.currentPage) {
      navigate(`${pageLink}${routerStore.lastPageLink(pageLink)}`);
    } else {
      navigate(pageLink);
    }
  };

  return (
    <>
      <div className={styles.topNavigationLayout}>
        <div className={styles.topNavigationContainer}>
          <a
            tabIndex={0}
            className={styles.link}
            onClick={(e) => handleClick(e, BASE_ROUTES.main)}
            href={`${BASE_ROUTES.main}`}
          >
            <div
              className={cx(styles.logoIconWrapper, {
                [styles.mobileLogoWrapper]: isMobileDetected,
              })}
              id="topNavigation_logoIcon"
            >
              {userStore.organizationLogo && (
                <img src={userStore.organizationLogo} className={styles.logoIcon} alt="logo" />
              )}
            </div>
          </a>
          <div className={styles.role}>Admin</div>

          <div className={styles.subAppsGroup}>
            {LINK_TABS.map(({ title, link }) => (
              <a
                key={title}
                tabIndex={0}
                className={styles.link}
                onClick={(e) => handleClick(e, link)}
                href={link}
              >
                <Typography className={styles.subAppTitle} id={`topNavigation_${title}`}>
                  {title}
                </Typography>
                <div
                  className={cx(styles.subAppTitleUnderline, {
                    [styles.hide]: window.location.pathname !== link,
                  })}
                />
              </a>
            ))}
          </div>

          <div className={styles.actionsWrapper}>
            <div className={styles.settingsContainer}>
              <div tabIndex={0} className={styles.settingsGroup} id="topNavigation_settingsGroup">
                {currentUser.first && (
                  <Typography className={styles.nameLabel} variant="subtitle1">
                    Hello, {currentUser.first}!
                  </Typography>
                )}

                <Button
                  id="topNavigation_settings_logout_menuitem"
                  size="large"
                  variant="tertiary"
                  startIcon={<Logout />}
                  onClick={() => {
                    clearToken();
                    logout({ returnTo: window.location.origin });
                  }}
                >
                  Logout
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default observer(TopNavigation);
