import { useEffect, useRef, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { debounce } from '@mui/material';

import ScheduleTable from './ScheduleTable/ScheduleTable';
import { Button, LoadingIndicator, SearchField, Typography } from '@/components';
import { useSettingsStore, userStore } from '@/store';
import SideMenu from '../SideMenu';
import { BASE_ROUTES, UrlSearch } from '@/utils/types';
import DetailContent from './DetailContent';
import { DEFAULT_SCHEDULE, PAGE_MODE } from '@/utils/constants';

import styles from './SchedulePage.module.css';

const PAGE_NAME = 'SchedulePage';

const SchedulePage = () => {
  const [pageMode, setPageMode] = useState(PAGE_MODE.none);
  const { accountStore, scheduleStore, routerStore } = useSettingsStore();
  const initLoadingRef = useRef(true);
  const prevOrgIdRef = useRef(null);
  const urlParams = routerStore.getSearchChunkParams();
  const selectedOrganizationId = accountStore.selectedAccount?.id;
  const isScheduleLoading = scheduleStore.isLoading || accountStore.isLoading;
  const isCreateMode = pageMode === PAGE_MODE.creating;

  useEffect(() => {
    return () => {
      scheduleStore.dispose();
      userStore.dispose();
    };
  }, []);

  useEffect(() => {
    accountStore.fetchAccounts();
  }, [userStore.organizationId, userStore.isRefresh]);

  useEffect(() => {
    if (routerStore.currentPage !== BASE_ROUTES.schedules) {
      routerStore.setCurrentPage(BASE_ROUTES.schedules);
      return;
    }
    const orgId = Number(urlParams[UrlSearch.organization]);
    const ticketId = Number(urlParams[UrlSearch.createdTicketId]);
    if (orgId === selectedOrganizationId && (!ticketId || isCreateMode)) return;
    if (accountStore.allAccounts.length === 0) return;

    if (ticketId) {
      setPageMode(PAGE_MODE.creating);
      scheduleStore.setSelectedSchedule({ ...DEFAULT_SCHEDULE, ticketId });
    }

    // NOTE: Update account
    accountStore.onSelectAccount(orgId);
    accountStore.updateExpandedAccounts(
      accountStore.allAccounts,
      accountStore.selectedAccount?.path,
    );
    initLoadingRef.current = false;
  }, [accountStore.allAccounts, urlParams]);

  useEffect(() => {
    if (initLoadingRef.current) return;
    if (selectedOrganizationId !== prevOrgIdRef.current) {
      prevOrgIdRef.current = selectedOrganizationId;
      if (!selectedOrganizationId) routerStore.removeSearchParam(UrlSearch.organization);
      else
        routerStore.setSearchChunkParams({
          [UrlSearch.organization]: selectedOrganizationId,
        });
      scheduleStore.setOrganizationId(selectedOrganizationId);
      scheduleStore.fetchScheduleList();
    }
  }, [selectedOrganizationId, scheduleStore.organizationId, initLoadingRef.current]);

  const handleChangeSearch = debounce((event) => {
    scheduleStore.setSearch(event.target.value);
  }, 500);

  if (!userStore.organizationLogo) {
    return <LoadingIndicator fullScreen />;
  }

  return (
    <div id={`${PAGE_NAME}_container`} className={styles.mainContainer}>
      <SideMenu />
      <div id={`${PAGE_NAME}_layout_div`} className={styles.mainLayout}>
        {isScheduleLoading && <LoadingIndicator fullScreen />}
        {!isScheduleLoading && (
          <div className={styles.header}>
            <SearchField
              id={`${PAGE_NAME}_searchField`}
              placeholder="Search"
              value={scheduleStore.search}
              style={{ width: 270 }}
              classes={{
                root: styles.searchRoot,
                container: styles.searchContainer,
              }}
              onChange={handleChangeSearch}
            />
            <Button
              size="small"
              variant="secondary"
              onClick={() => {
                setPageMode(PAGE_MODE.creating);
                scheduleStore.setSelectedSchedule(DEFAULT_SCHEDULE);
              }}
              disabled={isCreateMode}
            >
              Create Schedule
            </Button>
          </div>
        )}
        {!isScheduleLoading && !scheduleStore.filteredScheduleList?.length && (
          <Typography variant="body1">No schedule available</Typography>
        )}
        {!isScheduleLoading && (
          <div className={styles.content}>
            <ScheduleTable />
            {(isCreateMode || scheduleStore.filteredScheduleList.length > 0) && (
              <DetailContent pageMode={pageMode} setPageMode={setPageMode} />
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default observer(SchedulePage);
