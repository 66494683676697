import { useEffect } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { observer } from 'mobx-react-lite';
import { Route, Routes, useNavigate } from 'react-router-dom';

import { userStore, withStore } from './store';
import { Config } from './config';
import { showErrorNotification } from './utils/notificationToasts';
import {
  ErrorBoundary,
  ErrorBoundaryModal,
  LoadingIndicator,
  NotificationsContainer,
} from './components';
import { BASE_ROUTES } from './utils/types';
import {
  Members,
  OrganizationPage,
  TicketsPage,
  TopNavigation,
  BulkPage,
  SchedulePage,
  ReportsPage,
} from './pages';
import {
  getRedirectURLAfterLogin,
  removeRedirectURLAfterLogin,
  saveRedirectURLAfterLogin,
} from './utils';

import './App.css';

function App() {
  const { isLoading, isAuthenticated, loginWithRedirect, getAccessTokenSilently, logout } =
    useAuth0();
  const navigate = useNavigate();
  const searchParam = window.location.search;
  const pagePath = window.location.pathname;
  const urlParams = new URLSearchParams(searchParam);
  const pageSettings = userStore.getPageSettings(pagePath);

  useEffect(() => {
    const getUserMetadata = async () => {
      const domain = Config.REACT_APP_AUTH0_DOMAIN;
      try {
        const accessToken = await getAccessTokenSilently({
          audience: `https://${domain}/api/v2/`,
          scope: 'read:current_user',
        });
        userStore.login(accessToken);
      } catch (err) {
        showErrorNotification(err.preview ?? err.message);
      }
    };

    if (!isLoading && isAuthenticated) getUserMetadata();
  }, [isLoading, isAuthenticated]);

  useEffect(() => {
    if (
      isAuthenticated &&
      (userStore.isUnAuthorizedError || (userStore.currentUser.id && !userStore.isWFLAdminUser))
    ) {
      showErrorNotification('You are not authorized!');
      const timeoutId = setTimeout(() => logout({ returnTo: window.location.origin }), 500);
      return () => clearTimeout(timeoutId);
    }
  }, [
    isAuthenticated,
    userStore.currentUser.id,
    userStore.isWFLAdminUser,
    userStore.isUnAuthorizedError,
  ]);

  if (isLoading) {
    return <LoadingIndicator fullScreen />;
  }

  if (isAuthenticated) {
    const redirectURL = getRedirectURLAfterLogin();
    if (redirectURL) {
      navigate(redirectURL);
      removeRedirectURLAfterLogin();
    }
    if (!userStore.lastUrlLoaded[pagePath] && pageSettings?.path) {
      userStore.setLastUrlLoaded(pagePath);
      if (!searchParam || urlParams.has('code')) {
        navigate(`${pagePath}${pageSettings.path}`);
      }
    }
    if (userStore.isUnAuthorizedError) return null;

    return (
      <ErrorBoundary>
        <NotificationsContainer />
        <div className="App">
          <TopNavigation />
          <Routes>
            <Route path={BASE_ROUTES.main} element={<TicketsPage />} />
            <Route path={BASE_ROUTES.schedules} element={<SchedulePage />} />
            <Route path={BASE_ROUTES.organizations} element={<OrganizationPage />} />
            <Route path={BASE_ROUTES.users} element={<Members />} />
            <Route path={BASE_ROUTES.bulk} element={<BulkPage />} />
            <Route path={BASE_ROUTES.reports} element={<ReportsPage />} />
          </Routes>
        </div>
        {userStore.apiCriticalIssue && (
          <ErrorBoundaryModal
            url={userStore.apiCriticalIssue.url}
            payload={userStore.apiCriticalIssue.payload}
            preview={userStore.apiCriticalIssue.preview}
            statusText={userStore.apiCriticalIssue.statusText}
            isCritical={userStore.apiCriticalIssue.isCritical}
            isReload={userStore.apiCriticalIssue.isReload}
            errorAPIs={userStore.errorAPIs}
          />
        )}
      </ErrorBoundary>
    );
  } else {
    saveRedirectURLAfterLogin(`${pagePath}${searchParam}`);
    loginWithRedirect();
    return null;
  }
}

export default withStore(observer(App));
