import { makeStyles } from '@mui/styles';
import Typography from '../Typography';
import clsx from 'clsx';

const useStyles = makeStyles(() => ({
  infoRow: {
    display: 'flex',
    gap: '8px',
  },

  fullWidth: {
    flex: 1,
  },

  infoLabel: {
    display: 'flex',
    justifyContent: 'space-between',
  },
}));

const InfoRow = ({ label, children, variant = 'body2', labelWidth = 100, fullWidth = false }) => {
  const styles = useStyles();
  return (
    <div className={clsx(styles.infoRow, { [styles.fullWidth]: fullWidth })}>
      <div className={styles.infoLabel} style={{ width: labelWidth }}>
        <Typography variant={variant}>{label}</Typography>:
      </div>
      {children}
    </div>
  );
};

export default InfoRow;
