import PropTypes from 'prop-types';
import cx from 'clsx';
import { Typography as MuiTypography } from '@mui/material';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme) => ({
  common: {
    fontFamily: 'Open Sans',
    color: theme.palette.secondary.darkBlue,
  },
  headline1: {
    fontWeight: 600,
    fontSize: '95px',
    lineHeight: '116.7%',
    letterSpacing: '-1.5px',
  },
  headline2: {
    fontWeight: 600,
    fontSize: '60px',
    lineHeight: '120%',
    letterSpacing: '-0.5px',
  },
  headline3: {
    fontWeight: 600,
    fontSize: '48px',
    lineHeight: '116.7%',
  },
  headline4: {
    fontWeight: 700,
    fontSize: '34px',
    lineHeight: '123.5%',
    letterSpacing: '0.25px',
  },
  headline5: {
    fontWeight: 600,
    fontSize: '24px',
    lineHeight: '133.4%',
  },
  headline6: {
    fontWeight: 600,
    fontSize: '20px',
    letterSpacing: '0.15px',
  },
  subtitle1: {
    fontWeight: 600,
    fontSize: '16px',
    lineHeight: '150%',
    letterSpacing: '0.15px',
  },
  subtitle2: {
    fontWeight: 600,
    fontSize: '14px',
    lineHeight: '157%',
    letterSpacing: '0.1px',
  },
  subtitle3: {
    fontWeight: 400,
    fontSize: '20px',
    lineHeight: '150%',
    letterSpacing: '0.15px',
  },
  body1: {
    fontWeight: 400,
    fontSize: '16px',
    lineHeight: '150%',
    letterSpacing: '0.15px',
  },
  body2: {
    fontWeight: 400,
    fontSize: '14px',
    lineHeight: '143%',
    letterSpacing: '0.17px',
  },
  buttonLarge: {
    fontWeight: 700,
    fontSize: '15px',
    lineHeight: '26px',
    letterSpacing: '0.46px',
    textTransform: 'capitalize',
  },
  buttonMedium: {
    fontWeight: 700,
    fontSize: '14px',
    lineHeight: '24px',
    letterSpacing: '0.4px',
    textTransform: 'capitalize',
  },
  buttonSmall: {
    fontWeight: 600,
    fontSize: '13px',
    lineHeight: '22px',
    letterSpacing: '0.46px',
    textTransform: 'capitalize',
  },
  buttonExtraSmall: {
    fontWeight: 600,
    fontSize: '11px',
    lineHeight: '20px',
    letterSpacing: '0.46px',
    textTransform: 'capitalize',
  },
  caption: {
    fontWeight: 400,
    fontSize: '14px',
    lineHeight: '143%',
    letterSpacing: '0.17px',
  },
  captionBold: {
    fontWeight: 700,
    fontSize: '14px',
    lineHeight: '143%',
    letterSpacing: '0.17px',
  },
  overline: {
    fontWeight: 400,
    fontSize: '14px',
    lineHeight: '143%',
    letterSpacing: '0.17px',
    textTransform: 'uppercase',
  },
  workflow: {
    fontWeight: 600,
    fontSize: '14px',
    lineHeight: '20px',
    letterSpacing: '0.2px',
    textTransform: 'capitalize',
  },
  multiline: {
    overflowWrap: 'break-word !important',
    whiteSpace: 'pre-line !important',
  },
}));

const Typography = ({ children, variant, multiline, match, onChange, ...props }) => {
  const classes = useStyles();
  const { className, ...rest } = props;

  return (
    <MuiTypography
      variant="root"
      className={cx(
        classes.common,
        {
          [classes.headline1]: variant === 'h1',
          [classes.headline2]: variant === 'h2',
          [classes.headline3]: variant === 'h3',
          [classes.headline4]: variant === 'h4',
          [classes.headline5]: variant === 'h5',
          [classes.headline6]: variant === 'h6',
          [classes.subtitle1]: variant === 'subtitle1',
          [classes.subtitle2]: variant === 'subtitle2',
          [classes.subtitle3]: variant === 'subtitle3',
          [classes.body1]: variant === 'body1',
          [classes.body2]: variant === 'body2',
          [classes.buttonLarge]: variant === 'button-large',
          [classes.buttonMedium]: variant === 'button-medium',
          [classes.buttonSmall]: variant === 'button-small',
          [classes.buttonExtraSmall]: variant === 'button-extra-small',
          [classes.overline]: variant === 'overline',
          [classes.caption]: variant === 'caption',
          [classes.captionBold]: variant === 'caption-bold',
          [classes.workflow]: variant === 'workflow',
          [classes.multiline]: multiline,
        },
        className,
      )}
      onClick={onChange}
      {...rest}
    >
      {match ? (
        <>
          {children.slice(0, match[0])}
          <strong>{children.slice(match[0], match[1])}</strong>
          {children.slice(match[1])}
        </>
      ) : (
        children
      )}
    </MuiTypography>
  );
};

Typography.propTypes = {
  children: PropTypes.node,
  variant: PropTypes.oneOf([
    'h1',
    'h2',
    'h3',
    'h4',
    'h5',
    'h6',
    'subtitle1',
    'subtitle2',
    'subtitle3',
    'body1',
    'body2',
    'button-large',
    'button-medium',
    'button-small',
    'button-extra-small',
    'caption-bold',
    'caption',
    'overline',
  ]),
};

Typography.defaultProps = {
  variant: null,
  children: null,
};

export default Typography;
