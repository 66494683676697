import { useState, useEffect } from 'react';
import { observer } from 'mobx-react-lite';

import { Button, LoadingIndicator, TextLink, Typography } from '@/components';
import { PAGE_MODE } from '@/utils/constants';
import { userStore, useSettingsStore } from '@/store';
import { BASE_ROUTES, UrlSearch } from '@/utils/types';
import { getCronError } from '@/utils';
import ScheduleContent from './ScheduleContent';

import styles from './ScheduleContent.module.css';

const PAGE_NAME = 'DetailContent';

const DetailContent = ({ pageMode, setPageMode }) => {
  const { scheduleStore } = useSettingsStore();
  const [isSaveButtonClicked, setIsSaveButtonClicked] = useState(false);
  const [scheduleInfo, setScheduleInfo] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const isViewMode = pageMode === PAGE_MODE.none || pageMode === PAGE_MODE.loading;

  useEffect(() => {
    setScheduleInfo(scheduleStore.selectedSchedule);
  }, [scheduleStore.selectedSchedule]);

  const handleSaveSchedule = async () => {
    if (pageMode === PAGE_MODE.none) {
      setPageMode(PAGE_MODE.editing);
      return;
    }
    setIsSaveButtonClicked(true);
    if (!getCronError(scheduleInfo.cron)) {
      setIsLoading(true);
      await scheduleStore.saveSchedule(scheduleInfo);
      setIsLoading(false);
      setIsSaveButtonClicked(false);
      setPageMode(PAGE_MODE.none);
    }
  };

  const handleDeleteSchedule = async () => {
    if (!getCronError(scheduleInfo.cron)) {
      setIsLoading(true);
      await scheduleStore.deleteSchedule();
      setIsLoading(false);
      setPageMode(PAGE_MODE.none);
    }
  };

  return (
    <div id={`${PAGE_NAME}_container`} className={styles.detailContentContainer}>
      {isLoading && <LoadingIndicator />}
      {Boolean(scheduleInfo) && !isLoading && (
        <div className={styles.scheduleContent}>
          <div className={styles.header}>
            <div className={styles.titleWrapper}>
              <Typography variant="h6" className={styles.truncatedText}>
                {scheduleInfo.cron}
              </Typography>
            </div>
            <div className={styles.buttonWrapper}>
              {pageMode !== PAGE_MODE.creating && !!scheduleInfo.id && (
                <TextLink
                  href={`${BASE_ROUTES.main}?${UrlSearch.organization}=${
                    scheduleStore.organizationId ?? userStore.organizationId
                  }&${UrlSearch.search}=triggered by ${scheduleInfo.ticketId}`}
                  className={styles.linkButton}
                >
                  Monitor
                </TextLink>
              )}
              {pageMode === PAGE_MODE.none && (
                <Button
                  size="small"
                  variant="secondary"
                  onClick={handleDeleteSchedule}
                  id={`${PAGE_NAME}_deleteButton`}
                >
                  Delete
                </Button>
              )}
              <Button
                size="small"
                variant="secondary"
                onClick={handleSaveSchedule}
                id={`${PAGE_NAME}_saveButton`}
              >
                {isViewMode ? 'Edit' : 'Save'}
              </Button>
              {!isViewMode && (
                <Button
                  size="small"
                  variant="tertiary"
                  onClick={() => {
                    setScheduleInfo(scheduleStore.selectedSchedule);
                    setPageMode(PAGE_MODE.none);
                    setIsSaveButtonClicked(false);
                  }}
                  id={`${PAGE_NAME}_cancelButton`}
                >
                  Cancel
                </Button>
              )}
            </div>
          </div>
          <ScheduleContent
            pageMode={pageMode}
            scheduleInfo={scheduleInfo}
            setScheduleInfo={setScheduleInfo}
            showError={isSaveButtonClicked}
          />
        </div>
      )}
    </div>
  );
};

export default observer(DetailContent);
