import { observer } from 'mobx-react-lite';
import cx from 'clsx';
import {
  Table,
  TableCell,
  TableContainer,
  TableHead,
  TableBody,
  TableRow,
  TableSortLabel,
} from '@mui/material';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { DndProvider, useDrag, useDrop } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';

import {
  Typography,
  LoadingIndicator,
  TablePagination,
  Pagination,
  EmptyFilterView,
  DateTimeCell,
  Checkbox,
} from '@/components';
import { ItemTypes } from '@/utils/types';
import { HEADER_COLUMNS, USER_COLUMN_KEYS } from '@/utils/users';
import { CheckboxCell } from '@/components/Table/CheckboxCell';
import { useSettingsStore } from '@/store';

import styles from './MemberTable.module.css';

const PAGE_NAME = 'MemberTable';

const MemberTable = ({ onSelectUser }) => {
  const { membersStore } = useSettingsStore();

  const moveColumn = (dragIndex, hoverIndex) => {
    const newColumnOrder = [...membersStore.columnOrder];
    const dragColumn = newColumnOrder.splice(dragIndex, 1)[0];
    newColumnOrder.splice(hoverIndex, 0, dragColumn);
    membersStore.setColumnOrder(newColumnOrder);
  };

  const Column = ({ index, visible, column }) => {
    const [{ isDragging }, drag] = useDrag(() => ({
      type: ItemTypes.COLUMN,
      item: { index },
      collect: (monitor) => ({
        isDragging: monitor.isDragging(),
      }),
    }));
    const { label, align, sortKey, width } = column;

    const [{ isOver, canDrop }, drop] = useDrop(() => ({
      accept: ItemTypes.COLUMN,
      drop: (item) => {
        const dragIndex = item.index;
        const hoverIndex = index;

        if (dragIndex === hoverIndex || hoverIndex === 0) {
          return;
        }

        moveColumn(dragIndex, hoverIndex);
      },
      canDrop: (item) => item.index && index && item.index !== index,
      collect: (monitor) => ({
        canDrop: !!monitor.canDrop(),
        isOver: !!monitor.isOver(),
      }),
    }));

    if (!visible) return null;

    return (
      <TableCell
        ref={(node) => drag(drop(node))}
        align={align}
        className={cx(styles.headCell, {
          [styles.dropHead]: canDrop && isOver,
        })}
        style={{
          opacity: isDragging ? 0.5 : 1,
          width,
        }}
      >
        {sortKey ? (
          <TableSortLabel
            active={membersStore.isActiveSort(sortKey)}
            direction={membersStore.getSortDirection(sortKey)}
            IconComponent={ArrowDropDownIcon}
            classes={{ icon: styles.sortIcon }}
            onClick={() => membersStore.toggleSort(sortKey)}
          >
            <Typography className={styles.headerLabel}>{label}</Typography>
          </TableSortLabel>
        ) : (
          <>
            {!index && (
              <Checkbox
                checked={membersStore.isSelectedAll}
                indeterminate={membersStore.isIndeterminate}
                onChange={(e) => membersStore.onSelectAllUser(e.target.checked)}
              />
            )}
            {index > 0 && <Typography className={styles.headerLabel}>{label}</Typography>}
          </>
        )}
      </TableCell>
    );
  };

  return (
    <DndProvider backend={HTML5Backend}>
      <div className={styles.memberTable}>
        {membersStore.isLoading ? (
          <LoadingIndicator />
        ) : (
          <div className={styles.tableWrapper}>
            <TableContainer className={styles.tableContainer}>
              <Table stickyHeader size="small" className={styles.table}>
                <TableHead id={`${PAGE_NAME}_table_head`}>
                  <TableRow>
                    {membersStore.columnOrder.map((headerIndex, index) => (
                      <Column
                        key={HEADER_COLUMNS[headerIndex].key}
                        visible={membersStore.hasColumn(HEADER_COLUMNS[headerIndex].key)}
                        index={index}
                        column={HEADER_COLUMNS[headerIndex]}
                      />
                    ))}
                  </TableRow>
                </TableHead>

                <TableBody id={`${PAGE_NAME}_table_body`}>
                  {membersStore.filteredCurrentPageMembers.map((member) => {
                    const { id, createdOn, match, matchedField } = member;
                    return (
                      <TableRow
                        key={id}
                        className={cx(styles.row, {
                          [styles.selectedRow]: membersStore.selectedIds.includes(id),
                        })}
                        id={`members_table_row`}
                        onClick={() => {
                          onSelectUser(member);
                        }}
                      >
                        {membersStore.columnOrder.map((index) => {
                          const key = HEADER_COLUMNS[index].key;
                          if (!membersStore.hasColumn(key)) return null;
                          if (key === USER_COLUMN_KEYS.select)
                            return (
                              <CheckboxCell
                                key={key}
                                value={member[key]}
                                onChange={(e) => {
                                  e.stopPropagation();
                                  membersStore.onToggleSelectUser(member.id);
                                  if (membersStore.selectedUsers.length === 1)
                                    onSelectUser(membersStore.selectedUsers[0]);
                                }}
                              />
                            );
                          if (key === USER_COLUMN_KEYS.id)
                            return (
                              <TableCell key={key} className={styles.bodyCell}>
                                <Typography variant="body2" match={matchedField === key && match}>
                                  {`${id}`}
                                </Typography>
                              </TableCell>
                            );
                          if (key === USER_COLUMN_KEYS.createdOn)
                            return (
                              <DateTimeCell
                                id={`${PAGE_NAME}_created`}
                                key={key}
                                column="created"
                                value={createdOn}
                              />
                            );
                          if (
                            key === USER_COLUMN_KEYS.active ||
                            key === USER_COLUMN_KEYS.admin ||
                            key === USER_COLUMN_KEYS.assignable ||
                            key === USER_COLUMN_KEYS.contributor
                          )
                            return <CheckboxCell key={key} value={member[key]} />;
                          return (
                            <TableCell key={key} className={styles.bodyCell}>
                              <Typography
                                className={styles.truncatedText}
                                match={matchedField === key && match}
                              >
                                {member[key]}
                              </Typography>
                            </TableCell>
                          );
                        })}
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </TableContainer>
            {membersStore.allFilteredMembers.length === 0 &&
            (membersStore.search || membersStore.allFilteredCount > 0) ? (
              <EmptyFilterView
                onClick={() => {
                  membersStore.clearFilter();
                }}
                id="memberPage_noFilteredLog"
              />
            ) : (
              <div className={styles.pagination}>
                <TablePagination
                  label="View Per Page"
                  id={`${PAGE_NAME}_paginationDropdown`}
                  value={membersStore.pageRows}
                  options={[10, 20, 50, 100, 1000]}
                  onChangeRowsPerPage={(v) => membersStore.setPageRows(v)}
                />
                <Pagination
                  count={membersStore.paginationCounts}
                  page={membersStore.currentPage}
                  onChange={(e, v) => membersStore.setPageNumber(v)}
                  id={`${PAGE_NAME}_pagination`}
                />
              </div>
            )}
          </div>
        )}
      </div>
    </DndProvider>
  );
};

export default observer(MemberTable);
