import { action, computed, makeObservable, observable } from 'mobx';
import { ColumnType } from '@/utils/types';

const HEADER_COLUMNS = [
  {
    key: ColumnType.select,
    label: '',
    align: 'left',
    width: 28,
  },
  {
    key: ColumnType.id,
    label: 'Id',
    sortKey: 'id',
    align: 'left',
    width: 72,
  },
  {
    key: ColumnType.state,
    label: 'Status',
    sortKey: ColumnType.state,
    align: 'left',
    width: 100,
  },
  {
    key: ColumnType.accountId,
    label: 'Account',
    align: 'left',
    sortKey: 'organization',
    width: 120,
  },
  {
    key: ColumnType.intentType,
    label: 'Service',
    sortKey: 'Workflow',
    align: 'left',
    width: 120,
  },
  {
    key: ColumnType.title,
    label: 'Title',
    sortKey: ColumnType.title,
    align: 'left',
    width: 350,
  },
  {
    key: ColumnType.createdDate,
    label: 'Date Created',
    align: 'left',
    width: 70,
    sortKey: 'createdOn',
  },
  {
    key: ColumnType.lastComment,
    label: 'Updated',
    align: 'left',
    width: 70,
    sortKey: 'updatedOn',
  },
  {
    key: ColumnType.automation,
    label: 'Automation',
    align: 'left',
    sortKey: 'automation',
    width: 80,
  },
  {
    key: ColumnType.buildError,
    label: 'Issue',
    sortKey: ColumnType.buildError,
    align: 'left',
    width: 350,
  },
];

export class TableStore {
  hoveredRow = null;
  selectedRow = null;
  rowsPerPage = 100;
  pageNumber = 1;
  sortKey = 'updatedOn';
  sortDirection = 'desc';

  constructor(accountStore, filterStore) {
    makeObservable(this, {
      headerColumns: computed,
      hoveredRow: observable,
      pageNumber: observable,

      selectedRow: observable,
      setSelectedRow: action,

      sortKey: observable,
      setSortKey: action,
      sortDirection: observable,
      setSortDirection: action,

      rowsPerPage: observable,
      setRowsPerPage: action,
    });

    this.accountStore = accountStore;
    this.filterStore = filterStore;
  }

  setSortKey(key) {
    if (key) {
      this.sortKey = key;
    }
  }

  setSortDirection(dir) {
    if (dir) {
      this.sortDirection = dir;
    }
  }

  // NOTE: Get table header columns
  get headerColumns() {
    let headers;

    if (this.accountStore.hasChildren) {
      headers = HEADER_COLUMNS.filter((item) => item.key !== ColumnType.select);
    } else {
      headers = HEADER_COLUMNS.filter(
        (item) => item.key !== ColumnType.accountId && item.key !== ColumnType.select,
      );
    }
    if (this.filterStore.getSelectedCount(ColumnType.priority) !== 0) {
      return headers;
    } else {
      return headers.filter((item) => item.key !== ColumnType.priority);
    }
  }

  // NOTE: Table actions
  onMouseEnter(logId) {
    this.hoveredRow = logId;
  }

  onMouseLeave() {
    this.hoveredRow = null;
  }

  setSelectedRow(logId) {
    this.selectedRow = logId;
  }

  // NOTE: Sort table rows by order
  toggleSort(sortKey) {
    let direction = 'asc';
    if (this.sortKey === sortKey) {
      direction = this.sortDirection === 'asc' ? 'desc' : 'asc';
    } else {
      direction = 'desc';
    }
    this.sortKey = sortKey;
    this.sortDirection = direction;
  }

  // NOTE: Check if current column is active
  isActiveSort(sortKey) {
    return this.sortKey === sortKey;
  }

  getSortDirection(sortKey) {
    if (this.sortKey === sortKey) return this.sortDirection;
    return 'asc';
  }

  // NOTE: Pagination
  setRowsPerPage(rows) {
    this.rowsPerPage = rows;
  }

  setPageNumber(page) {
    this.pageNumber = page;
  }

  dispose() {
    // TBD
  }
}
