import { useEffect, useMemo, useState } from 'react';
import { observer } from 'mobx-react-lite';

import { Button, IconTabs, LoadingIndicator, Typography } from '@/components';
import { isValidateEmail, showErrorNotification, showSuccessNotification } from '@/utils';
import { userApi } from '@/api';
import { PAGE_MODE } from '@/utils/constants';
import UserContent from './UserContent';
import { useSettingsStore } from '@/store';

import styles from './DetailContent.module.css';

const PAGE_NAME = 'UserContent';

const DEFAULT_USER_INFO = {
  first: '',
  last: '',
  email: '',
  isActive: true,
  isAdmin: false,
  isAssignable: true,
  isContributor: true,
  profile: { emailsEnabled: false },
};

const DetailContent = ({ pageMode, setPageMode, defaultOrgId, saveUser }) => {
  const { membersStore } = useSettingsStore();
  const editingMember = membersStore.firstSelectedUser;
  const [isDeveloperSettings, setIsDeveloperSettings] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [showError, setShowError] = useState(false);
  const isOpen = Boolean(editingMember);
  const isViewMode = pageMode === PAGE_MODE.none || pageMode === PAGE_MODE.loading;
  const isMultiple = membersStore.selectedUsers.length > 1;

  const tabs = useMemo(
    () => [
      {
        label: 'Details',
        value: false,
      },
      {
        label: 'Developer',
        value: true,
        disabled: isMultiple || pageMode === PAGE_MODE.creating,
      },
    ],
    [pageMode, isMultiple],
  );
  const handleChangeMemberInfo = (fieldValue) => {
    if (isMultiple) {
      membersStore.updateUnionInfo(fieldValue);
    } else {
      membersStore.updateSelectedUser(fieldValue);
    }
  };

  const handleToggleAccess = async () => {
    setIsSaving(true);
    try {
      const access = await userApi.toggleAPIAccess(editingMember.id, !editingMember.enabled);
      handleChangeMemberInfo(access);
    } catch (err) {
      showErrorNotification(err.preview ?? err.message);
    }
    setIsSaving(false);
  };

  useEffect(() => {
    if (isMultiple) setIsDeveloperSettings(false);
  }, [isMultiple]);

  useEffect(() => {
    const fetchAPIAccess = async () => {
      if (editingMember?.id) {
        setIsLoading(true);
        try {
          const settings = await userApi.getAPIAccess(editingMember.id);
          membersStore.updateSelectedUser(settings);
        } catch {}
        setIsLoading(false);
        setPageMode(PAGE_MODE.none);
      } else {
        membersStore.setSelectedUser(DEFAULT_USER_INFO);
      }
    };
    if (pageMode === PAGE_MODE.loading) {
      fetchAPIAccess();
    } else if (pageMode === PAGE_MODE.creating) {
      setIsDeveloperSettings(false);
      membersStore.setSelectedUser({
        ...DEFAULT_USER_INFO,
        organizationId: defaultOrgId ?? editingMember.organizationId,
      });
    }
  }, [pageMode]);

  const handleClickSaveButton = async () => {
    if (pageMode === PAGE_MODE.none) {
      setPageMode(PAGE_MODE.editing);
      return;
    }
    setShowError(true);
    const { organizationId, first: firstName, last: lastName, email } = editingMember;
    if (
      isMultiple ||
      (firstName && lastName && email && organizationId && isValidateEmail(email))
    ) {
      setIsLoading(true);
      setIsSaving(true);
      if (isMultiple) {
        await membersStore.saveSelectedUsers();
        setPageMode(PAGE_MODE.none);
      } else {
        await saveUser(editingMember);
        setPageMode(PAGE_MODE.loading);
      }
      setIsSaving(false);
      setIsLoading(false);
    }
  };

  const handleClickReinviteButton = async () => {
    setIsSaving(true);
    try {
      await userApi.reinviteUser(editingMember.id);
      handleChangeMemberInfo({ isActive: true });
      membersStore.updateSelectedUser({ isActive: true });
      showSuccessNotification(`Reinvited Successfully to ${editingMember.email}!`);
    } catch (err) {
      showErrorNotification(err.preview ?? err.message);
    }
    setIsSaving(false);
  };

  return (
    <div id={`${PAGE_NAME}_container`} className={styles.detailContentContainer}>
      {isOpen && isLoading && <LoadingIndicator />}
      {Boolean(editingMember) && !isLoading && (
        <div className={styles.userContent}>
          <Typography variant="h6" className={styles.truncatedText}>
            {pageMode === PAGE_MODE.creating
              ? editingMember.name
              : isMultiple
              ? `${membersStore.selectedUsers.length} users selected`
              : `${editingMember.id}: ${editingMember.name}`}
          </Typography>
          <div className={styles.header}>
            {!isMultiple && (
              <IconTabs
                tabArray={tabs}
                value={isDeveloperSettings}
                flexContainer={styles.tabContainer}
                onChange={(_, newValue) => setIsDeveloperSettings(newValue)}
              />
            )}

            <div className={styles.buttonsWrapper}>
              {!isMultiple && !editingMember?.isActive && (
                <Button
                  size="small"
                  variant="secondary"
                  onClick={handleClickReinviteButton}
                  id={`${PAGE_NAME}_inviteUserButton`}
                  sx={{ mr: 0.5 }}
                  disabled={isSaving}
                >
                  Reinvite
                </Button>
              )}
              {(!isDeveloperSettings || isViewMode) && (
                <Button
                  size="small"
                  variant="secondary"
                  onClick={handleClickSaveButton}
                  id={`${PAGE_NAME}_saveUserButton`}
                  disabled={isSaving}
                >
                  {isViewMode ? 'Edit' : 'Save'}
                </Button>
              )}
              {!isMultiple && isDeveloperSettings && pageMode === PAGE_MODE.editing && (
                <Button
                  size="small"
                  variant="secondary"
                  onClick={handleToggleAccess}
                  disabled={isSaving}
                >
                  {editingMember.enabled ? 'Disable' : 'Enable'}
                </Button>
              )}
              {!isViewMode && (
                <Button
                  size="small"
                  variant="tertiary"
                  onClick={() => {
                    membersStore.refreshSelectedUsers();
                    setPageMode(PAGE_MODE.none);
                    setShowError(false);
                  }}
                  id={`${PAGE_NAME}_cancelInviteButton`}
                  disabled={isSaving}
                >
                  Cancel
                </Button>
              )}
            </div>
          </div>
          <UserContent
            showError={showError}
            setIsSaving={setIsSaving}
            isEditingMode={pageMode !== PAGE_MODE.none}
            isDeveloperSettings={isDeveloperSettings}
          />
        </div>
      )}
    </div>
  );
};

export default observer(DetailContent);
