import { useCallback, useEffect, useMemo, useState } from 'react';
import { CloseFullscreen, OpenInFull } from '@mui/icons-material';
import { observer } from 'mobx-react-lite';
import cx from 'clsx';

import {
  Button,
  IconButton,
  IconTabs,
  LoadingIndicator,
  ResizeGroupPanel,
  Typography,
} from '@/components';
import { InformationPanel } from '../InformationPanel';
import { useMainStore } from '../../stores/useMainStore';
import { DetailWorkflowContent } from '../DetailWorkflowContent';
import ScheduleTable from '../ScheduleTable';
import TriggersTable from '../TriggersTable';
import { TICKET_TABS } from '@/utils/constants';
import { TICKET_DETAIL_RESIZE_SAVE_KEY } from '@/utils';
import { CommentContent } from '../CommentContent';

import styles from './DetailTicketContent.module.css';

const PAGE_NAME = 'DetailTicketContent';

export const DetailTicketContent = observer(({ open, ticket, onExecute }) => {
  const [data, setData] = useState(null);
  const { detailStore } = useMainStore();

  const tabs = useMemo(
    () =>
      [
        { label: 'Details', value: TICKET_TABS.details, visible: detailStore.isFullWidth },
        { label: 'Discussions', value: TICKET_TABS.discussions, visible: true },
        { label: 'Configuration', value: TICKET_TABS.configuration, visible: true },
        { label: 'Schedules', value: TICKET_TABS.schedules, visible: true },
      ].filter((tab) => tab.visible),
    [detailStore.isFullWidth],
  );

  useEffect(() => {
    if (open) {
      setData(ticket);
    }
  }, [open, ticket]);

  const updateData = useCallback(
    (fieldValue) => setData((prev) => ({ ...prev, ...fieldValue })),
    [],
  );
  const onCancel = useCallback(() => setData(ticket), [ticket]);

  if (!open) return null;

  return (
    <div
      id={`${PAGE_NAME}_container`}
      className={cx(styles.ticketContentContainer, {
        [styles.isFullScreen]: detailStore.isFullScreen,
      })}
    >
      {detailStore.isLoading && <LoadingIndicator />}
      {!detailStore.isLoading && (
        <>
          <div className={styles.header}>
            <Typography variant="h6" className={styles.truncatedText}>
              {data?.title}
            </Typography>
          </div>
          <div className={styles.tabContainer}>
            <IconTabs
              activeColor=""
              tabArray={tabs}
              value={detailStore.selectedTab}
              flexContainer={styles.tabContainer}
              onChange={(_, newValue) => detailStore.setSelectedTab(newValue)}
            />
            <IconButton size="small" onClick={() => detailStore.toggleFullWidth()}>
              {detailStore.isFullWidth ? (
                <CloseFullscreen fontSize="small" />
              ) : (
                <OpenInFull fontSize="small" />
              )}
            </IconButton>
          </div>
          {Boolean(data) && (
            <div className={styles.taskContent}>
              <ResizeGroupPanel
                saveId={TICKET_DETAIL_RESIZE_SAVE_KEY}
                direction="horizontal"
                firstPanel={
                  <InformationPanel
                    variant={data.objectType}
                    data={data}
                    updateData={updateData}
                    onCancel={onCancel}
                  />
                }
                showFirstPanel={!detailStore.isFullWidth}
                className={styles.fitContent}
              >
                <div className={styles.contentWrapper}>
                  {detailStore.isFullWidth && detailStore.isSelectedTab(TICKET_TABS.details) && (
                    <InformationPanel
                      variant={data.objectType}
                      data={data}
                      updateData={updateData}
                      onCancel={onCancel}
                    />
                  )}
                  {detailStore.isSelectedTab(TICKET_TABS.discussions) && (
                    <CommentContent
                      comments={data.discussions}
                      updateComment={(id, title, originalFiles, addingFiles, removingFiles) => {
                        detailStore.updateComment(id, title, addingFiles, removingFiles);
                      }}
                      deleteComment={(id, files) => {
                        detailStore.deleteComment(id, files);
                      }}
                    />
                  )}
                  {detailStore.isSelectedTab(TICKET_TABS.configuration) && (
                    <DetailWorkflowContent content={data.config} />
                  )}
                  {detailStore.isSelectedTab(TICKET_TABS.schedules) && (
                    <>
                      <ScheduleTable />
                      <TriggersTable rows={detailStore.triggeredTickets} />
                    </>
                  )}
                </div>
              </ResizeGroupPanel>
            </div>
          )}
        </>
      )}
    </div>
  );
});
