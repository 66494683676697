import { useEffect, useState } from 'react';
import { IconButton, Stack } from '@mui/material';
import TextField from '../TextField';
import { organizationApi } from '@/api';
import { CopyOutlineIcon } from '@/assets/icons';
import { PLATFORM_ITEM_WIDTH } from '@/utils/constants';
import PasswordField from '../PasswordField';
import InfoRow from '../InfoRow';

const PAGE_NAME = 'AmazonSellerCentralLogin';

export const AmazonSellerCentral = ({
  username,
  onUsernameChange,
  showError,
  defaultValue,
  onChange,
  disabled,
}) => {
  const [templateData, setTemplateData] = useState(defaultValue ?? {});
  const [showCode, setShowCode] = useState(false);
  const [otpCode, setOtpCode] = useState('');

  useEffect(() => setTemplateData(defaultValue), [defaultValue]);

  const fetchOtpCode = async (isCopy) => {
    const code = await organizationApi.getOtpCode(templateData.OtpKey);
    setOtpCode(code);
    if (isCopy) handleCopyText(code);
  };

  useEffect(() => {
    if (disabled) fetchOtpCode();
  }, [showCode, disabled]);

  const handleChangeData = (field, e) => {
    const newData = { ...templateData, [field]: e.target.value };
    setTemplateData(newData);
    onChange(newData);
  };
  const handleChangeUsername = (e) => {
    onUsernameChange(e.target.value);
  };

  const handleCopyText = (text) => {
    navigator.clipboard.writeText(text);
  };

  return (
    <Stack spacing={1} id={`${PAGE_NAME}_container`}>
      <Stack spacing={1} direction="row">
        <Stack direction="row" alignItems="center" width={PLATFORM_ITEM_WIDTH}>
          <InfoRow label="User Name*" fullWidth>
            <TextField
              value={username ?? ''}
              error={showError && !username}
              helperText={showError && !username ? 'Please provide your name.' : null}
              onChange={handleChangeUsername}
              id={`${PAGE_NAME}_userName`}
              size="small"
              disabled={disabled}
              classes={{ flex: 1 }}
            />
          </InfoRow>
          <IconButton
            id={`${PAGE_NAME}_copyButton`}
            size="small"
            onClick={() => handleCopyText(username)}
          >
            <CopyOutlineIcon fontSize="small" />
          </IconButton>
        </Stack>
        <InfoRow label="Account*" fullWidth>
          <TextField
            value={templateData.Account ?? ''}
            error={showError && !templateData.Account}
            helperText={showError && !templateData.Account ? 'Please provide your Account.' : null}
            onChange={(e) => handleChangeData('Account', e)}
            id={`${PAGE_NAME}_account`}
            size="small"
            disabled={disabled}
            classes={{ flex: 1 }}
          />
        </InfoRow>
      </Stack>
      <Stack spacing={1} direction="row">
        <InfoRow label="Merchant" fullWidth>
          <TextField
            value={templateData.Merchant ?? ''}
            onChange={(e) => handleChangeData('Merchant', e)}
            id={`${PAGE_NAME}_userName`}
            classes={{ flex: 1 }}
            size="small"
            disabled={disabled}
          />
        </InfoRow>
        <InfoRow label="Marketplace*" fullWidth>
          <TextField
            error={showError && !templateData.Marketplace}
            value={templateData.Marketplace ?? ''}
            onChange={(e) => handleChangeData('Marketplace', e)}
            id={`${PAGE_NAME}_marketplace`}
            classes={{ flex: 1 }}
            size="small"
            disabled={disabled}
          />
        </InfoRow>
      </Stack>
      <Stack spacing={1} direction="row" alignItems="center">
        <PasswordField
          value={templateData.Password ?? ''}
          label="Password"
          onChange={(e) => handleChangeData('Password', e)}
          classes={{ flex: 1 }}
          id={`${PAGE_NAME}_password`}
          size="small"
          disabled={disabled}
        />
        <IconButton
          id={`${PAGE_NAME}_copyButton`}
          size="small"
          onClick={() => handleCopyText(templateData.Password)}
        >
          <CopyOutlineIcon fontSize="small" />
        </IconButton>
      </Stack>
      <Stack spacing={1} direction="row" alignItems="center">
        <PasswordField
          label="One-Time Key"
          value={(disabled ? otpCode : templateData.OtpKey) ?? ''}
          onChange={(e) => handleChangeData('OtpKey', e)}
          setShowCode={setShowCode}
          classes={{ flex: 1 }}
          id={`${PAGE_NAME}_optKey`}
          size="small"
          disabled={disabled}
        />
        <IconButton
          id={`${PAGE_NAME}_copyButton`}
          size="small"
          onClick={() => {
            if (disabled) {
              fetchOtpCode(true);
            } else handleCopyText(templateData.OtpKey);
          }}
        >
          <CopyOutlineIcon fontSize="small" />
        </IconButton>
      </Stack>
    </Stack>
  );
};
