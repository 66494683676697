import * as React from 'react';
import { DataGridTable, Typography } from '@/components';
import { TRIGGERS_COLUMNS } from '../tableColumns';
import './TriggersTable.css';

export default function TriggersTable({ rows }) {
  return (
    <>
      <Typography variant="subtitle1">Triggered by:</Typography>
      {!rows?.length ? (
        <Typography variant="body1">No tickets</Typography>
      ) : (
        <DataGridTable className="triggers-table" rows={rows} columns={TRIGGERS_COLUMNS} hideFooter />
      )}
    </>
  );
}
