import { useEffect, useRef, useState } from 'react';
import { observer } from 'mobx-react-lite';
import cx from 'clsx';
import { Accordion, AccordionDetails, AccordionSummary, SearchField } from '@/components';
import SideMenuItem from './SideMenuItem';
import { useMainStore } from '../main/stores/useMainStore';
import { useSettingsStore } from '@/store';

import { useStyles } from './styles';

const PAGE_NAME = 'SideMenu';

const SideMenu = () => {
  const styles = useStyles();
  const { accountStore, scheduleStore } = useSettingsStore();
  const { taskLogStore } = useMainStore();
  const isLoading = taskLogStore.isLoading || accountStore.isLoading || scheduleStore.isLoading;
  const selectedOrgId = accountStore.selectedAccount?.id;
  const containerRef = useRef();

  useEffect(() => {
    if (
      !isLoading &&
      selectedOrgId &&
      accountStore.filteredAccounts.length > 0 &&
      containerRef.current
    ) {
      let top = 0;
      const findPosition = (accounts) => {
        return accounts.some((account) => {
          top += 32;
          if (account.id === selectedOrgId) {
            return true;
          }
          let result = false;
          if (account.children.length > 0 && account.isExpanded) {
            result = findPosition(account.children);
          }
          return result;
        });
      };
      findPosition(accountStore.filteredAccounts);
      if (Math.abs(containerRef.current.scrollTop - top) > containerRef.current.clientHeight - 32) {
        containerRef.current.scrollTo({
          top,
          behavior: 'smooth',
        });
      }
    }
  }, [isLoading, selectedOrgId, accountStore.filteredAccounts]);

  const handleSelectAccount = (id) => {
    accountStore.onSelectAccount(id);
  };

  const renderSubAccountItem = (account, layer) => {
    return (
      <Accordion
        key={account.id}
        classes={{
          accordionRoot: styles.accordionRoot,
          accordionExpanded: styles.accordionExpanded,
        }}
        id={`${PAGE_NAME}_accounts_accordion`}
        expanded={!!account.isExpanded}
      >
        <AccordionSummary
          id={`${PAGE_NAME}_accounts_accordion_content`}
          containerClassName={cx({
            [styles.accordionSummary]: 1,
            [styles.accordionSelectedSummary]: selectedOrgId === account.id,
          })}
          contentClassName={styles.accordionSummaryContent}
        >
          <SideMenuItem
            isChild={!accountStore.search && account.children.length > 0}
            isExpanded={!!account.isExpanded}
            isSelected={selectedOrgId === account.id}
            orgId={account.id}
            label={account.name}
            count={account.count}
            onSelect={(e) => {
              e.stopPropagation();
              handleSelectAccount(account.id);
            }}
            onToggleExpand={(e) => {
              e.stopPropagation();
              accountStore.onToggleExpandNode(account.id);
            }}
          />
        </AccordionSummary>
        {account.children.length > 0 && (
          <AccordionDetails className={cx(styles.accordionDetails)}>
            {account.children.map((subAccount) => renderSubAccountItem(subAccount, layer + 1))}
          </AccordionDetails>
        )}
      </Accordion>
    );
  };

  return (
    <div className={styles.sideContainer}>
      <div className={styles.sideContent}>
        {accountStore.allAccounts.length > 1 && (
          <SearchField
            size="small"
            id="accounts-search"
            placeholder="Search"
            value={accountStore.search}
            classes={{ root: styles.searchRoot }}
            onChange={(e) => accountStore.setSearch(e.target.value)}
          />
        )}
        <div id={`${PAGE_NAME}_sideMenuItem_group`} className={styles.body} ref={containerRef}>
          {!accountStore.search && accountStore.allAccounts.length > 0 && (
            <SideMenuItem
              isAllAccount
              orgId={accountStore.organization?.id}
              isSelected={accountStore.isParent}
              label={
                accountStore.allAccounts.length > 1
                  ? accountStore.organization?.name
                  : accountStore.allAccounts[0].name
              }
              onSelect={() => handleSelectAccount(accountStore.organization?.id)}
            />
          )}
          {accountStore.filteredAccounts.map((account) => renderSubAccountItem(account, 0))}
        </div>
      </div>
    </div>
  );
};

export default observer(SideMenu);
