import PropTypes from 'prop-types';
import { makeStyles } from '@mui/styles';
import { TableCell } from '@mui/material';
import cx from 'clsx';

const useStyles = makeStyles((theme) => ({
  navItemContainer: {
    color: theme.palette.secondary.darkBlue,
    fontSize: 16,
    letterSpacing: 0.15,
    fontWeight: 400,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    cursor: 'pointer',
    flex: 1,
  },
  labelWrapper: {
    display: 'flex',
    width: '100%',
    justifyContent: 'space-between',
  },
  expandWrapper: {
    width: 'calc(100% - 20px)',
  },
  label: {
    fontSize: 14,
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    lineHeight: '20px',
  },
  expandIcon: {
    width: '15px',
  },
  treeCell: {
    padding: '0px !important',
    borderBottom: '0px !important',
  },
}));

const OrganizationCell = ({ isChild, isExpanded, label, onToggleExpand, level }) => {
  const styles = useStyles();

  return (
    <TableCell className={styles.treeCell}>
      <div
        className={styles.navItemContainer}
        style={{
          paddingLeft: level < 0 ? 0 : (isChild ? 0 : 20) + level * 15,
        }}
        id="OrganizationTree_sideMenuItem"
      >
        {isChild && (
          <span className={styles.expandIcon} onClick={onToggleExpand}>
            {isExpanded ? '⊟' : '⊞'}
          </span>
        )}
        <div
          className={cx(styles.labelWrapper, {
            [styles.expandWrapper]: isChild,
          })}
        >
          <span className={styles.label}>{label}</span>
        </div>
      </div>
    </TableCell>
  );
};

OrganizationCell.propTypes = {
  isChild: PropTypes.bool,
  isExpanded: PropTypes.bool,
  isAllAccount: PropTypes.bool,
  isSelected: PropTypes.bool,
  label: PropTypes.string.isRequired,
  onToggleExpand: PropTypes.func,
};

OrganizationCell.defaultProps = {
  isChild: false,
  isExpanded: false,
  isAllAccount: false,
  isSelected: false,
  onToggleExpand: undefined,
};

export default OrganizationCell;
