import { useState, useEffect, useCallback, useRef } from 'react';
import { observer } from 'mobx-react-lite';
import { cloneDeep, isEqual } from 'lodash';

import OrganizationTable from './OrganizationTable';
import FilterPanel from './FilterPanel/FilterPanel';
import DetailContent from './DetailContent';
import { useSettingsStore, userStore } from '@/store';
import { Button, LoadingIndicator } from '@/components';
import { PAGE_MODE } from '@/utils/constants';
import { BASE_ROUTES, UrlSearch } from '@/utils/types';

import styles from './OrganizationPage.module.css';

const OrganizationPage = () => {
  const { organizationStore, accountStore, routerStore, membersStore } = useSettingsStore();
  const [pageMode, setPageMode] = useState(PAGE_MODE.none);
  const initLoadingRef = useRef(true);
  const prevParams = useRef();
  const urlParams = routerStore.getSearchChunkParams();

  useEffect(() => {
    organizationStore.fetchWorkflowConfigList();
    organizationStore.fetchEventTypes();
    setPageMode(PAGE_MODE.loading);
    routerStore.setCurrentPage(BASE_ROUTES.organizations);
  }, []);

  useEffect(() => {
    const fetchAccounts = async () => {
      const result = await accountStore.fetchAccounts();
      if (result) membersStore.fetchAllAccounts(cloneDeep(result));
    };
    fetchAccounts();
  }, [userStore.organizationId, userStore.isRefresh]);

  useEffect(() => {
    if (routerStore.currentPage !== BASE_ROUTES.organizations) {
      routerStore.setCurrentPage(BASE_ROUTES.organizations);
      return;
    }
    const orgId = Number(urlParams[UrlSearch.organization]);
    if (prevParams.current && isEqual(prevParams.current, urlParams)) return;
    if (accountStore.allAccounts.length === 0) return;
    prevParams.current = urlParams;

    // NOTE: Update account
    if (orgId) {
      accountStore.onSelectAccount(orgId);
      accountStore.updateExpandedAccounts(
        accountStore.allAccounts,
        accountStore.selectedAccount?.path,
      );
    }

    const searchTxt = urlParams[UrlSearch.search];
    accountStore.setSearch(searchTxt || '');
    initLoadingRef.current = false;
  }, [accountStore.allAccounts, urlParams]);

  useEffect(() => {
    if (initLoadingRef.current || !accountStore.selectedAccount?.id) return;

    // Update Uri
    const params = {};
    params[UrlSearch.organization] = accountStore.selectedAccount.id;
    if (accountStore.search) params[UrlSearch.search] = accountStore.search;

    const stringParams = Object.keys(params).reduce((acc, key) => {
      if (Number.isFinite(params[key])) return { ...acc, [key]: params[key].toString() };
      return { ...acc, [key]: params[key] };
    }, {});

    if (!isEqual(urlParams, stringParams)) {
      routerStore.setSearchChunkParams(params);
    }
  }, [accountStore.selectedAccount, accountStore.search, urlParams]);

  const handleSelectOrganization = useCallback(async (id) => {
    setPageMode(PAGE_MODE.loading);
    accountStore.onSelectAccount(id);
  }, []);

  const handleClose = useCallback(() => {
    organizationStore.setOrganizationInfo(null);
    setPageMode(PAGE_MODE.none);
  }, []);

  if (accountStore.isLoading) {
    return <LoadingIndicator fullScreen />;
  }

  return (
    <div id="Organizations_container" className={styles.mainContainer}>
      <div className={styles.organizationHeader}>
        <FilterPanel />
        {pageMode !== PAGE_MODE.creating && (
          <Button
            size="small"
            variant="secondary"
            onClick={() => {
              setPageMode(PAGE_MODE.creating);
            }}
            className={styles.button}
          >
            OnBoard
          </Button>
        )}
      </div>
      <div className={styles.content}>
        <OrganizationTable onClickRow={handleSelectOrganization} />
        <DetailContent
          pageMode={pageMode}
          setPageMode={setPageMode}
          open={Boolean(accountStore.selectedAccount?.id)}
          onClose={handleClose}
        />
      </div>
    </div>
  );
};

export default observer(OrganizationPage);
