import { Box } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';

export default function DataGridTable({ rows, columns, density = 'compact', ...otherProps }) {
  return (
    <Box
      sx={{
        width: '100%',
        '& .actions': {
          color: 'text.secondary',
        },
        '& .textPrimary': {
          color: 'text.primary',
        },
      }}
    >
      <DataGrid
        rows={rows}
        columns={columns}
        density={density}
        disableColumnMenu
        autoHeight
        {...otherProps}
      />
    </Box>
  );
}
