import { observer } from 'mobx-react-lite';
import { ListItemText } from '@mui/material';
import { MultiSelect, Checkbox, Switch, MultiTriStateSelect, TreeListDropdown } from '@/components';
import { useSettingsStore } from '@/store';
import { FilterType } from '@/utils/constants';
import { AnchorOrigin, TRISTATE } from '@/utils/types';

import styles from './FilterPanel.module.css';

const PAGE_NAME = 'Members_FilterPanel';

export const FilterPanel = observer(() => {
  const { membersStore } = useSettingsStore();

  return (
    <div className={styles.filtersGroup} id={`${PAGE_NAME}_container`}>
      {Object.keys(membersStore.filteredResult).map((filterKey) => {
        const {
          label,
          selectedValues: value,
          values,
          hasSearchBar,
          hasSelectAllClear,
        } = membersStore.filteredResult[filterKey];
        const options = values.map(({ label, value }) => ({
          value,
          label: label ?? value,
        }));

        if (filterKey === FilterType.status) {
          return (
            <div key={filterKey} className={styles.filterItem} id={`${PAGE_NAME}_filterDropdown`}>
              <MultiTriStateSelect
                options={options}
                placeholder={label}
                value={value}
                renderValue={(selected, placeholder) => {
                  const selectedValues = options.filter(
                    (_, index) => selected[index] === TRISTATE.checked,
                  );
                  if (!selectedValues?.length && placeholder) {
                    return placeholder;
                  }
                  if (selectedValues.length > 1) {
                    return `${selectedValues[0].label} +${selected.length - 1} more`;
                  }
                  return selectedValues[0]?.label;
                }}
                onChange={(value) => {
                  membersStore.updateFilterValues(filterKey, value);
                }}
                style={{ minWidth: 220, width: 'fit-content' }}
                classes={{ item: styles.menuItemCheckbox }}
              />
            </div>
          );
        }

        if (filterKey === FilterType.organizationId) {
          return (
            <TreeListDropdown
              key={filterKey}
              multiSelect
              selectCategory
              treeList={membersStore.allAccounts}
              allItems={membersStore.arrangedAccounts}
              anchorPosition={AnchorOrigin.bottom}
              placeholder="Select Organization"
              selectedValues={value}
              className={styles.orgsDropdown}
              onChange={(value) => membersStore.updateFilterValues(filterKey, value)}
            />
          );
        }

        return (
          <div key={filterKey} className={styles.filterItem} id={`${PAGE_NAME}_filterDropdown`}>
            <MultiSelect
              value={value}
              key={filterKey}
              placeholder={label}
              options={options}
              isSearchBar={hasSearchBar}
              hasSelectAllClear={hasSelectAllClear}
              style={{ width: '220px' }}
              id={`${PAGE_NAME}_paginationDropdown`}
              renderValue={(selected, placeholder) => {
                if ((filterKey === FilterType.viewBy || selected.length === 0) && placeholder) {
                  return placeholder;
                }
                if (selected.length > 1) {
                  return `${selected[0]} +${selected.length - 1} more`;
                }
                return selected[0];
              }}
              onChange={(value) => {
                membersStore.updateFilterValues(filterKey, value);
              }}
              MenuItemComponent={({ label, checked }) => {
                if (filterKey === FilterType.viewBy) {
                  return (
                    <>
                      <Switch checked={checked} id={`${PAGE_NAME}_switchItem`} />
                      <ListItemText>{label}</ListItemText>
                    </>
                  );
                }
                return <Checkbox label={label} checked={checked} id={`${PAGE_NAME}_checkbox`} />;
              }}
            />
          </div>
        );
      })}
    </div>
  );
});
