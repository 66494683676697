import cx from 'clsx';
import { makeStyles } from '@mui/styles';
import Avatar from '../Avatar';

export const useStyles = makeStyles((theme) => ({
  assignContainer: {
    display: 'flex',
    alignItems: 'center',
    position: 'relative',
    borderRadius: '8px',
    padding: '5px 12px',
    '&:hover': {
      cursor: 'pointer',
      background: theme.palette.neutrals.grayBlue,
    },
  },
  selectedContainer: {
    background: `${theme.palette.options.hover} !important`,
  },
  label: {
    flex: 1,
    padding: '0px 8px',
    fontWeight: 400,
    fontSize: '16px',
    letterSpacing: '0.15px',
    color: theme.palette.secondary.darkBlue,
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
  },
  highlight: {
    fontWeight: 600,
    backgroundColor: theme.palette.secondary.lightPurple,
  },
}));
const PAGE_NAME = 'AssignLabel';

const AssignLabel = ({
  isEditable,
  width,
  name,
  type,
  match,
  checked,
  borderColor,
  contentClassName,
  onChange,
}) => {
  const styles = useStyles();

  return (
    <span
      id={`${PAGE_NAME}_span`}
      className={cx(
        styles.assignContainer,
        { [styles.selectedContainer]: checked },
        contentClassName,
      )}
      onClick={() => onChange && onChange(!checked)}
    >
      <Avatar
        id={`${PAGE_NAME}_ava`}
        name={name}
        variant={type}
        borderColor={borderColor || '#fff'}
        width={width}
      />
      <span className={styles.label}>
        {match && name ? (
          <>
            {name.slice(0, match[0])}
            <span className={styles.highlight}>{name.slice(match[0], match[1])}</span>
            {name.slice(match[1])}
          </>
        ) : (
          name
        )}
      </span>
    </span>
  );
};

export default AssignLabel;
