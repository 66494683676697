import { useMemo } from 'react';
import { observer } from 'mobx-react-lite';

import { Checkbox, ComboBox, InfoRow, TextField, Typography } from '@/components';
import { DEFAULT_CRONS, PAGE_MODE } from '@/utils/constants';

const PAGE_NAME = 'ScheduleContent';
const DEFAULT_WIDTH = { width: 216 };

const ScheduleContent = ({ pageMode, scheduleInfo, setScheduleInfo, showError }) => {
  const { id, cron, label, ticketId, state, workflow, lastRun, title, paused } = scheduleInfo;
  const isEditMode = pageMode !== PAGE_MODE.none;
  const cronExpression = useMemo(
    () => DEFAULT_CRONS.find((item) => item.value === cron) ?? cron,
    [cron],
  );

  const handleChangeScheduleInfo = (fieldValue) => {
    setScheduleInfo((prv) => ({ ...prv, ...fieldValue }));
  };

  return (
    <>
      <InfoRow label="Schedule Id">
        <Typography variant="body2">{id}</Typography>
      </InfoRow>
      {isEditMode && (
        <InfoRow label="Cron">
          <ComboBox
            placeholder="Cron"
            options={DEFAULT_CRONS}
            value={cronExpression}
            sx={DEFAULT_WIDTH}
            onChange={(option) => handleChangeScheduleInfo({ cron: option.value ?? option })}
          />
        </InfoRow>
      )}
      <InfoRow label="Ticket Id">
        <TextField
          id={`${PAGE_NAME}_ticketId`}
          placeholder="Ticket Id"
          value={ticketId}
          error={showError && !ticketId}
          onChange={(e) => handleChangeScheduleInfo({ ticketId: e.target.value })}
          sx={DEFAULT_WIDTH}
          disabled={!isEditMode}
        />
      </InfoRow>
      <InfoRow label="Is Paused">
        <Checkbox
          checked={paused}
          onChange={(e) => handleChangeScheduleInfo({ paused: e.target.checked })}
          disabled={!isEditMode || pageMode === PAGE_MODE.creating}
        />
      </InfoRow>
      <InfoRow label="Label">
        <Typography variant="body2" sx={DEFAULT_WIDTH}>
          {label}
        </Typography>
      </InfoRow>
      <InfoRow label="Status">
        <Typography variant="body2">{state}</Typography>
      </InfoRow>
      <InfoRow label="Service">
        <Typography variant="body2">{workflow}</Typography>
      </InfoRow>
      <InfoRow label="Last Run">
        <Typography variant="body2">{lastRun}</Typography>
      </InfoRow>
      <InfoRow label="Ticket Title">
        <Typography variant="body2" sx={DEFAULT_WIDTH}>
          {title}
        </Typography>
      </InfoRow>
    </>
  );
};

export default observer(ScheduleContent);
