import { useMemo, useState } from 'react';
import {
  GridActionsCellItem,
  GridRowEditStopReasons,
  GridRowModes,
  GridToolbarContainer,
} from '@mui/x-data-grid';
import { Cancel, DeleteOutline, Edit, Save } from '@mui/icons-material';
import { observer } from 'mobx-react-lite';

import { Button, DataGridTable, LoadingIndicator, Typography } from '@/components';
import { DEFAULT_SCHEDULE } from '@/utils/constants';
import { SCHEDULE_COLUMNS } from '../tableColumns';
import { useMainStore } from '../../stores/useMainStore';

function EditToolbar(props) {
  const { ticketId, rows, setRows, setRowModesModel, isCreating, setIsCreating } = props;

  const handleClick = () => {
    const id = 0;
    setIsCreating(true);
    setRows([...rows, { ...DEFAULT_SCHEDULE, id, ticketId, isNew: true }]);
    setRowModesModel((oldModel) => ({
      ...oldModel,
      [id]: { mode: GridRowModes.Edit },
    }));
  };

  return (
    <GridToolbarContainer sx={{ display: 'flex', justifyContent: 'space-between', padding: '8px' }}>
      <Typography variant="subtitle1">Schedules</Typography>
      <Button
        id="create-schedule"
        size="small"
        variant="secondary"
        onClick={handleClick}
        disabled={isCreating}
      >
        Create Schedule
      </Button>
    </GridToolbarContainer>
  );
}

const ScheduleTable = () => {
  const { detailStore } = useMainStore();
  const [rowModesModel, setRowModesModel] = useState({});
  const [isCreating, setIsCreating] = useState(false);

  const handleRowEditStop = (params, event) => {
    if (params.reason === GridRowEditStopReasons.rowFocusOut) {
      event.defaultMuiPrevented = true;
    }
  };

  const handleEditClick = (id) => () => {
    setRowModesModel((oldModel) => ({ ...oldModel, [id]: { mode: GridRowModes.Edit } }));
  };

  const handleDeleteClick = (id) => async () => {
    setRowModesModel((oldModel) => ({
      ...oldModel,
      [id]: { mode: GridRowModes.View, showLoading: true },
    }));
    await detailStore.deleteSchedule(id);
    setRowModesModel((oldModel) => ({
      ...oldModel,
      [id]: { mode: GridRowModes.View, showLoading: false },
    }));
  };

  const handleSaveClick = (id) => () => {
    const editedRow = detailStore.scheduleList.find((row) => row.id === id);
    if (editedRow.isNew) {
      setIsCreating(false);
    }
    setRowModesModel((oldModel) => ({
      ...oldModel,
      [editedRow.id]: { mode: GridRowModes.View, isNew: false, showLoading: true },
    }));
  };

  const handleCancelClick = (id) => () => {
    setRowModesModel((oldModel) => ({
      ...oldModel,
      [id]: { mode: GridRowModes.View, ignoreModifications: true },
    }));
    setIsCreating(false);

    const editedRow = detailStore.scheduleList.find((row) => row.id === id);
    if (editedRow.isNew) {
      detailStore.setScheduleList(detailStore.scheduleList.filter((row) => row.id !== id));
    }
  };

  const processRowUpdate = async (newRow) => {
    let result = newRow;
    try {
      const newData = await detailStore.saveSchedule(newRow);
      result = { ...newData, isNew: false, showLoading: false };
    } catch (err) {
      console.error(err);
    }
    setRowModesModel((oldModel) => ({
      ...oldModel,
      [newRow.id]: { mode: GridRowModes.View, showLoading: false },
    }));
    return result;
  };

  const handleRowModesModelChange = (newRowModesModel) => {
    setRowModesModel(newRowModesModel);
  };

  const handleProcessRowUpdateError = (error) => {
    console.error('Error processing row update:', error);
  };

  const columns = useMemo(
    () => [
      ...SCHEDULE_COLUMNS,
      {
        field: 'actions',
        type: 'actions',
        headerName: 'Actions',
        width: 100,
        cellClassName: 'actions',
        getActions: ({ id }) => {
          const isInEditMode = rowModesModel[id]?.mode === GridRowModes.Edit;

          if (rowModesModel[id]?.showLoading) {
            return [
              <GridActionsCellItem
                icon={<LoadingIndicator width={28} />}
                label="loading"
                sx={{
                  color: 'primary.main',
                }}
              />,
            ];
          }

          if (isInEditMode) {
            return [
              <GridActionsCellItem
                icon={<Save />}
                label="Save"
                sx={{
                  color: 'primary.main',
                }}
                onClick={handleSaveClick(id)}
              />,
              <GridActionsCellItem
                icon={<Cancel />}
                label="Cancel"
                className="textPrimary"
                onClick={handleCancelClick(id)}
                color="inherit"
              />,
            ];
          }

          return [
            <GridActionsCellItem
              icon={<Edit />}
              className="textPrimary"
              onClick={handleEditClick(id)}
              color="inherit"
            />,
            <GridActionsCellItem
              icon={<DeleteOutline />}
              label="Delete"
              onClick={handleDeleteClick(id)}
              color="inherit"
            />,
          ];
        },
      },
    ],
    [rowModesModel],
  );

  return (
    <DataGridTable
      rows={detailStore.scheduleList}
      columns={columns}
      editMode="row"
      rowModesModel={rowModesModel}
      onRowModesModelChange={handleRowModesModelChange}
      onRowEditStop={handleRowEditStop}
      processRowUpdate={processRowUpdate}
      onProcessRowUpdateError={handleProcessRowUpdateError}
      slots={{
        toolbar: EditToolbar,
      }}
      slotProps={{
        toolbar: {
          ticketId: detailStore.ticketId,
          rows: detailStore.scheduleList,
          setRows: (rows) => detailStore.setScheduleList(rows),
          setRowModesModel,
          isCreating,
          setIsCreating,
        },
      }}
      hideFooter
    />
  );
};

export default observer(ScheduleTable);
