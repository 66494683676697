import { last, trim } from 'lodash';
import { Config } from '../config';
import {
  DEFAULT_DAYTIME_FORMAT,
  DEFAULT_LOCAL_TIME_FORMAT,
  convertLocalDateTime,
  formatLocalDateTime,
  getObjectType,
  getStatus,
  getValidFields,
} from '../utils';
import { MemberStatusList, getFullName } from '../utils/users';
import { AutomationStatusColors, Unassigned } from '@/utils/types';

export function parseUser(obj) {
  const name = obj.name?.trim();
  const organizationId =
    obj.organizationId || Number(last(obj.organizationPath.split('/').filter((id) => id)));
  const organizationName = last(obj.organizationPathByName?.split('/')?.filter((name) => name));
  const status = MemberStatusList.filter(({ key }) => obj[key]).map(({ label }) => label);

  if (name.startsWith(Unassigned.name)) {
    Unassigned.id = obj.id;
    Unassigned.value = obj.id;
    Unassigned.name = name;
    Unassigned.organizationId = organizationId;
    Unassigned.organizationName = organizationName;
    Unassigned.status = status;
  }

  return {
    id: obj.id,
    authId: obj.authId,
    email: obj.email,
    emailsEnabled: obj.profile?.emailsEnabled ?? obj.emailsEnabled,
    first: obj.first,
    last: obj.last,
    name: name ?? getFullName(obj),
    image: obj.image,
    organizationId,
    organizationName,
    organizationPath: obj.organizationPath,
    organizationPathByName: obj.organizationPathByName,
    status,
    isActive: obj.isActive,
    isAdmin: obj.isAdmin,
    isContributor: obj.isContributor,
    isAssignable: obj.isAssignable,
    pinnedTickets: obj.profile?.pinnedTickets ?? [],
    pinnedRequests: obj.profile?.pinnedRequests ?? [],
    helpItemTags: obj.helpItemTags ?? [],
    createdOn: formatLocalDateTime(obj.createdOn, DEFAULT_DAYTIME_FORMAT),
    filters: obj.profile?.filters ?? obj.filters ?? {},
    ticketColumns: obj.profile?.ticketColumns,
    profile: obj.profile,
    accessId: obj.accessId,
  };
}

export function parseUserFromObj(obj) {
  return {
    id: obj.id,
    email: obj.email,
    first: obj.first,
    last: obj.last,
    name: `${obj.first} ${obj.last}`,
    organizationId: obj.organizationId,
    organizationPath: obj.organizationPath,
    profile: obj.profile,
    isActive: obj.isActive,
    isAdmin: obj.isAdmin,
    isContributor: obj.isContributor,
    isAssignable: obj.isAssignable,
  };
}

export function parseTaskLogFromResponse(obj) {
  const { automation, automationTime, buildId } = obj;
  const id = obj.id;
  const buildError = obj.buildError;
  const organization = obj.organization;
  const workflow = obj.workflow;
  const config = getValidFields(JSON.parse(obj.config));

  return {
    id,
    ticketId: id,
    row: obj.row,
    automation: automation
      ? {
          name: automation,
          color: AutomationStatusColors[automation] || AutomationStatusColors.none,
          automationTime:
            automationTime &&
            formatLocalDateTime(convertLocalDateTime(automationTime), DEFAULT_LOCAL_TIME_FORMAT),
          buildId,
          link: `https://dev.azure.com/WorkflowLabs/Helpdesk/_build/results?buildId=${buildId}&view=results`,
        }
      : null,
    automationTime: obj.automationTime,
    buildError,
    ticketUrl: `${Config.PORTAL_APP_LINK}/detail?ticket=${id}`,
    buildNumber: buildError && `${organization} ${workflow} Ticket ${id}`,
    config,
    workflow,
    createdByName: obj.createdByName,
    organization,
    accountId: obj.organizationId,
    isPinned: obj.isPinned,
    priority: obj.priority,
    objectType: getObjectType(obj),
    intentType: obj.service || obj.workflow,
    remainingWork: Number(obj.quantity),
    status: obj.status,
    state: getStatus(obj.state),
    children: [],
    path: (obj.path ?? '')
      .split('/')
      .filter((id) => id)
      .map(Number),
    title: obj.title,
    description: obj.description,
    assignedId: Number(obj.assignedTo),
    assignedName: trim(obj.assignedToName || ''),
    ownedByName: trim(obj.ownedByName || obj.createdByName || ''),
    createdDate: convertLocalDateTime(obj.createdOn),
    lastUpdated: convertLocalDateTime(obj.updatedOn || obj.createdOn),
    lastComment: convertLocalDateTime(obj.lastComment),
    requestedDate: convertLocalDateTime(obj.requestedByDate),
    estimatedCompletion: convertLocalDateTime(obj.estimatedCompletion),
    isRead: !!obj.openQuestions,
  };
}

export function parseBulkTaskLogFromResponse(obj) {
  return {
    id: obj.id,
    row: obj.row,
    ticketUrl: `${Config.PORTAL_APP_LINK}/detail?ticket=${obj.id}`,
    workflow: obj.workflow,
    path: obj.path,
    organizationPath: obj.organizationPath,
    ticketId: obj.id,
    organization: obj.organization,
    accountId: obj.organizationId,
    state: getStatus(obj.state),
    title: obj.title,
    ownedByName: trim(obj.ownedByName || obj.createdByName || ''),
    assignedToName: trim(obj.assignedToName || ''),
    commentedByName: obj.commentedByName,
    resolved: obj.resolved,
    total: obj.total,
    lastUpdated: obj.lastUpdated,
  };
}
