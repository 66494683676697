import TableCell from "@mui/material/TableCell";
import cx from "clsx";
import { useStyles } from "./sharedStyles";
import { AutomationLog } from "./AutomationLog";

export const AutomationCell = ({ automation, isAdminUser }) => {
  const styles = useStyles();

  return (
    <TableCell
      key="pin"
      id="AutomationCell"
      align="center"
      className={cx(styles.bodyCell, styles.pinCell)}
    >
      <AutomationLog automation={automation} isAdminUser={isAdminUser} />
    </TableCell>
  );
};
