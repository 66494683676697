import { action, computed, makeObservable, observable, runInAction } from 'mobx';

import { getToken, showSuccessNotification } from '../utils';
import { attachmentApi, organizationApi, platformApi } from '../api';
import { omit, orderBy } from 'lodash';
import { userStore } from './UserStore';
import { setError } from '../utils/errors';
import { getWorkflowConfigList } from '@/utils/staticForm';

export class OrganizationStore {
  organizationInfo = null;
  isReceiveAPIKey = false;
  isCopied = false;
  isLoading = false;
  eventTypes = [];
  workflowTreeList = [];
  parentOrgId = null;

  constructor() {
    makeObservable(this, {
      //  Organization Settings
      parentOrgId: observable,
      setParentOrgId: action,

      organizationInfo: observable,
      setOrganizationInfo: action,

      workflowTreeList: observable,
      workflowConfigOptions: computed,

      //  Developer Settings
      isReceiveAPIKey: observable,
      setIsReceiveAPIKey: action,
      isCopied: observable,
      copyAPIKey: action,

      isLoading: observable,
      setIsLoading: action,

      eventTypes: observable,
    });
  }

  setIsReceiveAPIKey(value) {
    this.isReceiveAPIKey = value;
  }

  setIsLoading(value) {
    this.isLoading = value;
  }

  setParentOrgId(value) {
    this.parentOrgId = value;
  }

  async copyAPIKey() {
    await navigator.clipboard.writeText(getToken());
    this.isCopied = true;
    showSuccessNotification('Key copied to clipboard');
  }

  async getLogoURL(logoFile, isLogoChanged) {
    if (isLogoChanged) {
      const imageLink = await attachmentApi.uploadAttachmentBlob(logoFile);
      return imageLink;
    }
    return this.organizationInfo.profile.branding?.logoUrl;
  }

  async saveOrganizationInfo(
    parentOrgId,
    orgName,
    orgInfo,
    orgEventSubscriptions,
    logins,
    isCaptcha,
    isCreating,
    organization,
    services,
  ) {
    this.isLoading = true;
    let res = false;
    try {
      const logoUrl = await this.getLogoURL(orgInfo.logoUrl, orgInfo.isLogoChanged);
      const eventSubscriptions = orgEventSubscriptions.reduce(
        (subscriptions, { event, link }) => ({
          ...subscriptions,
          [event]: link,
        }),
        {},
      );
      const profile = {
        ...this.organizationInfo.profile,
        logins: logins.map((login) => ({
          ...login,
          configuration: omit(login.configuration, ['Password', 'OtpKey']),
        })),
        branding: {
          alias: orgInfo.alias,
          logoUrl,
          eMail: orgInfo.mailFrom,
          supportUrl: orgInfo.helpUrl,
        },
        eventSubscriptions,
        settings: {
          isCaptcha,
        },
        services: services.filter((serviceId) =>
          userStore.workflowConfigList.some(({ id }) => id === serviceId),
        ),
      };
      if (isCreating) {
        await organizationApi.createOrganization({
          organization: orgName,
          parentId: this.parentOrgId,
          isActive: true,
          path: organization.path.join('/'),
          profile: omit(profile, 'id'),
        });
      } else {
        await organizationApi.updateOrganization(parentOrgId, {
          organization: orgName,
          parentId: this.parentOrgId,
          profile,
        });
      }
      await organizationApi.savePlatformLogins(logins);
      res = true;
    } catch (err) {
      res = false;
      setError(err, false, `${isCreating ? 'Create' : 'Update'} organization failed`);
    }
    this.isLoading = false;
    return res;
  }

  setOrganizationInfo(organization) {
    this.organizationInfo = organization;
    if (userStore.organizationId === organization.id) {
      userStore.updateLogoHelpLink(
        this.organizationInfo.profile?.branding?.logoUrl,
        this.organizationInfo.profile?.branding?.helpUrl,
      );
    }
  }

  async fetchOrganizationInfo(parentOrgId) {
    if (parentOrgId) {
      this.isLoading = true;
      const organizationInfo = await organizationApi.getOrganization(parentOrgId);
      this.setOrganizationInfo(organizationInfo);
      this.isLoading = false;
    }
  }

  // NOTE: Workflow Config Type
  get workflowConfigOptions() {
    const options = userStore.workflowConfigList.map(({ workflow, displayName }) => {
      return {
        value: workflow,
        label: displayName || workflow,
      };
    });
    return orderBy(options, 'label');
  }

  async fetchWorkflowConfigList() {
    this.setIsLoading(true);
    try {
      const { workflowConfigList, workflowTreeList } = getWorkflowConfigList();
      runInAction(() => {
        userStore.workflowConfigList = workflowConfigList;
        this.workflowTreeList = workflowTreeList;
      });
    } catch (err) {
      setError(err);
      userStore.workflowConfigList = [];
    }
    this.setIsLoading(false);
  }

  async fetchEventTypes() {
    try {
      const eventTypes = await platformApi.getEventTypes();
      runInAction(() => {
        this.eventTypes = eventTypes ?? [];
      });
    } catch (err) {
      setError(err);
    }
  }

  dispose() {}
}
