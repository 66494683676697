import { useEffect, useRef, useState } from 'react';
import { observer } from 'mobx-react-lite';
import cx from 'clsx';
import {
  Table,
  TableCell,
  TableContainer,
  TableHead,
  TableBody,
  TableRow,
  TableSortLabel,
} from '@mui/material';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';

import { BulkFilter } from './BulkFilter';
import { BulkTaskLogTableRow } from './BulkTaskLogTableRow';
import { useStyles } from './styles';
import {
  Checkbox,
  EmptyFilterView,
  LoadingIndicator,
  Pagination,
  TablePagination,
  Typography,
} from '@/components';
import { ColumnType } from '@/utils/types';
import { EmptyLogView } from '../../main/components/EmptyLogView';
import { useBulkStore } from '../stores/useBulkStore';

const PAGE_NAME = 'BulkTaskLog';

const BulkTaskLog = () => {
  const { accountStore, taskLogStore, tableStore, filterStore } = useBulkStore();
  const styles = useStyles();
  const tableRef = useRef(null);
  const [isScrollVisible, setIsScrollVisible] = useState(false);

  useEffect(() => {
    if (tableRef.current) {
      if (tableRef.current.scrollHeight > tableRef.current.clientHeight + 10) {
        setIsScrollVisible(true);
      } else {
        setIsScrollVisible(false);
      }
    }
  }, [taskLogStore.parsedLogs]);

  const isLoading = taskLogStore.isLoading || accountStore.isLoading;

  if (
    !isLoading &&
    taskLogStore.parsedLogs?.length === 0 &&
    filterStore.allFilteredCount === 0 &&
    !filterStore.search
  ) {
    return <EmptyLogView />;
  }

  return (
    <div className={styles.content} id={`${PAGE_NAME}_container`}>
      <div className={styles.container} id={`${PAGE_NAME}_contentContainer`}>
        {accountStore.isLoading ? (
          <LoadingIndicator />
        ) : (
          <>
            <BulkFilter />

            {taskLogStore.isLoading ? (
              <LoadingIndicator />
            ) : (
              <div className={styles.tableBlock} id={`${PAGE_NAME}_tableBlock`}>
                {taskLogStore.parsedLogs.length === 0 &&
                (filterStore.allFilteredCount > 0 || filterStore.search) ? (
                  <EmptyFilterView
                    noFilterResult={filterStore.allFilteredCount > 0}
                    onClick={() =>
                      filterStore.allFilteredCount > 0
                        ? filterStore.clearFilter()
                        : filterStore.setSearch('')
                    }
                  />
                ) : (
                  <TableContainer className={styles.tableContainer} ref={tableRef}>
                    <Table
                      stickyHeader
                      size="small"
                      style={{ paddingRight: isScrollVisible ? 0 : 8 }}
                      id={`${PAGE_NAME}_table`}
                    >
                      <TableHead id={`${PAGE_NAME}_table_head`}>
                        <TableRow>
                          {tableStore.bulkHeaderColumns.map(
                            ({ label, align, sortKey, key, width }, idx) => (
                              <TableCell
                                key={label}
                                align={align}
                                className={cx(styles.headCell, {
                                  [styles.headCellLast]:
                                    idx === tableStore.bulkHeaderColumns.length - 1,
                                })}
                                style={{ minWidth: width }}
                              >
                                {sortKey ? (
                                  <TableSortLabel
                                    active={tableStore.isActiveSort(sortKey)}
                                    direction={tableStore.getSortDirection(sortKey)}
                                    IconComponent={ArrowDropDownIcon}
                                    classes={{ icon: styles.sortIcon }}
                                    onClick={() => tableStore.toggleSort(sortKey)}
                                  >
                                    <Typography variant="button-small">{label}</Typography>
                                  </TableSortLabel>
                                ) : (
                                  <>
                                    {key === ColumnType.select && (
                                      <Checkbox
                                        checked={taskLogStore.isSelectedAll}
                                        indeterminate={taskLogStore.isIndeterminate}
                                        onChange={(e) =>
                                          taskLogStore.onSelectAllTickets(e.target.checked)
                                        }
                                        classes={{ formRoot: styles.checkbox }}
                                      />
                                    )}
                                    <Typography variant="button-small">{label}</Typography>
                                  </>
                                )}
                              </TableCell>
                            ),
                          )}
                        </TableRow>
                      </TableHead>

                      <TableBody id={`${PAGE_NAME}_table_body`}>
                        {taskLogStore.parsedLogs.map((log, index) => (
                          <BulkTaskLogTableRow
                            log={log}
                            isLastRow={index === taskLogStore.parsedLogs.length - 1}
                          />
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                )}
                <div className={styles.pagination}>
                  <TablePagination
                    label="View Per Page"
                    value={tableStore.rowsPerPage}
                    id={`${PAGE_NAME}_paginationDropdown`}
                    options={[10, 20, 50, 100, 1000]}
                    onChangeRowsPerPage={(v) => tableStore.setRowsPerPage(v)}
                  />
                  <Pagination
                    count={taskLogStore.pageCount}
                    page={tableStore.pageNumber}
                    onChange={(e, v) => tableStore.setPageNumber(v)}
                    id={`${PAGE_NAME}_pagination`}
                  />
                </div>
              </div>
            )}
          </>
        )}
      </div>
    </div>
  );
};

export default observer(BulkTaskLog);
