import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles((theme) => ({
  sideContainer: {
    display: 'flex',
    zIndex: 10,
    flexDirection: 'column',
    backgroundColor: theme.palette.secondary.darkBlue,
  },
  sideContent: {
    position: 'relative',
    padding: '8px 0px 8px 8px',
    height: 'calc(100% - 16px)',
    width: '228px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    transition: 'all 0.3s ease-in-out',
  },
  topItem: {
    display: 'flex',
    flexDirection: 'column',
    paddingTop: '5px',
  },
  body: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    paddingTop: '12px',
    paddingRight: '8px',
    overflowY: 'auto',
    '&::-webkit-scrollbar': {
      width: 0,
    },
    '&:hover': {
      /* width */
      '&::-webkit-scrollbar': {
        width: 8,
      },
      /* Track */
      '&::-webkit-scrollbar-track': {
        borderRadius: 8,
      },
      /* Handle */
      '&::-webkit-scrollbar-thumb': {
        background: '#546596',
        borderRadius: 8,
      },
    },
  },
  accordionRoot: {
    height: 'fit-content !important',
    backgroundColor: `transparent !important`,
  },
  accordionSelectedSummary: {
    background: `${theme.palette.secondary.lightBlue} !important`,
    borderRadius: '8px !important',
    color: `${theme.palette.secondary.darkBlue} !important`,
    fontWeight: '700 !important',
  },
  accordionSummary: {
    height: '32px !important',
    minHeight: '32px !important',
    padding: '0px !important',
    flexDirection: 'row-reverse',
    borderRadius: '8px !important',
    '&:hover': {
      background: 'rgba(255, 255, 255, 0.2)',
      borderRadius: 8,
      color: theme.palette.secondary.contrast,
      fontWeight: 400,
    },
    '&:hover > .expandIcon': {
      color: `${theme.palette.primary.darkPurple} !important`,
    },
  },
  accordionSummaryContent: {
    paddingRight: '0px !important',
    margin: '0px !important',
  },
  expandIcon: {
    color: `${theme.palette.primary.purple} !important`,
  },
  accordionExpanded: {
    backgroundColor: `transparent !important`,
    '&:berfore': {
      opacity: `1 !important`,
    },
  },
  accordionDetails: {
    padding: '4px 0px 4px 16px !important',
    gap: 0,
  },
  searchRoot: {
    width: 'calc(100% - 12px)',
  },
}));
