import { observer } from 'mobx-react-lite';
import { debounce } from 'lodash';

import { Button, SearchField } from '@/components';
import { useSettingsStore } from '@/store';
import { FilterPanel } from '../Filter/FilterPanel';
import styles from './Header.module.css';

const PAGE_NAME = 'MemberHeader';

export const Header = observer(({ onCreateUser }) => {
  const { membersStore } = useSettingsStore();

  const handleChangeSearch = debounce((event) => {
    const { value } = event.target;
    membersStore.setSearch(value);
  }, 500);

  return (
    <div className={styles.headerContainer}>
      <div className={styles.filtersRoot}>
        <div className={styles.selectorWrapper}>
          <div className={styles.buttonGroup}>
            <SearchField
              placeholder="Search by id, name, email, path"
              value={membersStore.search}
              style={{ width: 270 }}
              classes={{
                root: styles.searchRoot,
                container: styles.searchContainer,
              }}
              onChange={handleChangeSearch}
              id={`${PAGE_NAME}_searchField`}
              helperText={`${membersStore.allFilteredMembers.length}/${membersStore.members.length}`}
            />
            <div className={styles.buttonGroup}>
              <FilterPanel />
              {membersStore.allFilteredCount > 0 && (
                <Button
                  size="small"
                  variant="tertiary"
                  onClick={() => membersStore.clearFilter()}
                  id={`${PAGE_NAME}_clearButton`}
                >
                  Reset
                </Button>
              )}
            </div>
          </div>
          <div className={styles.buttonGroup}>
            <Button size="small" variant="secondary" onClick={onCreateUser}>
              OnBoard
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
});
