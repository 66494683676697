import PropTypes from 'prop-types';
import cx from 'clsx';
import TableCell from '@mui/material/TableCell';
import { useStyles } from './sharedStyles';
import { getAvatarName } from '@/utils';
import Tooltip from '../Tooltip';

export const AvatarCell = ({ isRequest, value, variant }) => {
  const styles = useStyles();

  return (
    <TableCell key="avatar" className={cx(styles.bodyCell, styles.assignCell)} id="AvatarCell">
      <Tooltip title={value}>
        <div>{getAvatarName(value)}</div>
      </Tooltip>
    </TableCell>
  );
};

AvatarCell.propTypes = {
  value: PropTypes.any,
};

AvatarCell.defaultProps = {
  value: null,
};
