import { useMemo } from 'react';
import {
  Table,
  TableCell,
  TableContainer,
  TableHead,
  TableBody,
  TableRow,
  IconButton,
} from '@mui/material';
import { DeleteForever } from '@mui/icons-material';

import { Button, Typography, TextField, Dropdown } from '@/components';
import { isValidWebLink } from '@/utils';

import styles from './OrganizationContent.module.css';

const PAGE_NAME = 'EventsContent';
const DEFAULT_WIDTH = 284;

const HEADER_COLUMNS = [
  {
    key: 'event',
    label: 'Event*',
    align: 'left',
    width: '50%',
  },
  {
    key: 'hyperlink',
    label: 'Event Hyperlink*',
    align: 'left',
    width: '50%',
  },
  {
    key: 'action',
    label: '',
    width: '32px',
  },
];

const EventsContent = ({
  orgEventSubscriptions,
  setOrgEventSubscriptions,
  eventTypes,
  showError,
  editing,
}) => {
  const orgEventTypeOptions = useMemo(
    () =>
      orgEventSubscriptions?.map(({ event }, index) =>
        eventTypes.filter(
          ({ value }) =>
            value === event ||
            !orgEventSubscriptions.some(
              ({ event: eventName }, eventIndex) => eventIndex !== index && eventName === value,
            ),
        ),
      ),
    [eventTypes, orgEventSubscriptions],
  );

  const handleChangeEventSubscriptions = (changedIndex, param) => {
    setOrgEventSubscriptions(
      orgEventSubscriptions.map((eventSubscription, index) =>
        index === changedIndex ? { ...eventSubscription, ...param } : eventSubscription,
      ),
    );
  };

  return (
    <div className={styles.infoGroupContainer}>
      <Typography variant="subtitle1">Event Subscriptions</Typography>
      <TableContainer className={styles.tableContainer}>
        <Table stickyHeader size="small" className={styles.table}>
          <TableHead id={`${PAGE_NAME}_table_head`}>
            <TableRow>
              {HEADER_COLUMNS.map(({ key, label, align, width }) => (
                <TableCell key={key} align={align} className={styles.headCell} style={{ width }}>
                  <Typography variant="subtitle2">{label}</Typography>
                </TableCell>
              ))}
            </TableRow>
          </TableHead>

          <TableBody id={`${PAGE_NAME}_table_body`}>
            {orgEventSubscriptions &&
              orgEventSubscriptions.map(({ event, link }, index) => (
                <TableRow key={event} className={styles.row}>
                  <TableCell className={styles.eventCell}>
                    <Dropdown
                      size="small"
                      placeholder="Select Event"
                      id={`${PAGE_NAME}_events`}
                      value={event}
                      error={showError && !event}
                      options={orgEventTypeOptions[index]}
                      onChange={(e) =>
                        handleChangeEventSubscriptions(index, {
                          event: e.target.value,
                        })
                      }
                      style={{ width: DEFAULT_WIDTH }}
                      disabled={!editing}
                    />
                  </TableCell>
                  <TableCell className={styles.eventCell}>
                    <TextField
                      value={link}
                      error={showError && !isValidWebLink(link)}
                      onChange={(e) =>
                        handleChangeEventSubscriptions(index, {
                          link: e.target.value,
                        })
                      }
                      style={{ width: DEFAULT_WIDTH }}
                      disabled={!editing}
                    />
                  </TableCell>
                  <TableCell className={styles.eventCell} align="center">
                    {editing && (
                      <IconButton
                        onClick={() =>
                          setOrgEventSubscriptions(
                            orgEventSubscriptions.filter((_, eventIndex) => eventIndex !== index),
                          )
                        }
                        id={`${PAGE_NAME}_editOrganizationButton`}
                      >
                        <DeleteForever fontSize="small" />
                      </IconButton>
                    )}
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>

      {editing && orgEventSubscriptions?.length !== eventTypes?.length && (
        <Button
          size="small"
          variant="secondary"
          onClick={() => setOrgEventSubscriptions([...orgEventSubscriptions, {}])}
          id={`${PAGE_NAME}_editOrganizationButton`}
          className={styles.button}
        >
          Add
        </Button>
      )}
    </div>
  );
};

export default EventsContent;
