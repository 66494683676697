import { action, computed, makeObservable, observable, reaction, runInAction } from 'mobx';
import { userStore } from '@/store';
import {
  getCurrentLocalDateTime,
  getFiltersQuery,
  getTicketLogMap,
  showErrorNotification,
  showSuccessNotification,
  updateTicketLogs,
} from '@/utils';
import { taskLogApi, ticketApi } from '@/api';
import { BASE_ROUTES, ColumnType } from '@/utils/types';
import { setError } from '@/utils/errors';
import { getWorkflowConfigList } from '@/utils/staticForm';

export class TaskLogStore {
  users = null;
  parsedLogs = [];
  pageCount = 1;
  isLoading = false;
  timeout = null;

  constructor(accountStore, tableStore, filterStore) {
    makeObservable(this, {
      users: observable,
      parsedLogs: observable,
      pageCount: observable,

      selectedTickets: computed,
      isSelectedAll: computed,
      isSelectedTickets: computed,
      isIndeterminate: computed,
      onToggleSelectTicket: action,
      onSelectAllTickets: action,

      closeTickets: action,

      isLoading: observable,
      setIsLoading: action,
    });

    this.accountStore = accountStore;
    this.tableStore = tableStore;
    this.filterStore = filterStore;

    this.disposeResetPagination = reaction(
      () => [this.accountStore.selectedAccount?.id],
      () => this.resetPagination(),
      { fireImmediately: true },
    );
  }

  // NOTE: Get assign info
  getPicture(userId) {
    return this.users.find((user) => user.id === userId)?.picture;
  }

  // NOTE: Reset pagination settings
  resetPagination() {
    this.tableStore.setPageNumber(1);
  }

  updateAdvancedLogs(logs) {
    this.parsedLogs = logs.map((log) => getTicketLogMap(log));
  }

  // NOTE: Fetch Log list
  async fetchTaskLog(accountId, query, sort) {
    if (!accountId) return;
    this.setIsLoading(true);
    this.parsedLogs = [];
    try {
      const urlParams = window.location.search;
      const res = await taskLogApi.getTaskLogs(accountId, query, sort);

      runInAction(() => {
        userStore.setUrlParams(BASE_ROUTES.main, urlParams);
        this.pageCount = Math.ceil(res.pageCount / this.tableStore.rowsPerPage);
        if (this.tableStore.pageNumber > this.pageCount) {
          this.tableStore.setPageNumber(1);
        }
        this.filterStore.filteredSearch = this.filterStore.search;
        this.updateAdvancedLogs(res.logs);
      });
    } catch (err) {
      setError(err, false);
    }
    this.setIsLoading(false);
  }

  async fetchBulkTaskLog(accountId, query, sort) {
    this.setIsLoading(true);
    this.parsedLogs = [];
    try {
      const urlParams = window.location.search;
      const res = await taskLogApi.getBulkTaskLogs(accountId, query, sort);
      runInAction(() => {
        userStore.setUrlParams(BASE_ROUTES.main, urlParams);
        this.pageCount = Math.ceil(res.pageCount / this.tableStore.rowsPerPage);
        if (this.tableStore.pageNumber > this.pageCount) {
          this.tableStore.setPageNumber(1);
        }
        this.filterStore.filteredSearch = this.filterStore.search;
        this.parsedLogs = res.logs;
      });
    } catch (err) {
      setError(err, false);
    }
    this.setIsLoading(false);
  }

  get selectedTickets() {
    return this.parsedLogs.filter((ticket) => ticket[ColumnType.select]);
  }

  get isSelectedAll() {
    const selectedCount = this.selectedTickets.length;
    return selectedCount === this.parsedLogs.length;
  }

  get isSelectedTickets() {
    return this.selectedTickets.length > 0;
  }

  get isIndeterminate() {
    return !this.isSelectedAll && this.isSelectedTickets;
  }

  onToggleSelectTicket(id) {
    this.parsedLogs = this.parsedLogs.map((ticket) =>
      ticket.id === id ? { ...ticket, [ColumnType.select]: !ticket[ColumnType.select] } : ticket,
    );
  }

  onSelectAllTickets(value) {
    this.parsedLogs = this.parsedLogs.map((ticket) => ({
      ...ticket,
      [ColumnType.select]: value,
    }));
  }

  async closeTickets() {
    try {
      const res = await ticketApi.closeMultipleTickets(
        this.selectedTickets.map((ticket) => ticket.id),
      );
      if (res.result === 0) {
        //setError('No valid tickets to close', false, 'Close tickets failed');
        showErrorNotification('Close tickets failed');
        return;
      }

      if (!res.invalidTktIds) {
        this.selectedTickets.forEach((ticket) => {
          updateTicketLogs(this.parsedLogs, { ...ticket, state: 'Closed' });
        });
        showSuccessNotification('Tickets closed successfully');
        return;
      }

      if (res.invalidTktIds) {
        this.selectedTickets.forEach((ticket) => {
          if (!res.invalidTktIds.includes(ticket.id))
            updateTicketLogs(this.parsedLogs, { ...ticket, state: 'Closed' });
        });
        //setError(res.invalidTktIds.join(','), false, 'Some tickets failed to close');
        showErrorNotification('Some tickets failed to close');
        return;
      }
    } catch (err) {
      setError(err, false, 'Close tickets failed');
      showErrorNotification(err.preview ?? err.message);
    }
  }

  async exportToCSV() {
    const sort = {
      key: this.tableStore.sortKey,
      dir: this.tableStore.sortDirection,
    };
    const query = getFiltersQuery(
      this.tableStore.pageNumber,
      this.tableStore.rowsPerPage,
      this.filterStore.filteredResult,
      this.filterStore.search,
      this.filterStore.hasIssuesFilter,
    );

    try {
      const downloadLink = await taskLogApi.downloadLogsCSV(
        this.accountStore.selectedAccount.id,
        query,
        sort,
      );
      if (downloadLink) {
        const a = document.createElement('a');
        a.href = downloadLink;
        a.download = `AdminTicketLogs-${getCurrentLocalDateTime('YYYY-MM-DD-HH-mm')}.csv`;
        a.click();
      }
    } catch (err) {
      setError(err, false, 'Ticket logs CSV download failed');
    }
  }

  setIsLoading(isLoading) {
    this.isLoading = isLoading;
  }

  // NOTE: Other processing
  async fetchWorkflowConfigList() {
    this.setIsLoading(true);
    try {
      const { workflowConfigList } = getWorkflowConfigList();
      runInAction(() => {
        userStore.workflowConfigList = workflowConfigList;
      });
    } catch (err) {
      setError(err, false, 'Fetch Workflow configs failed!');
      userStore.workflowConfigList = [];
    }
    this.setIsLoading(false);
  }

  dispose() {
    this.disposeResetPagination();
    this.tableStore.dispose();
    this.filterStore.dispose();
  }
}
