import { action, computed, makeObservable, observable, runInAction } from 'mobx';
import { isEqual, sortBy } from 'lodash';
import { userApi, platformApi, organizationApi } from '../api';
import {
  saveToken,
  sortMembers,
  mapToUserOption,
  getStatus,
  getStatusColor,
  saveAdminSettings,
  getAdminAppSettings,
} from '../utils';
import { setError } from '../utils/errors';
import { DEFAULT_BRANDING_INFO } from '../api/constants';
import { TicketStates } from '../utils/constants';
import { RouterStore } from './RouterStore';
import { AssignType, NotificationText } from '@/utils/types';

export class UserStore {
  version = '1.0.0.0';
  currentUser = {};
  organizationId = null;
  members = [];
  assignOptions = [];
  closeTaskTrigger = false;
  isUnAuthorizedError = false;
  apiCriticalIssue = null;
  states = [];
  platformTypes = [];
  appLoaded = false;
  prevUrlParams = {};
  lastUrlLoaded = {};
  organizationLogo = null;
  organizationName = '';
  organizationHelpLink = DEFAULT_BRANDING_INFO.helpUrl;
  isRefresh = false;
  workflowConfigList = [];
  appSettings = {};
  errorAPIs = {};

  constructor() {
    makeObservable(this, {
      version: observable,
      errorAPIs: observable,
      // User parameters
      currentUser: observable,
      isWFLAdminUser: computed,
      setUser: action,
      setUserEmailEnabled: action,
      organizationId: observable,
      members: observable,
      assignOptions: observable,
      updateName: action,
      prevUrlParams: observable,
      setUrlParams: action,
      lastUrlLoaded: observable,
      setLastUrlLoaded: action,

      appSettings: observable,
      userSettings: computed,
      updatePageSetting: action,

      // Global parameters
      appLoaded: observable,
      setAppLoaded: action,
      states: observable,
      platformTypes: observable,

      closeTaskTrigger: observable,
      setCloseTaskTrigger: action,
      isUnAuthorizedError: observable,
      setIsUnAuthorizedError: action,
      apiCriticalIssue: observable,
      setApiCriticalIssue: action,

      organizationName: observable,
      organizationLogo: observable,
      organizationHelpLink: observable,
      updateLogoHelpLink: action,

      isRefresh: observable,
      setRefresh: action,

      workflowConfigList: observable,
    });

    this.routerStore = new RouterStore();
  }

  // NOTE: Update history profile

  loadAppSettings() {
    this.appSettings = getAdminAppSettings();
  }

  get userSettings() {
    return this.appSettings[this.currentUser?.id] ?? {};
  }

  getPageSettings(pagePath) {
    return this.userSettings[pagePath] ?? {};
  }

  saveAppSettings = (value) => {
    this.appSettings = value;
    saveAdminSettings(value);
  };

  updatePageSetting = (pagePath, param) => {
    const newSettings = {
      ...this.appSettings,
      [this.currentUser.id]: {
        ...this.userSettings,
        [pagePath]: { ...this.getPageSettings(pagePath), ...param },
      },
    };
    this.saveAppSettings(newSettings);
  };

  async setUrlParams(page, urlParams) {
    if (!this.appLoaded) return;

    if (!isEqual(urlParams, this.prevUrlParams[page])) {
      try {
        this.updatePageSetting(page, { path: urlParams });
        this.prevUrlParams[page] = urlParams;
      } catch (err) {
        setError(err, false, NotificationText.updateProfileLastLogError);
      }
    }
  }

  setLastUrlLoaded(pageType) {
    this.lastUrlLoaded[pageType] = true;
  }

  // NOTE: Login
  async login(accessToken) {
    saveToken(accessToken);
    this.fetchUser();
  }

  getFullName(user) {
    return `${user.first} ${user.last}`;
  }

  getUnknownUser(name) {
    return { label: name, type: AssignType.teal };
  }

  // NOTE: User manage
  setUser(user) {
    this.currentUser = { ...user };
  }

  setUserEmailEnabled(value) {
    this.currentUser.emailsEnabled = value;
  }

  get isWFLAdminUser() {
    return (
      this.organizationId === 272 &&
      this.currentUser &&
      (this.currentUser.isAdmin ||
        ['ivan cheng', 'jesse wright', 'eric grecko'].includes(
          this.currentUser.name?.toLowerCase(),
        )) //  Super admin
    );
  }

  get ownerOptions() {
    return this.assignOptions;
  }

  async fetchOrganizationInfo(parentOrgId) {
    if (parentOrgId) {
      const organizationInfo = await organizationApi.getOrganization(parentOrgId);
      this.updateLogoHelpLink(
        organizationInfo.profile?.branding?.logoUrl,
        organizationInfo.profile?.branding?.helpUrl,
      );
      this.organizationName = organizationInfo.name;
    }
  }

  async fetchUser() {
    try {
      const me = await userApi.getMyProfile();
      await this.fetchOrganizationInfo(me.organizationId);
      await this.fetchUsers(me.organizationId);
      await this.fetchPlatformList();
      runInAction(() => {
        this.currentUser = {
          ...me,
          type: this.members.find((user) => user.id === me.id)?.type,
        };
        this.organizationId = this.currentUser.organizationId;
        this.loadAppSettings();
        return this.currentUser;
      });
    } catch (err) {
      setError(err, true);
    }
  }

  async fetchUsers(organizationId) {
    try {
      const assigns = await organizationApi.getAssigners(organizationId);

      runInAction(() => {
        this.members = sortMembers(assigns);
        this.assignOptions = this.members.map(mapToUserOption);
      });
    } catch (err) {
      setError(err);
    }
    return [];
  }

  updateName(firstName, lastName) {
    this.currentUser.first = firstName;
    this.currentUser.last = lastName;
    this.currentUser.name = `${firstName} ${lastName}`;
  }

  setRefresh() {
    this.isRefresh = !this.isRefresh;
  }

  updateLogoHelpLink(logoUrl, helpLink) {
    this.organizationLogo = logoUrl || DEFAULT_BRANDING_INFO.logoUrl;
    this.organizationHelpLink = helpLink || DEFAULT_BRANDING_INFO.helpUrl;
  }

  async fetchPlatformList() {
    try {
      const states = await platformApi.getPickList(TicketStates);
      const platformTypes = await platformApi.getPlatformTypes();
      runInAction(() => {
        this.states = sortBy(states, 'name').map((item) => {
          const status = getStatus(item.name);
          return {
            id: item.id,
            value: status,
            label: item.name,
            color: getStatusColor(status),
          };
        });
        this.platformTypes = platformTypes;
      });
    } catch (err) {
      setError(err, true);
    }
  }

  setAppLoaded() {
    this.appLoaded = true;
  }

  setCloseTaskTrigger() {
    this.closeTaskTrigger = !this.closeTaskTrigger;
  }

  setIsUnAuthorizedError() {
    this.isUnAuthorizedError = true;
  }

  setApiCriticalIssue(issue) {
    this.apiCriticalIssue = issue;
  }

  dispose() {
    this.routerStore.dispose();
  }
}
/* Store end */

export const userStore = new UserStore();
