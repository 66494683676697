import { makeStyles } from "@mui/styles";

export const useStyles = makeStyles((theme) => ({
  sideContainer: {
    display: "flex",
    flexDirection: "column",
    flex: 1,
    overflow: "hidden",
    padding: "4px",
    background: "#F8F8F8",
    borderRadius: "8px",
  },
  sideContent: {
    height: "100%",
    display: "flex",
    flexDirection: "column",
    gap: "8px",
  },
  tableContainer: {
    width: "100%",
    height: "100%",
    overflow: "auto ",
    position: "relative",
    "&:hover": {
      "&::-webkit-scrollbar-thumb": {
        background: "#B8B9BB !important",
      },
    },
    "&::-webkit-scrollbar": {
      width: 8,
      height: 8,
    },
    "&::-webkit-scrollbar-track": {
      borderRadius: 100,
      marginTop: 33,
    },
    "&::-webkit-scrollbar-thumb": {
      backgroundClip: "padding-box",
      borderRadius: 100,
      background: "transparent !important",
    },
    "&::-webkit-scrollbar-corner": {
      background: "transparent",
    },
  },
  table: {
    minWidth: 480,
    tableLayout: "fixed",
  },
  row: {
    "&:hover": {
      cursor: "pointer",
      boxShadow: "0px 4px 4px rgba(177, 185, 205, 0.5) !important",
      position: "sticky",
    },
  },
  selected: {
    background: theme.palette.secondary.lightBlue,
    fontWeight: "700 !important",
  },
  headCell: {
    padding: "4px 0 !important",
    whiteSpace: "nowrap",
    background: `#F8F8F8 !important`,
    borderBottom: "1px solid rgba(211, 211, 211, 0.4) !important",
  },
  topItem: {
    display: "flex",
    flexDirection: "column",
    paddingTop: "5px",
  },
  collapseContainer: {
    display: "flex",
    alignItems: "center",
  },
  collapseIcon: {
    width: "15px !important",
    height: "13px !important",
    background: "#FFF",
    padding: "6px 5px",
    boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.15)",
    borderRadius: "100px",
    cursor: "pointer",
    position: "absolute",
    right: "-12px",
    "&:hover": {
      background: `${theme.palette.neutrals.grayBlue} !important`,
    },
  },
  collapsed: {
    transform: "rotate(180deg) !important",
  },
  expandIcon: {
    color: `${theme.palette.primary.purple} !important`,
  },
  accordionExpanded: {
    backgroundColor: `transparent !important`,
    "&:before": {
      opacity: `1 !important`,
    },
  },
  accordionDetails: {
    padding: "6px 0px 6px 16px !important",
    gap: 0,
  },
  searchRoot: {
    width: "calc(100% - 12px)",
  },
}));
