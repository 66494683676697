import { useEffect, useRef } from 'react';
import { observer } from 'mobx-react-lite';
import { Table, TableCell, TableContainer, TableHead, TableBody, TableRow } from '@mui/material';
import cx from 'clsx';

import OrganizationCell from './OrganizationCell';
import { DateTimeCell, TextCell, Typography } from '@/components';
import { CheckboxCell } from '@/components/Table/CheckboxCell';
import { useSettingsStore, userStore } from '@/store';
import { useStyles } from './styles';

const PAGE_NAME = 'OrganizationTree';

const HEADER_COLUMNS = [
  {
    key: 'id',
    label: 'Id',
    align: 'left',
    width: '48px',
  },
  {
    key: 'name',
    label: 'Name',
    align: 'left',
    width: '100%',
  },
  {
    key: 'platforms',
    label: 'Platforms',
    align: 'left',
    width: '96px',
  },
  {
    key: 'created',
    label: 'CreatedOn',
    align: 'left',
    width: '80px',
  },
  {
    key: 'isActive',
    label: 'IsActive',
    align: 'left',
    width: '60px',
  },
  {
    key: 'isCaptcha',
    label: 'IsCaptcha',
    align: 'left',
    width: '72px',
  },
];

const OrganizationTable = ({ onClickRow }) => {
  const styles = useStyles();
  const { accountStore } = useSettingsStore();
  const tableRef = useRef(null);
  const initLoadingRef = useRef(true);

  useEffect(() => {
    if (initLoadingRef.current && tableRef.current && accountStore.selectedAccount?.id) {
      const index = accountStore.filteredOrganizations.findIndex(
        ({ id }) => id === accountStore.selectedAccount.id,
      );
      const timeout = setTimeout(() => {
        tableRef.current.scrollTo({
          top: index * 20,
          behavior: 'smooth',
        });
        initLoadingRef.current = false;
      }, 300);
      return () => clearTimeout(timeout);
    }
  }, [accountStore.selectedAccount?.id]);

  return (
    <div className={styles.sideContainer}>
      <div className={styles.sideContent}>
        {accountStore.filteredOrganizations?.length > 0 && (
          <TableContainer className={styles.tableContainer} ref={tableRef}>
            <Table stickyHeader size="small" id={`${PAGE_NAME}_table`} className={styles.table}>
              <TableHead id={`${PAGE_NAME}_table_head`}>
                <TableRow>
                  {HEADER_COLUMNS.map(({ label, align, width }) => (
                    <TableCell
                      key={label}
                      align={align}
                      className={styles.headCell}
                      style={{ width }}
                    >
                      <Typography variant="button-small">{label}</Typography>
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>

              <TableBody id={`${PAGE_NAME}_table_body`}>
                {accountStore.filteredOrganizations.map(
                  (
                    {
                      id,
                      name,
                      isExpanded,
                      children,
                      path,
                      orgPlatformNames,
                      createdOn,
                      profile,
                      isActive,
                    },
                    index,
                  ) => (
                    <TableRow
                      key={id}
                      onClick={() => onClickRow(id)}
                      className={cx(styles.row, {
                        [styles.selected]: accountStore.selectedAccount?.id === id,
                      })}
                    >
                      <TextCell id={`${PAGE_NAME}_id`} column="id" value={`${id}`} />
                      <OrganizationCell
                        isChild={
                          id !== userStore.organizationId &&
                          (accountStore.isEmptyFilter || accountStore.isSearchByPath) &&
                          children.length > 0
                        }
                        isAllAccount={index === 0}
                        isExpanded={isExpanded}
                        label={name}
                        level={path.length - 2}
                        onToggleExpand={(e) => {
                          e.stopPropagation();
                          accountStore.onToggleExpand(id);
                        }}
                      />
                      <TextCell
                        id={`${PAGE_NAME}_platforms`}
                        column="platforms"
                        value={orgPlatformNames}
                      />
                      <DateTimeCell
                        id={`${PAGE_NAME}_created`}
                        column="created"
                        value={createdOn}
                      />
                      <CheckboxCell value={isActive} />
                      <CheckboxCell value={profile?.settings?.isCaptcha} />
                    </TableRow>
                  ),
                )}
              </TableBody>
            </Table>
          </TableContainer>
        )}
      </div>
    </div>
  );
};

export default observer(OrganizationTable);
