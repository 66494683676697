import PropTypes from "prop-types";
import TableCell from "@mui/material/TableCell";
import Typography from "../Typography";
import { formatLocalDateTime } from "@/utils";
import { useStyles } from "./sharedStyles";
import cx from "clsx";

export const DateTimeCell = ({
  column,
  value,
  isLastRow,
  format = "MM/DD/YYYY",
}) => {
  const styles = useStyles();

  return (
    <TableCell
      key={column}
      className={cx(styles.bodyCell, styles.textCell, {
        [styles.bodyLastRightCell]: isLastRow,
      })}
      id="DateTimeCell"
    >
      <Typography variant="caption">
        {formatLocalDateTime(value, format) || ""}
      </Typography>
    </TableCell>
  );
};

DateTimeCell.propTypes = {
  column: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
};
