import { Tooltip } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { AutomationStatusIcons } from '@/utils/types';
import { TextLink, Typography } from '..';

const useStyles = makeStyles(({ palette }) => ({
  automationTimeLog: {
    display: 'flex',
    flexDirection: 'row',
    gap: 8,
    alignItems: 'center',
    padding: '6px 20px',
    borderRadius: '30px',
    color: palette.secondary.darkBlue,
    backgroundColor: palette.options.hover,
    cursor: 'default',
    width: 'fit-content',
  },
  tooltipContainer: {
    padding: '8px 13px 8px 8px !important',
    borderRadius: '12px !important',
    background: 'white !important',
    border: '1px solid #DDD',
    boxShadow: '0px 10px 10px 0px rgba(0, 0, 0, 0.10)',
  },
  rowWrapper: {
    display: 'flex',
    flexDirection: 'row',
    gap: 8,
    alignItems: 'center',
  },
  linkIcon: {
    display: 'flex',
    alignItems: 'center',
    textDecoration: 'none',
    fontSize: '18px',
    lineHeight: '20px',
    color: ({ statusColor }) => `${statusColor} !important`,
  },
  columnWrapper: {
    display: 'flex',
    flexDirection: 'column',
    gap: 4,
  },
  name: {
    fontWeight: 700,
    color: palette.neutrals.grey,
  },
  description: {
    color: palette.neutrals.greyBlueDark,
  },
}));

const AutomationTooltip = ({ automation }) => {
  const classes = useStyles();
  const { name, automationTime } = automation;

  return (
    <div className={classes.columnWrapper}>
      <Typography variant="subtitle2" className={classes.name}>
        {name}
      </Typography>
      <Typography variant="subtitle2" className={classes.description}>
        {automationTime}
      </Typography>
    </div>
  );
};

export const AutomationLog = ({ automation, isAdminUser }) => {
  const classes = useStyles({ statusColor: automation?.color });
  if (!automation) return null;
  const { name, buildId, link } = automation;

  return (
    <Tooltip
      title={<AutomationTooltip automation={automation} />}
      classes={{ tooltip: classes.tooltipContainer }}
    >
      <div className={classes.rowWrapper}>
        <TextLink
          href={isAdminUser && buildId && link}
          className={classes.linkIcon}
          onClick={(e) => e.stopPropagation()}
        >
          {AutomationStatusIcons?.[name]}
        </TextLink>
      </div>
    </Tooltip>
  );
};
