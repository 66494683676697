import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Popover } from '@mui/material';
import { MoreVert } from '@mui/icons-material';
import { makeStyles } from '@mui/styles';

import { IconButton } from '../IconButton';

const useStyles = makeStyles(() => ({
  paper: {
    marginTop: '8px',
    minWidth: '293px !important',
    maxHeight: '500px !important',
    padding: '8px 0px 8px 12px',
    borderRadius: '8px !important',
    overflow: 'hidden !important',
    boxShadow: '0px 8px 25px rgba(0, 0, 0, 0.15) !important',
  },
  popoverContent: {
    display: 'flex',
    flexDirection: 'column',
    position: 'relative',
    height: '100%',
  },
}));

const PAGE_NAME = 'MoreButtonPopover';

const MoreButtonPopover = ({
  tooltipText,
  children,
  isPopoverOpen,
  popoverClassName,
  anchorOrigin,
  transformOrigin,
}) => {
  const styles = useStyles();
  const [anchorEl, setAnchorEl] = useState(null);

  const handleOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  useEffect(() => {
    if (anchorEl) {
      handleClose();
    }
  }, [isPopoverOpen]);

  return (
    <>
      <IconButton
        id="more-button-popover"
        size="small"
        tooltipArrow
        tooltipTitle={tooltipText}
        onClick={handleOpen}
      >
        <MoreVert fontSize="small" color="info" />
      </IconButton>

      <Popover
        anchorEl={anchorEl}
        id={`${PAGE_NAME}_Popover`}
        open={Boolean(anchorEl)}
        anchorOrigin={anchorOrigin}
        transformOrigin={transformOrigin}
        onClose={handleClose}
        classes={{ paper: styles.paper }}
        className={popoverClassName}
      >
        <div className={styles.popoverContent}>{children}</div>
      </Popover>
    </>
  );
};

MoreButtonPopover.propTypes = {
  tooltipText: PropTypes.string,
  children: PropTypes.node,
  isPopoverOpen: PropTypes.bool,
  popoverClassName: PropTypes.string,
  anchorOrigin: PropTypes.object,
  transformOrigin: PropTypes.object,
};

MoreButtonPopover.defaultProps = {
  tooltipText: '',
  children: null,
  isPopoverOpen: false,
  popoverClassName: '',
  anchorOrigin: {
    vertical: 'bottom',
    horizontal: 'center',
  },
  transformOrigin: {
    vertical: 'top',
    horizontal: 'center',
  },
};

export default MoreButtonPopover;
