import { useState } from 'react';

import { BASE_ROUTES, UrlSearch } from '@/utils/types';
import { MenuItem, MoreButtonPopover, Typography } from '@/components';
import './SideMenu.css';

const SideMenuItemPopover = ({ orgId }) => {
  const [isPopoverOpen, setIsPopoverOpen] = useState(false);

  const handleClosePopover = () => {
    setIsPopoverOpen(false);
  };

  const handleNavigation = (route) => {
    window.location.href = route;
    handleClosePopover();
  };

  return (
    <MoreButtonPopover
      popoverClassName="sidebarPopover"
      tooltipText="Organization Options"
      isPopoverOpen={isPopoverOpen}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'left',
      }}
    >
      <div className="popoverContainer">
        <MenuItem
          onClick={() =>
            handleNavigation(`${BASE_ROUTES.organizations}?${UrlSearch.organization}=${orgId}`)
          }
        >
          Configuration
        </MenuItem>
        <MenuItem
          onClick={() =>
            handleNavigation(
              `${BASE_ROUTES.users}?${UrlSearch.organization}=${orgId}&organizationId=${orgId}`,
            )
          }
        >
          Members
        </MenuItem>
        <MenuItem
          onClick={() =>
            handleNavigation(`${BASE_ROUTES.schedules}?${UrlSearch.organization}=${orgId}`)
          }
        >
          Schedules
        </MenuItem>
      </div>
    </MoreButtonPopover>
  );
};

export default SideMenuItemPopover;
