import { FilterType } from './constants';
import { TRISTATE } from './types';

export const MemberStatus = {
  active: 'Active',
  invited: 'Invited',
  inactive: 'Inactive',
  contributor: 'Contributor',
  admin: 'Admin',
  assignable: 'Assignable',
};

export const USER_COLUMN_KEYS = {
  select: 'select',
  id: 'id',
  name: 'name',
  email: 'email',
  authId: 'authId',
  path: 'organizationPathByName',
  createdOn: 'createdOn',
  status: 'status',
  active: 'isActive',
  admin: 'isAdmin',
  assignable: 'isAssignable',
  contributor: 'isContributor',
};

export const MemberStatusList = [
  { key: USER_COLUMN_KEYS.active, label: MemberStatus.active },
  { key: USER_COLUMN_KEYS.admin, label: MemberStatus.admin },
  { key: USER_COLUMN_KEYS.assignable, label: MemberStatus.assignable },
  { key: USER_COLUMN_KEYS.contributor, label: MemberStatus.contributor },
];

export const MemberFilterType = {
  status: USER_COLUMN_KEYS.status,
  organization: 'organization',
};

export const InitUserFilters = {
  [FilterType.status]: {
    key: USER_COLUMN_KEYS.status,
    label: 'Status',
    values: [
      { value: MemberStatus.active },
      { value: MemberStatus.admin },
      { value: MemberStatus.assignable },
      { value: MemberStatus.contributor },
    ],
    selectedValues: [
      TRISTATE.unchecked,
      TRISTATE.unchecked,
      TRISTATE.unchecked,
      TRISTATE.unchecked,
    ],
  },
  [FilterType.organizationId]: {
    key: 'organizationId',
    label: 'Organization',
    values: [],
    selectedValues: [],
  },
  [FilterType.viewBy]: {
    key: FilterType.viewBy,
    label: 'Show/Hide Columns',
    values: [
      { value: USER_COLUMN_KEYS.id, label: USER_COLUMN_KEYS.id },
      { value: USER_COLUMN_KEYS.name, label: USER_COLUMN_KEYS.name },
      { value: USER_COLUMN_KEYS.email, label: USER_COLUMN_KEYS.email },
      { value: USER_COLUMN_KEYS.authId, label: USER_COLUMN_KEYS.authId },
      { value: USER_COLUMN_KEYS.path, label: 'Organization Path' },
      { value: USER_COLUMN_KEYS.createdOn, label: 'Created Date' },
      { value: USER_COLUMN_KEYS.active, label: 'Active' },
      { value: USER_COLUMN_KEYS.admin, label: 'Admin' },
      { value: USER_COLUMN_KEYS.assignable, label: 'Assignable' },
      { value: USER_COLUMN_KEYS.contributor, label: 'Contributor' },
    ],
    selectedValues: [
      USER_COLUMN_KEYS.id,
      USER_COLUMN_KEYS.name,
      USER_COLUMN_KEYS.email,
      USER_COLUMN_KEYS.authId,
      USER_COLUMN_KEYS.path,
      USER_COLUMN_KEYS.status,
      USER_COLUMN_KEYS.active,
      USER_COLUMN_KEYS.admin,
      USER_COLUMN_KEYS.assignable,
      USER_COLUMN_KEYS.contributor,
    ],
  },
};

export const MemberSearchKeys = [
  USER_COLUMN_KEYS.id,
  USER_COLUMN_KEYS.name,
  USER_COLUMN_KEYS.email,
  USER_COLUMN_KEYS.authId,
  USER_COLUMN_KEYS.path,
  USER_COLUMN_KEYS.createdOn,
];

export const HEADER_COLUMNS = [
  {
    key: USER_COLUMN_KEYS.select,
    label: '',
    align: 'left',
    width: '28px',
  },
  {
    key: USER_COLUMN_KEYS.id,
    label: 'Id',
    sortKey: USER_COLUMN_KEYS.id,
    align: 'left',
    width: '48px',
  },
  {
    key: USER_COLUMN_KEYS.name,
    label: 'Name',
    align: 'left',
    sortKey: USER_COLUMN_KEYS.name,
    width: '20%',
  },
  {
    key: USER_COLUMN_KEYS.email,
    label: 'Email',
    sortKey: USER_COLUMN_KEYS.email,
    align: 'left',
    width: '25%',
  },
  {
    key: USER_COLUMN_KEYS.authId,
    label: 'AuthId',
    align: 'left',
    width: '25%',
  },
  {
    key: USER_COLUMN_KEYS.path,
    label: 'Path',
    sortKey: USER_COLUMN_KEYS.path,
    align: 'left',
    width: '30%',
  },
  {
    key: USER_COLUMN_KEYS.createdOn,
    label: 'Created Date',
    sortKey: USER_COLUMN_KEYS.createdOn,
    align: 'left',
    width: '96px',
  },
  {
    key: USER_COLUMN_KEYS.active,
    label: 'Active',
    align: 'left',
    width: '44px',
  },
  {
    key: USER_COLUMN_KEYS.admin,
    label: 'Admin',
    align: 'left',
    width: '44px',
  },
  {
    key: USER_COLUMN_KEYS.assignable,
    label: 'Assignable',
    align: 'left',
    width: '68px',
  },
  {
    key: USER_COLUMN_KEYS.contributor,
    label: 'Contributor',
    align: 'left',
    width: '76px',
  },
];

export const getFullName = (user) =>
  user.first ? `${user.first?.trim()} ${user.last?.trim()}` : null;
