import { observer } from 'mobx-react-lite';
import cx from 'clsx';
import { makeStyles } from '@mui/styles';
import { TableRow } from '@mui/material';
import { useMainStore } from '../../stores/useMainStore';
import { ColumnType } from '@/utils/types';
import { getMatch } from '@/utils';
import { StatusCell, TextCell } from '@/components';
import { AutomationCell, DateTimeCell, TicketLinkCell } from '@/components/Table';

export const useStyles = makeStyles((theme) => ({
  bodyLastLeftCell: {
    borderBottomLeftRadius: '10px',
  },
  bodyLastRightCell: {
    borderBottomRightRadius: '10px',
  },
  tableRow: {
    display: 'table-row',
    textDecoration: 'none',
    background: `#F8F8F8 !important`,
    '&:hover': {
      cursor: 'pointer',
      boxShadow: '0px 4px 4px rgba(177, 185, 205, 0.5) !important',
      position: 'sticky',
    },
  },
  selected: {
    background: `${theme.palette.secondary.lightBlue} !important`,
  },
}));

const PAGE_NAME = 'TaskLogTableRow';

export const TaskLogTableRow = observer(({ log, isLastRow }) => {
  const styles = useStyles();
  const { accountStore, tableStore, filterStore, detailStore } = useMainStore();
  const ticketId = log[ColumnType.id];

  const handleViewTicketClick = (e) => {
    e.preventDefault();
    detailStore.setSelectedTicket(log);
  };

  return (
    <TableRow
      hover
      data-role={`${PAGE_NAME}_container`}
      key={ticketId}
      className={cx(styles.tableRow, {
        [styles.selected]:
          detailStore.selectedId === ticketId || tableStore.selectedRow === ticketId,
        [styles.bodyLastLeftCell]: isLastRow,
        [styles.bodyLastRightCell]: isLastRow,
      })}
      onMouseEnter={() => tableStore.onMouseEnter(ticketId)}
      onMouseLeave={() => tableStore.onMouseLeave()}
      onClick={handleViewTicketClick}
    >
      <TicketLinkCell
        column={ColumnType.id}
        link={log.ticketUrl}
        value={ticketId}
        showCopy={tableStore.hoveredRow === ticketId}
      />
      <StatusCell id={`${PAGE_NAME}_statusCell`} value={log[ColumnType.state]} />
      {accountStore.hasChildren && (
        <TextCell
          id={`${PAGE_NAME}_accountName`}
          column={ColumnType.accountName}
          value={log[ColumnType.accountName]}
        />
      )}
      <TextCell
        id={`${PAGE_NAME}_type`}
        column={ColumnType.intentType}
        value={log[ColumnType.intentType]}
      />
      <TextCell
        id={`${PAGE_NAME}_title`}
        column={ColumnType.title}
        value={log[ColumnType.title]}
        match={getMatch(log[ColumnType.title], filterStore.filteredSearch)}
      />
      <DateTimeCell
        id={`${PAGE_NAME}_createdDate`}
        column={ColumnType.createdDate}
        value={log[ColumnType.createdDate]}
      />
      <DateTimeCell
        id={`${PAGE_NAME}_lastUpdated`}
        column={ColumnType.lastUpdated}
        value={log[ColumnType.lastUpdated]}
      />
      <AutomationCell automation={log[ColumnType.automation]} isAdminUser />
      <TextCell
        id={`${PAGE_NAME}_buildError`}
        column={ColumnType.buildError}
        value={log[ColumnType.buildError]}
        isLastRow={isLastRow}
      />
    </TableRow>
  );
});
