import { observer } from 'mobx-react-lite';
import cx from 'clsx';
import { makeStyles } from '@mui/styles';
import { TableRow } from '@mui/material';
import { useMainStore } from '../../main/stores/useMainStore';
import { BASE_ROUTES, ColumnType, UrlSearch } from '@/utils/types';
import { useSettingsStore } from '@/store';
import { StatusCell, TextCell } from '@/components';
import { DateTimeCell, TextLinkCell, TicketLinkCell } from '@/components/Table';

export const useStyles = makeStyles((theme) => ({
  bodyLastLeftCell: {
    borderBottomLeftRadius: '10px',
  },
  bodyLastRightCell: {
    borderBottomRightRadius: '10px',
  },
  tableRow: {
    display: 'table-row',
    textDecoration: 'none',
    background: `#F8F8F8 !important`,
    '&:hover': {
      cursor: 'pointer',
      boxShadow: '0px 4px 4px rgba(177, 185, 205, 0.5) !important',
      position: 'sticky',
    },
  },
  selected: {
    background: `${theme.palette.secondary.lightBlue} !important`,
  },
}));

const PAGE_NAME = 'ScheduleTableRow';

export const ScheduleTableRow = observer(({ schedule, isLastRow }) => {
  const styles = useStyles();
  const { scheduleStore } = useSettingsStore();
  const { tableStore } = useMainStore();
  const scheduleId = schedule[ColumnType.id];

  const handleSingleClick = (e) => {
    e.preventDefault();
    scheduleStore.setSelectedSchedule(schedule);
  };

  return (
    <TableRow
      hover
      data-role={`${PAGE_NAME}_container`}
      key={scheduleId}
      className={cx(styles.tableRow, {
        [styles.selected]: scheduleStore.selectedScheduleId === scheduleId,
        [styles.bodyLastLeftCell]: isLastRow,
        [styles.bodyLastRightCell]: isLastRow,
      })}
      onMouseEnter={() => tableStore.onMouseEnter(scheduleId)}
      onMouseLeave={() => tableStore.onMouseLeave()}
      onClick={handleSingleClick}
    >
      <TicketLinkCell
        column={ColumnType.ticketId}
        link={schedule.ticketUrl}
        value={schedule.ticketId}
        showCopy={tableStore.hoveredRow === scheduleId}
      />
      <TextLinkCell
        id={`${PAGE_NAME}_accountName`}
        column={ColumnType.accountName}
        value={schedule[ColumnType.accountName]}
        link={`${BASE_ROUTES.organizations}?${UrlSearch.organization}=${schedule.organizationId}`}
      />
      <TextCell id={`${PAGE_NAME}_label`} column={ColumnType.label} value={schedule.label} />
      <TextCell id={`${PAGE_NAME}_type`} column={ColumnType.intentType} value={schedule.workflow} />
      <DateTimeCell
        id={`${PAGE_NAME}_lastUpdated`}
        column={ColumnType.lastUpdated}
        value={schedule[ColumnType.lastRun]}
      />
      <DateTimeCell
        id={`${PAGE_NAME}_createdOn`}
        column={ColumnType.createdOn}
        value={schedule[ColumnType.createdOn]}
      />
    </TableRow>
  );
});
