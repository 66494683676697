import { orderBy } from 'lodash';

import { userStore } from '../store';
import { DEFAULT_PRODUCT_INFO, PRODUCT_PROPERTIES } from '../utils/constants';
import {
  AmazonBrandRegistry,
  AmazonSellerCentral,
  AmazonVendorCentral,
  GeneralPlatform,
  GoogleAds,
} from '../components/PlatformLogins';
import { WORKFLOW_CONFIGS } from './workflows';

export const PLATFORMS = {
  none: 0,
  amazonSellerCentral: 1,
  amazonVendorCentral: 2,
  amazonBrandRegistry: 4,
  googleAds: 6,
};

const PLATFORM_MAPS = {
  [PLATFORMS.amazonSellerCentral]: AmazonSellerCentral,
  [PLATFORMS.amazonVendorCentral]: AmazonVendorCentral,
  [PLATFORMS.amazonBrandRegistry]: AmazonBrandRegistry,
  [PLATFORMS.googleAds]: GoogleAds,
};

const PLATFORM_VALUES = {
  [PLATFORMS.amazonSellerCentral]: 'SC',
  [PLATFORMS.amazonVendorCentral]: 'VC',
  [PLATFORMS.amazonBrandRegistry]: 'BR',
  [PLATFORMS.googleAds]: 'GA',
};

export function getPlatformLoginForm(platformId) {
  return {
    name: userStore.platformTypes.find(({ id }) => id === platformId)?.name,
    form: PLATFORM_MAPS[platformId] || GeneralPlatform,
  };
}

export function getPlatformName(platformId) {
  return userStore.platformTypes.find(({ id }) => id === platformId)?.name || 'None';
}

export function getPlatformValue(platformId) {
  return PLATFORM_VALUES[platformId] || 'NA';
}

export const PlatformLoginValidator = ({ Username, configuration }) =>
  Boolean(Username && configuration.Account && configuration.Marketplace);

export const GeneralLoginValidator = ({ Username }) => Boolean(Username);

const LOGIN_VALIDATORS = {
  [PLATFORMS.amazonSellerCentral]: PlatformLoginValidator,
  [PLATFORMS.amazonVendorCentral]: PlatformLoginValidator,
  [PLATFORMS.amazonBrandRegistry]: PlatformLoginValidator,
  [PLATFORMS.googleAds]: GeneralLoginValidator,
};

const sortWorkflows = (workflowsList) => {
  const sortedWorkflows = orderBy(workflowsList, 'name', 'asc');
  return sortedWorkflows.map((category) => ({
    ...category,
    children: category.children?.length > 0 ? sortWorkflows(category.children) : [],
  }));
};

const makeFullTree = (workflowsList) => {
  const result = { children: [] };
  workflowsList.forEach((workflow) => {
    let currentCategory = result;
    workflow.path.forEach((name, index) => {
      const nextCategory = currentCategory.children.find((item) => item.name === name);
      if (!nextCategory) {
        const newCategory = { id: name, name, children: [], isExpanded: true };
        currentCategory.children.push(newCategory);
        currentCategory = newCategory;
      } else {
        currentCategory = nextCategory;
      }
      if (index === workflow.path.length - 1) currentCategory.children.push(workflow);
    });
  });
  return sortWorkflows(result.children);
};

export const getWorkflowConfigList = () => {
  const workflowConfigList = Object.entries(WORKFLOW_CONFIGS).map(([workflow, config]) => ({
    ...config,
    workflow,
    displayName: config.title,
    name: `${workflow}Config`,
    path: [config.category],
  }));
  const workflowTreeList = makeFullTree(workflowConfigList);
  return { workflowConfigList, workflowTreeList };
};

export function getValidPlatformLogin(platformLogin) {
  const getValidator = LOGIN_VALIDATORS[platformLogin.platform] || GeneralLoginValidator;
  return getValidator(platformLogin);
}

export function getInitialProperties(defaultValue) {
  if (!defaultValue) return [];
  return Object.entries(defaultValue)
    .filter(([key]) => !Object.keys(DEFAULT_PRODUCT_INFO).includes(key))
    .map(([key, value]) => ({
      key,
      value,
      label: PRODUCT_PROPERTIES.find(({ key: filedName }) => filedName === key)?.label ?? key,
    }));
}

export function getInitialValue(defaultValue) {
  return Object.entries(defaultValue ?? {})
    .filter(([key]) => !Object.keys(DEFAULT_PRODUCT_INFO).includes(key))
    .map(([key, value]) => ({
      key,
      value,
      label: PRODUCT_PROPERTIES.find(({ key: filedName }) => filedName === key)?.label ?? key,
    }));
}
