import moment from 'moment-timezone';

export const DEFAULT_DAYTIME_FORMAT = 'YYYY-MM-DDTHH:mm:ss';
export const DEFAULT_LOCAL_TIME_FORMAT = 'MM/DD/YYYY hh:mm:ss A';

export function convertLocalDateTime(datetime) {
  if (!datetime) return null;
  const c = moment(datetime).format(DEFAULT_DAYTIME_FORMAT);
  return c.endsWith('Z') ? c : `${c}Z`;
}

export function formatLocalDateTime(datetime, format) {
  if (!datetime) return null;
  const datetimeStr =
    datetime[datetime.length - 1] === 'Z' ? datetime.substr(0, datetime.length - 1) : datetime;
  if (format) {
    return moment(datetimeStr).format(format);
  }
  return moment(datetimeStr).format(DEFAULT_LOCAL_TIME_FORMAT);
}

export function getCurrentLocalDateTime(format) {
  if (format) {
    return moment().format(format);
  }
  return moment().format();
}

export function formatUTCDateTime(datetime, format) {
  if (!datetime) return null;
  if (format) {
    return moment(datetime).utc().format(format);
  }
  const timestr = moment(datetime).utc().format(DEFAULT_DAYTIME_FORMAT);
  return `${timestr}.000Z`;
}

export function getCurrentUTCDateTime(format) {
  if (format) {
    return moment().utc().format(format);
  }
  return moment().utc().format();
}

export function getEcdDiffDays(datetime) {
  if (!datetime) return '';
  const ecd = moment(datetime);
  const today = moment(getCurrentLocalDateTime());
  const diff = ecd.diff(today, 'days');
  return `${diff} day${diff > 1 ? 's' : ''}`;
}
