import { Config } from '@/config';
import * as fetchWithAuth from './fetchWithAuth';
import { FetchOptions } from './constants';

export const addAttachment = (discussionId, file, filename) => {
  const formData = new FormData();
  formData.append('formFile', file, filename || file.name);
  return fetchWithAuth
    .postFormData(`${Config.NEW_API}/Attachment?discussionId=${discussionId}`, formData)
    .then((resp) => resp);
};

export const deleteAttachment = (attachmentId) => {
  return fetchWithAuth.del(`${Config.NEW_API}/Attachment/${attachmentId}`).then((resp) => resp);
};

export const uploadAttachment = (file, filename) => {
  const formData = new FormData();
  formData.append('formFile', file, filename || file.name);
  return fetchWithAuth
    .postFormData(`${Config.NEW_API}/Attachment/upload`, formData)
    .then((resp) => resp);
};

export const uploadAttachmentBlob = (file) => {
  const formData = new FormData();
  formData.append('formFile', file);
  return fetchWithAuth
    .postFormData(`${Config.NEW_API}/Attachment/blob`, formData)
    .then((resp) => resp);
};

export const getAttachment = (attachedId) => {
  return fetchWithAuth
    .get(`${Config.NEW_API}/Attachment/${attachedId}`, FetchOptions.stream)
    .then((res) => res.blob())
    .then((data) => URL.createObjectURL(data));
};

export const downloadAttachment = (filename) => {
  return fetchWithAuth
    .get(`${Config.NEW_API}/Attachment/download?filename=${filename}`, FetchOptions.stream)
    .then((res) => res.blob())
    .then((data) => URL.createObjectURL(data));
};
