import AceEditor from 'react-ace';
import 'brace/mode/json'; // Import the JSON mode
import 'brace/theme/monokai'; // Import the Monokai theme

import { Typography } from '@/components';

import styles from './DetailWorkflowContent.module.css';

const JSON_LINE_HEIGHT = 17;
const JSON_EDITOR_PADDING = 28;

export const DetailWorkflowContent = ({ content }) => {
  const jsonStr = JSON.stringify(content, null, 2);
  const height = `${jsonStr.split('\n').length * JSON_LINE_HEIGHT + JSON_EDITOR_PADDING}px`;

  return (
    <>
      <div className={styles.header}>
        <Typography variant="subtitle1">Workflow Configuration</Typography>
      </div>
      <AceEditor
        mode="json"
        theme="monokai"
        readOnly
        name="json-editor"
        value={jsonStr}
        editorProps={{ $blockScrolling: true }}
        showPrintMargin={false}
        showGutter={false}
        highlightActiveLine
        fontSize={14}
        width="100%"
        height={height}
        style={{ borderRadius: '4px' }}
        enableBasicAutocompletion={false}
        enableLiveAutocompletion={false}
        enableSnippets={false}
        tabSize={2}
      />
    </>
  );
};
