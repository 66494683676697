import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles((theme) => ({
  content: {
    flex: 1,
    padding: '8px 0px 8px 8px',
    overflow: 'hidden',
  },
  container: {
    width: '100%',
    height: '100%',
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
  },
  title: {
    textTransform: 'capitalize !important',
  },
  tableBlock: {
    display: 'flex',
    flexDirection: 'column',
    minHeight: 1,
    flex: 1,
  },
  hidden: {
    display: 'none',
  },
  tableContainer: {
    width: '100%',
    height: 'calc(100% - 34px)',
    overflow: 'auto ',
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    borderRadius: 10,
    '&:hover': {
      '&::-webkit-scrollbar-thumb': {
        background: '#B8B9BB !important',
      },
    },
    '&::-webkit-scrollbar': {
      width: 8,
      height: 8,
    },
    '&::-webkit-scrollbar-track': {
      borderRadius: 100,
      marginTop: 34,
      marginBottom: 8,
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundClip: 'padding-box',
      borderRadius: 100,
      background: 'transparent !important',
    },
    '&::-webkit-scrollbar-corner': {
      background: 'transparent',
    },
  },
  headCell: {
    padding: '4px !important',
    whiteSpace: 'nowrap',
    borderBottom: '1px solid rgba(211, 211, 211, 0.4) !important',
  },
  headCellPin: {
    minWidth: '56px',
    width: '56px',
    padding: '0px !important',
    borderTopLeftRadius: '10px',
  },
  headFirstCell: {
    borderRadius: '10px 0 0',
    paddingLeft: '12px !important',
  },
  headCellLast: {
    borderRadius: '0 10px 0 0',
    width: '1%',
    paddingRight: '24px !important',
  },
  sortIcon: {
    width: '24px',
    height: '24px',
    color: `${theme.palette.secondary.darkBlue} !important`,
  },
  pagination: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginBottom: '8px',
  },
  versionLabel: {
    display: 'flex',
    flexDirection: 'row-reverse',
    marginTop: '4px',
    marginRight: '24px',
    color: theme.palette.neutrals.greyMed,
    fontSize: '14px',
    fontWeight: 400,
  },
}));
