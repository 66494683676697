import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles((theme) => ({
  tableContainer: {
    flex: 1,
    overflow: 'auto',
  },
  table: {
    boxSizing: 'border-box',
  },
  headCell: {
    height: '25px',
    padding: '8px !important',
    whiteSpace: 'nowrap',
    borderBottom: '1px solid rgba(0, 0, 0, 0.26) !important',
  },
  headFirstCell: {
    borderRadius: '10px 0 0',
  },
  bodyLastLeftCell: {
    borderBottomLeftRadius: '10px',
  },
  bodyLastRightCell: {
    borderBottomRightRadius: '10px',
  },
  row: {
    background: theme.palette.neutrals.white,
    '&:hover': {
      cursor: 'pointer',
      background: `${theme.palette.neutrals.white} !important`,
      boxShadow: '0px 4px 4px rgba(177, 185, 205, 0.5) !important',
      position: 'sticky',
    },
  },
  readRow: {
    background: theme.palette.neutrals.tableLightGray,
    '&:hover': {
      cursor: 'pointer',
      background: `${theme.palette.neutrals.tableLightGray} !important`,
      boxShadow: '0px 4px 4px rgba(177, 185, 205, 0.5) !important',
      position: 'sticky',
    },
  },
  titleCell: {
    maxWidth: '300px',
    overflow: 'hidden',
  },
  bodyCell: {
    ...theme.typography.caption,
    borderBottom: '0px !important',
    padding: '0px !important',
  },
  linkCell: {
    display: 'flex !important',
    alignItems: 'center',
  },
  pinCell: {
    color: theme.palette.secondary.darkBlue,
    minWidth: '20px',
  },
  textCell: {
    color: theme.palette.secondary.darkBlue,
    paddingRight: '8px !important',
    maxWidth: '320px',
  },
  assignCell: {
    padding: '2px 2px 2px 0px !important',
  },
  readCell: {
    padding: '8px 0px 8px 0px !important',
  },
  readDot: {
    width: '10px',
    height: '10px',
    borderRadius: '50%',
    background: theme.palette.ui.error,
  },
  description: {
    display: '-webkit-box',
    overflow: 'hidden',
    WebkitLineClamp: 2,
    WebkitBoxOrient: 'vertical',
    overflowWrap: 'anywhere',
  },
  textLabel: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    lineHeight: '20px',
    textOverflow: 'ellipsis',
  },
  expandCell: {
    display: 'flex',
    alignItems: 'center',
    color: theme.palette.secondary.darkBlue,
    gap: 4,
  },
  expandIcon: {
    width: '14px',
  },
  moreIcon: {
    transform: 'rotate(-90deg)',
    transition: 'transform .8s ease-in-out',
    '&:hover': {
      borderRadius: '50%',
      background: theme.palette.neutrals.lightGrey,
    },
  },
  disabled: {
    color: theme.palette.neutrals.grayBlue,
  },
  configWrapper: {
    display: 'flex',
    alignItems: 'center',
    paddingLeft: '12px',
    fontSize: '20px',
  },
  actionIcon: {
    padding: '0 4px',
    '&:hover': {
      boxShadow: '0px 4px 4px #4B4E57 !important',
    }
  },
  delete: {
    fontSize: '16px',
    fontWeight: 'bold',
    color: theme.palette.ui.error,
  },
}));
