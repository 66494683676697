import { userStore } from '../store';
import { orderBy } from 'lodash';
import { AssignType, Unassigned } from './types';

export const AssignTypeOptions = [
  AssignType.lightBlue,
  AssignType.lightPurple,
  AssignType.darkBlue,
  AssignType.purple,
  AssignType.teal,
];

export const isCreatedByYou = (assignTo) => {
  return assignTo === userStore.currentUser.id;
};

export function getAssignFromId(userId, userOptions = userStore.assignOptions) {
  const matchedUser = userOptions?.find((member) => member.id === userId);
  if (matchedUser) {
    return {
      ...matchedUser,
      value: userId,
    };
  }
  return Unassigned;
}

export function getAssignFromName(name, assigns = userStore.assignOptions) {
  const matchedUser = assigns?.find((member) => member.name === name || member.label === name);
  if (matchedUser) {
    return {
      ...matchedUser,
      value: matchedUser.id,
    };
  }
  return Unassigned;
}

export function sortMembers(members) {
  const sortedMembers = orderBy(members, [(user) => user.name.toLowerCase()], 'asc');
  const result = (sortedMembers || []).map((user, index) => {
    let type = AssignTypeOptions[index % AssignTypeOptions.length];
    const name = user.name?.toLowerCase();
    if (name.startsWith(Unassigned.name.toLowerCase())) {
      type = AssignType.unassigned;
    } else if (name.includes('amazon')) type = AssignType.amazon;
    else if (name.includes('helpdesk') || name.includes('workflow')) type = AssignType.helpdesk;
    return {
      ...user,
      type,
    };
  });
  return result;
}

export const mapToUserOption = (user) => ({
  id: user.id,
  value: user.id,
  label: user.name,
  email: user.email,
  type: user.type,
});
