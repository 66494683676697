import { action, computed, makeObservable, observable, reaction, runInAction } from 'mobx';
import moment from 'moment-timezone';

import { attachmentApi, detailApi, organizationApi, taskLogApi, ticketApi } from '@/api';
import { userStore } from '@/store';
import {
  getAssignFromId,
  getCurrentLocalDateTime,
  getStateId,
  mapToUserOption,
  showErrorNotification,
  showSuccessNotification,
  sortMembers,
  updateTicketLogs,
} from '@/utils';
import { SCHEDULE_MODE, TICKET_TABS } from '@/utils/constants';
import { setError } from '@/utils/errors';
import { AutomationStatusColors, NotificationText } from '@/utils/types';
import { parseCaseMessage } from '@/api/detail';

export class DetailContentStore {
  parsedTicket = null;
  selectedTicket = null;
  scheduleMode = SCHEDULE_MODE.none;
  isLoading = false;
  isFullWidth = false;
  isFullScreen = false;
  selectedTab = TICKET_TABS.schedules;
  assignOptions = [];
  isShowTicket = false;
  scheduleList = [];
  triggeredTickets = [];
  selectedSchedule = null;

  constructor(taskLogStore) {
    makeObservable(this, {
      parsedTicket: observable,
      organizationId: computed,
      setSelectedTicket: action,

      selectedTicket: observable,
      selectedId: computed,
      canExecute: computed,

      isShowTicket: observable,

      scheduleList: observable,
      setScheduleList: action,

      triggeredTickets: observable,
      setTriggeredTickets: action,

      selectedSchedule: observable,
      setSelectedSchedule: action,
      selectedScheduleId: computed,

      scheduleMode: observable,
      setScheduleMode: action,

      isFullWidth: observable,
      isFullScreen: observable,
      toggleFullWidth: action,
      toggleFullScreen: action,

      selectedTab: observable,
      setSelectedTab: action,

      assignOptions: observable,
      ownerOptions: computed,

      isLoading: observable,
      setIsLoading: action,
    });

    this.taskLogStore = taskLogStore;
    this.disposeAssignReaction = reaction(
      () => [this.organizationId],
      () => this.fetchAssigns(),
      { fireImmediately: true },
    );
  }

  async setSelectedTicket(ticket) {
    this.isShowTicket = true;
    this.selectedTicket = ticket;
    if (ticket) {
      await this.fetchTicket(ticket.id);
    } else this.parsedTicket = ticket;
  }

  setScheduleList(value) {
    this.scheduleList = value;
  }

  setTriggeredTickets(value) {
    this.triggeredTickets = value;
  }

  get selectedId() {
    return this.selectedTicket?.id;
  }

  get canExecute() {
    const { name, automationTime } = this.parsedTicket?.automation ?? {};
    const localAutomationTime = moment(automationTime);
    const currentTime = moment();
    // Calculate difference in milliseconds
    const differenceInMilliseconds = currentTime.diff(localAutomationTime);
    const differenceInSeconds = moment.duration(differenceInMilliseconds).asSeconds();

    if (
      userStore.isWFLAdminUser &&
      name === 'Failed' &&
      automationTime &&
      differenceInSeconds > 30
    ) {
      const { rerun } =
        userStore.workflowConfigList.find(({ value }) => value === this.parsedTicket.workflow) ??
        {};
      return !!rerun;
    } else {
      return false;
    }
  }

  async executeTicket() {
    if (!this.selectedId) return;
    try {
      const buildId = await taskLogApi.executeTicket(this.selectedId);

      runInAction(() => {
        this.parsedTicket.automation = {
          ...this.parsedTicket.automation,
          name: 'Queued',
          color: AutomationStatusColors.Queued,
          automationTime: getCurrentLocalDateTime(),
        };
        showSuccessNotification(`Build Id ${buildId} is running!`);
      });
    } catch (err) {
      setError(err, false, 'Execute ticket failed');
      showErrorNotification(err.preview ?? err.message);
    }
  }

  get workflowConfig() {
    return this.parsedTicket?.workflow;
  }

  get ticketId() {
    return this.parsedTicket?.id;
  }

  toggleFullWidth() {
    this.isFullWidth = !this.isFullWidth;
    if (!this.isFullWidth && this.selectedTab === TICKET_TABS.details) {
      this.setSelectedTab(TICKET_TABS.configuration);
    }
  }

  toggleFullScreen() {
    this.isFullScreen = !this.isFullScreen;
  }

  setSelectedTab(tab) {
    this.selectedTab = tab;
  }

  isSelectedTab(tab) {
    return this.selectedTab === tab;
  }

  setIsLoading(value) {
    this.isLoading = value;
  }

  get ownerOptions() {
    return this.assignOptions;
  }

  get organizationId() {
    return this.selectedTicket?.accountId;
  }

  setHeight(value) {
    this.height = value;
  }

  setSelectedSchedule(value) {
    this.selectedSchedule = value;
  }

  get selectedScheduleId() {
    return this.selectedSchedule?.id;
  }

  setScheduleMode(value) {
    this.scheduleMode = value;
  }

  async fetchTicket(ticketId) {
    this.setIsLoading(true);
    try {
      const ticketInfo = await detailApi.getTicket(ticketId);
      await this.fetchDetailContent(ticketId);
      runInAction(() => {
        this.parsedTicket = {
          ...ticketInfo,
          discussions: ticketInfo.discussions?.map((discussion) =>
            parseCaseMessage(discussion, this.assignOptions),
          ),
        };
      });
    } catch (err) {
      setError(err);
      showErrorNotification(NotificationText.detailPageFetchError);
    }
    this.setIsLoading(false);
  }

  async updateTicket(data, warningTitle = 'Update Ticket Error') {
    this.setIsLoading(true);
    let result = false;
    try {
      const savingData = {
        title: data.title,
        description: data.description,
        ownedBy: data.ownedBy,
        assignedTo: data.assignedTo,
        priority: data.priority,
        stateId: getStateId(data.state),
        quantity: data.remainingWork,
      };
      await ticketApi.updateTicket(data.ticketId, savingData);

      runInAction(() => {
        const newTicket = {
          ...data,
          ownedByName: getAssignFromId(data.ownedBy)?.label,
        };
        updateTicketLogs(this.taskLogStore.parsedLogs, newTicket);
        this.setSelectedTicket(newTicket);
        result = true;
      });
      showSuccessNotification(NotificationText.createTicketSuccess);
    } catch (err) {
      setError(err, false, warningTitle);
      showErrorNotification(warningTitle);
    }
    this.setIsLoading(false);
    return result;
  }

  async saveSchedule(schedule) {
    try {
      const newSchedule = await ticketApi.saveSchedule(schedule);
      runInAction(() => {
        if (schedule.id) {
          const idx = this.scheduleList.findIndex((s) => s.id === schedule.id);
          if (idx > -1) {
            this.scheduleList[idx] = newSchedule;
          }
        } else {
          this.scheduleList = [...this.scheduleList.slice(0, -1), newSchedule];
        }
      });
      return newSchedule;
    } catch (err) {
      setError(err, true, `Failed to ${schedule.id ? 'save' : 'create'} schedule`);
    }
  }

  async deleteSchedule(scheduleId) {
    try {
      await ticketApi.deleteSchedule(scheduleId);
      runInAction(() => {
        this.scheduleList = this.scheduleList.filter((s) => s.id !== scheduleId);
      });
    } catch (err) {
      setError(err, true, `Failed to delete schedule`);
    }
  }

  async fetchAssigns() {
    if (!this.organizationId) return;
    try {
      const assigns = await organizationApi.getAssigners(this.organizationId);
      runInAction(() => {
        this.assigns = sortMembers(assigns);
        this.assignOptions = this.assigns.map(mapToUserOption);
      });
    } catch (err) {
      setError(err);
      showErrorNotification(NotificationText.detailPageFetchError);
    }
  }

  async updateComment(discussionId, comment, addingFiles, removingFiles) {
    this.setIsLoading(true);
    try {
      await addingFiles.reduce(
        (callback, file) =>
          callback.then(async () => {
            await attachmentApi.addAttachment(discussionId, file);
          }),
        Promise.resolve(),
      );
      await removingFiles.reduce(
        (callback, file) =>
          callback.then(async () => {
            await attachmentApi.deleteAttachment(file.id);
          }),
        Promise.resolve(),
      );
      await ticketApi.updateComment(discussionId, comment);

      await this.fetchTicket(this.ticketId);
    } catch (err) {
      setError(err, false, NotificationText.updateCommentError);
      showErrorNotification(NotificationText.updateCommentError);
    }

    this.setIsLoading(false);
  }

  async deleteComment(commentId, files, isRequest = false) {
    if (!commentId) return;
    this.setIsLoading(true);
    try {
      await files.reduce(
        (callback, file) =>
          callback.then(async () => {
            await attachmentApi.deleteAttachment(file.id);
          }),
        Promise.resolve(),
      );
      await ticketApi.deleteComment(commentId);

      await this.fetchTicket(this.ticketId);
    } catch (err) {
      setError(err, false, NotificationText.deleteCommentError);
      showErrorNotification(NotificationText.deleteCommentError);
    }
    this.setIsLoading(false);
  }

  async fetchDetailContent(ticketId) {
    this.setIsLoading(true);
    try {
      const scheduleList = await ticketApi.getScheduleList(null, ticketId);
      const triggeredTickets = await taskLogApi.getTriggeredTickets(
        ticketId,
      );
      runInAction(() => {
        this.scheduleList = scheduleList ?? [];
        this.triggeredTickets = triggeredTickets ?? [];
      });
    } catch (err) {
      setError(err, true, 'Fetch schedule list failed');
    }
    this.setIsLoading(false);
  }

  dispose() {
    this.disposeAssignReaction();
  }
}
