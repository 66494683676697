import { useMemo } from 'react';
import PropTypes from 'prop-types';
import cx from 'clsx';
import { Tooltip, useTheme } from '@mui/material';
import { makeStyles } from '@mui/styles';
import HelpdeskIcon from '@/assets/icons/helpDeskIcon.svg';
import { WFLOrganizationIcon, WFLWhiteIcon } from '@/assets/icons';
import AmazonIcon from '@/assets/icons/AmazonIcon.svg';
import UserIcon from '@/assets/icons/User.svg';
import { getAvatarName, isStorybook } from '@/utils';
import { AssignType } from '@/utils/types';

const useStyles = makeStyles((theme) => ({
  avatar: {
    fontFamily: 'Open Sans',
    fontSize: '16px',
    fontWeight: '700',
    letterSpacing: '0.15px',
    borderRadius: '50%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    userSelect: 'none',
  },
  svgIcon: {
    padding: '6px',
  },
  smallFontSize: {
    fontSize: '12px',
  },
  checked: {
    cursor: 'pointer',
    border: '5px solid rgb(215, 205, 254)',
  },
  unchecked: {
    cursor: 'pointer',
    border: ({ borderColor }) => `5px solid ${borderColor || 'rgb(251, 251, 253)'}`,
    '&:hover': {
      border: '5px solid rgb(215, 205, 254)',
    },
  },
}));

const ADVANCED_SIZE = isStorybook() ? 46 : 36;

const Avatar = ({
  isSelected,
  name,
  width,
  children,
  variant,
  borderColor,
  bgColor,
  foreColor,
  isEditable,
  checked,
  tooltipText,
  tooltipPlacement,
  tooltipClassName,
  tooltipArrow,
  src,
  className,
  ...props
}) => {
  const avatarName = useMemo(() => getAvatarName(name), [name]);
  const theme = useTheme();
  const styles = useStyles();

  let backColor = theme.palette.secondary.lightPurple;
  let color = theme.palette.neutrals.grey;
  if (variant === AssignType.lightBlue) {
    backColor = theme.palette.secondary.lightBlue;
  } else if (variant === AssignType.lightPurple) {
    backColor = theme.palette.secondary.lightPurple;
  } else if (variant === AssignType.darkBlue) {
    backColor = '#1976D2';
    color = '#fff';
  } else if (variant === AssignType.purple) {
    backColor = theme.palette.primary.purple;
    color = '#fff';
  } else if (variant === AssignType.teal) {
    backColor = '#00838F';
    color = '#fff';
  } else if (variant === AssignType.organization) {
    backColor = 'rgba(103, 83, 179, 0.4)';
    color = '#fff';
  }

  if (bgColor) {
    backColor = bgColor;
  }
  if (foreColor) {
    color = foreColor;
  }

  let imageStyle;
  if (
    src ||
    variant === AssignType.helpdesk ||
    variant === AssignType.workflow ||
    variant === AssignType.amazon ||
    variant === AssignType.unassigned
  ) {
    imageStyle = {
      backgroundSize: 'cover',
      backgroundRepeat: 'no-repeat',
    };
    if (variant === AssignType.helpdesk) {
      imageStyle = { ...imageStyle, backgroundImage: `url(${HelpdeskIcon})` };
    } else if (variant === AssignType.workflow) {
      imageStyle = {
        ...imageStyle,
        backgroundImage: `url(${WFLOrganizationIcon})`,
      };
    } else if (variant === AssignType.amazon) {
      backColor = '#fff';
      imageStyle = { ...imageStyle, backgroundImage: `url(${AmazonIcon})` };
    } else if (variant === AssignType.unassigned) {
      backColor = `${theme.palette.neutrals.grayBlueDark}`;
      imageStyle = {
        ...imageStyle,
        backgroundImage: `url(${UserIcon})`,
        backgroundSize: '24px 24px',
        backgroundPosition: 'center',
      };
    } else {
      imageStyle = { ...imageStyle, backgroundImage: `url(${src})` };
    }
  }

  return (
    <Tooltip
      arrow={tooltipArrow}
      title={tooltipText}
      placement={tooltipPlacement}
      classes={{ tooltip: tooltipClassName }}
    >
      {variant === AssignType.workflow ? (
        <>
          {isSelected ? (
            <WFLWhiteIcon
              className={cx(
                styles.avatar,
                styles.svgIcon,
                {
                  [styles.checked]: isEditable && checked,
                  [styles.unchecked]: isEditable && !checked,
                },
                className,
              )}
              style={
                isStorybook()
                  ? {
                      minWidth: isEditable ? ADVANCED_SIZE : width,
                      width: isEditable ? ADVANCED_SIZE : width,
                      height: isEditable ? ADVANCED_SIZE : width,
                      backgroundColor: backColor,
                      color,
                    }
                  : {}
              }
              {...props}
            />
          ) : (
            <WFLOrganizationIcon
              className={cx(
                styles.avatar,
                styles.svgIcon,
                {
                  [styles.checked]: isEditable && checked,
                  [styles.unchecked]: isEditable && !checked,
                },
                className,
              )}
              style={
                isStorybook()
                  ? {
                      minWidth: isEditable ? ADVANCED_SIZE : width,
                      width: isEditable ? ADVANCED_SIZE : width,
                      height: isEditable ? ADVANCED_SIZE : width,
                      backgroundColor: backColor,
                      color,
                    }
                  : {}
              }
              {...props}
            />
          )}
        </>
      ) : (
        <div
          variant={variant}
          id="avatar"
          className={cx(
            styles.avatar,
            {
              [styles.checked]: isEditable && checked,
              [styles.unchecked]: isEditable && !checked,
              [styles.smallFontSize]: width <= 30,
            },
            className,
          )}
          style={{
            minWidth: isEditable ? ADVANCED_SIZE : width,
            width: isEditable ? ADVANCED_SIZE : width,
            height: isEditable ? ADVANCED_SIZE : width,
            backgroundColor: backColor,
            color,
            ...imageStyle,
          }}
          {...props}
        >
          {variant === AssignType.helpdesk ||
          variant === AssignType.workflow ||
          variant === AssignType.unassigned ||
          variant === AssignType.amazon
            ? null
            : avatarName || children}
        </div>
      )}
    </Tooltip>
  );
};

Avatar.propTypes = {
  isSelected: PropTypes.bool,
  width: PropTypes.number,
  children: PropTypes.node,
  variant: PropTypes.oneOf([
    AssignType.unassigned,
    AssignType.user,
    AssignType.lightBlue,
    AssignType.lightPurple,
    AssignType.darkBlue,
    AssignType.purple,
    AssignType.teal,
    AssignType.organization,
    AssignType.helpdesk,
    AssignType.workflow,
    AssignType.amazon,
  ]),
  isEditable: PropTypes.bool,
  checked: PropTypes.bool,
  src: PropTypes.string,
  name: PropTypes.string,
  tooltipText: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  tooltipPlacement: PropTypes.oneOf(['left', 'right', 'bottom', 'top']),
  tooltipClassName: PropTypes.string,
  tooltipArrow: PropTypes.bool,
};

Avatar.defaultProps = {
  isSelected: false,
  width: 28,
  variant: AssignType.user,
  isEditable: false,
  checked: false,
  src: null,
  name: '',
  tooltipText: '',
  tooltipPlacement: 'bottom',
  tooltipClassName: '',
  tooltipArrow: true,
};

export default Avatar;
