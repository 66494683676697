import { FetchOptions } from './constants';
import * as fetchWithAuth from './fetchWithAuth';
import { Config } from '@/config';

export const downloadReportsCSV = async (reportType) => {
  return fetchWithAuth
    .get(
      `${Config.NEW_API}/Reports/${reportType}`,
      FetchOptions.stream,
    )
    .then((res) => res.blob())
    .then((data) => {
      if (data instanceof Blob) {
        return URL.createObjectURL(data);
      } else {
        return null;
      }
    });
};