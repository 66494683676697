import { useState } from 'react';

import { reportsApi } from '@/api';
import { Button, Typography } from '@/components';
import { setError } from '@/utils/errors';

import styles from './ReportsPage.module.css';

const ReportsPage = () => {
  const [isLoading, setIsLoading] = useState(false);

  const reportTypes = [
    {
      name: 'Billable Organizations By Month',
      type: 'OrganzationsBillableByTicketCountOverMonths',
      extension: '.csv',
    },
    {
      name: 'Audit Scheduled Tickets',
      type: 'AuditScheduledTickets',
      extension: '.csv',
    },
    {
      name: 'Audit Scheduled Effectiveness',
      type: 'AuditScheduleEffectiveness',
      extension: '.csv',
    },
  ];

  const handleClickExportCSV = async (reportType) => {
    setIsLoading(true);
    try {
      const downloadLink = await reportsApi.downloadReportsCSV(reportType.type);
      if (downloadLink) {
        const a = document.createElement('a');
        a.href = downloadLink;
        a.download = `${reportType.type}${reportType.extension}`;
        a.click();
      }
    } catch (error) {
      setError(error, false, 'Download Reports CSV failed');
    }
    setIsLoading(false);
  };

  return (
    <div id="Organizations_container" className={styles.mainContainer}>
      <Typography variant="h6">On Demand Reports</Typography>
      <div className={styles.content}>
        {reportTypes.map((reportType) => (
          <li key={reportType.type}>
            <Button
              id="btn-export-csv"
              variant="tertiary"
              onClick={() => handleClickExportCSV(reportType)}
              disabled={isLoading}
            >
              {reportType.name}
            </Button>
          </li>
        ))}
      </div>
    </div>
  );
};

export default ReportsPage;
