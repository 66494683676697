import { useCallback, useState } from 'react';
import { debounce } from 'lodash';
import { observer } from 'mobx-react-lite';
import { SystemUpdateAlt } from '@mui/icons-material';

import { useMainStore } from '../../stores/useMainStore';
import { FilterPanel } from './FilterPanel';
import { Button, IconButton, MoreButtonPopover, SearchField, Typography } from '@/components';

import styles from './Filter.module.css';

const PAGE_NAME = 'TaskLog_Filter';

export const Filter = observer(() => {
  const [isPopoverOpen, setIsPopoverOpen] = useState(false);

  const tooglePopover = () => {
    setIsPopoverOpen(!isPopoverOpen);
  };

  const { filterStore, taskLogStore } = useMainStore();

  const searchChangeHandler = (event) => {
    filterStore.setSearch(event.target.value.trim());
  };

  const debouncedChangeHandler = useCallback(debounce(searchChangeHandler, 500), []);

  return (
    <div id={`${PAGE_NAME}_container`} className={styles.filtersRoot}>
      <div className={styles.filtersContainer}>
        <div className={styles.filterButtons} id={`${PAGE_NAME}_buttonGroupContainer`}>
          <SearchField
            id="txt-log-search"
            placeholder="Search by id or title"
            value={filterStore.search}
            classes={{ root: styles.searchRoot }}
            onChange={debouncedChangeHandler}
          />
        </div>
      </div>
      <div className={styles.actionsContainer}>
        <IconButton
          id="btn-export-csv"
          size="small"
          tooltipArrow
          tooltipTitle="Export the tickets to CSV"
          onClick={() => taskLogStore.exportToCSV()}
        >
          <SystemUpdateAlt fontSize="small" color="info" />
        </IconButton>
        <MoreButtonPopover
          tooltipText="Manage Filters"
          isPopoverOpen={isPopoverOpen}
        >
          <div className={styles.popoverHeader}>
            <Typography variant="subtitle1">Manage Filters</Typography>
            {!filterStore.getDefaultStatusSelected && (
              <Button
                id="btn-clear-filter"
                size="small"
                variant="tertiary"
                onClick={() => filterStore.clearFilter()}
              >
                Reset
              </Button>
            )}
          </div>
          <FilterPanel vertical onClose={tooglePopover} />
        </MoreButtonPopover>
      </div>
    </div>
  );
});
