import PropTypes from "prop-types";
import cx from "clsx";
import { Box, Tabs, Tab } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { theme } from "@/config";

const useStyles = makeStyles({
  tab: {
    color: `${theme.palette.secondary.darkBlue} !important`,
    fontSize: "14px !important",
    fontWeight: "700 !important",
    fontFamily: "Open Sans !important",
    lineHeight: "24px !important",
    textTransform: "none !important",
    minWidth: "0px !important",
    minHeight: "34px !important",
    height: 34,
    background: "transparent !important",
    padding: "0px !important",
  },
  disabled: {
    color: `${theme.palette.neutrals.greyMed} !important`,
  },
  selectedTabUnderline: {
    background: `${theme.palette.secondary.darkBlue}`,
    borderRadius: "2px",
    width: "100%",
    height: "3px",
  },
  circleIcon: {
    width: 8,
    height: 8,
    borderRadius: "50%",
  },
  badgeLabel: {
    display: "flex",
    alignItems: "center",
  },
  badgeCount: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: "18px",
    height: "18px",
    marginLeft: "6px",
    borderRadius: "50%",
    background: theme.palette.ui.error,
    fontWeight: 700,
    fontSize: "11px",
    lineHeight: "10px",
    letterSpacing: "0.1px",
    color: "#FFFFFF",
  },
  redDot: {
    marginLeft: "6px",
    width: "10px",
    height: "10px",
    borderRadius: "50%",
    background: theme.palette.ui.error,
  },
});

const IconTab = ({
  size,
  activeColor,
  inactiveColor,
  isSelected,
  label,
  badgeCount,
  disabled,
  ...otherProps
}) => {
  const styles = useStyles();
  const renderLabel = () => {
    if (badgeCount) {
      return (
        <div className={styles.badgeLabel}>
          <span>{label}</span>
          <div className={styles.redDot} />
        </div>
      );
    }

    return label;
  };

  return (
    <div>
      <Tab
        {...otherProps}
        label={renderLabel()}
        icon={
          activeColor ? (
            <div
              className={styles.circleIcon}
              style={{
                width: size,
                height: size,
                backgroundColor: isSelected ? activeColor : inactiveColor,
              }}
            />
          ) : null
        }
        iconPosition="end"
        classes={{
          root: cx(styles.tab, {
            [styles.tabSelected]: isSelected,
            [styles.disabled]: disabled,
          }),
        }}
        disabled={disabled}
      />
      {isSelected && <div className={styles.selectedTabUnderline} />}
    </div>
  );
};

export const IconTabs = ({
  tabArray,
  value,
  onChange,
  flexContainer,
  rootClassName,
  ...otherProps
}) => {
  return (
    <Box sx={{ height: "34px" }} {...otherProps}>
      <Tabs
        classes={{ flexContainer, root: rootClassName }}
        TabIndicatorProps={{
          sx: { backgroundColor: "transparent" },
        }}
        value={value}
        onChange={onChange}
        aria-label="icon position tabs example"
      >
        {tabArray.map((tab) => (
          <IconTab
            key={tab.value}
            tabIndex={0}
            {...tab}
            isSelected={value === tab.value}
            sx={{ height: "34px" }}
            {...otherProps}
            id={`Tabs_IconTab`}
          />
        ))}
      </Tabs>
    </Box>
  );
};

IconTab.propTypes = {
  isSelected: PropTypes.bool.isRequired,
  size: PropTypes.number,
  activeColor: PropTypes.string,
};

IconTab.defaultProps = {
  size: 8,
  activeColor: theme.palette.secondary.lightBlue,
  inactiveColor: theme.palette.neutrals.lightGrey,
};

export default IconTab;
