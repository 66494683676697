import { useMemo, useState } from 'react';
import { observer } from 'mobx-react-lite';
import {
  AmazonStatus,
  Button,
  Dropdown,
  InfoRow,
  MultiLineInput,
  TextField,
  TypeaheadDropdown,
  Typography,
} from '@/components';
import { BASE_ROUTES, ObjectType, PriorityOptions, UrlSearch } from '@/utils/types';
import { getHyperText } from '@/utils';
import { StatusSelect } from '../StatusSelect';
import { userStore } from '@/store';
import { useMainStore } from '../../stores/useMainStore';
import { AutomationLog } from '@/components/Table/AutomationLog';

import styles from './Information.module.css';
import { Config } from '@/config';

const PAGE_NAME = 'InformationPanel';

export const InformationPanel = observer(({ isQuestion, data, updateData, onCancel }) => {
  const {
    ticketId,
    organizationId,
    organization,
    title,
    priority,
    description,
    remainingWork,
    ownedBy,
    assignedTo,
    state,
    externalStatus,
    objectType,
    createdByName,
    automation,
    workflow,
    buildError,
  } = data;
  const { detailStore } = useMainStore();
  const [isEditing, setIsEditing] = useState(false);
  const [saveButtonClicked, setSaveButtonClicked] = useState(false);
  const hyperText = useMemo(() => getHyperText(description || ''), [description]);

  const handleSaveClicked = async () => {
    if (!isEditing) {
      setIsEditing(true);
    } else {
      setSaveButtonClicked(true);
      const { title, estimatedCompletion, objectType, remainingWork } = data;
      if (
        (objectType === ObjectType.question && title) ||
        (title && (objectType === ObjectType.task || remainingWork) && estimatedCompletion)
      ) {
        setSaveButtonClicked(false);
        setIsEditing(false);
        detailStore.updateTicket(data);
      }
    }
  };

  return (
    <div id={`${PAGE_NAME}_div`} className={styles.informationWrapper}>
      <InfoRow label="Ticket Id">
        <div className={styles.header}>
          <Typography variant="body2">
            <a
              href={`${Config.PORTAL_APP_LINK}/detail?ticket=${ticketId}`}
              target="_blank"
              rel="noopener noreferrer"
            >
              {ticketId}
            </a>
          </Typography>
          <div id={`${PAGE_NAME}_header`} className={styles.buttonsGroup}>
            <Button
              id={`${PAGE_NAME}_save_button`}
              size="small"
              variant="secondary"
              onClick={handleSaveClicked}
            >
              {isEditing ? 'Save' : 'Edit'}
            </Button>
            {isEditing && (
              <Button
                id={`${PAGE_NAME}_cancel_button`}
                size="small"
                variant="tertiary"
                onClick={() => {
                  onCancel();
                  setIsEditing(false);
                }}
              >
                Cancel
              </Button>
            )}
          </div>
        </div>
      </InfoRow>
      <InfoRow label="Organization">
        <Typography variant="body2"></Typography>
        <a
          href={`${BASE_ROUTES.organizations}?${UrlSearch.organization}=${organizationId}`}
          target="_blank"
          rel="noreferrer"
        >
          {organization}
        </a>
      </InfoRow>
      <InfoRow label="Title*">
        <MultiLineInput
          id={`${PAGE_NAME}_title_multiLineInput`}
          size="small"
          error={saveButtonClicked && !title}
          placeholder="Provide a title of the task"
          value={title}
          maxLength={160}
          onChange={(value) => updateData({ title: value })}
          style={{ flex: 1 }}
          disabled={!isEditing}
        />
      </InfoRow>
      {!isQuestion && (
        <>
          <InfoRow label="Priority*">
            <Dropdown
              id={`${PAGE_NAME}_priority_dropdown`}
              placeholder="Select Priority"
              value={priority}
              options={PriorityOptions}
              onChange={(e) => updateData({ priority: Number(e.target.value) })}
              style={{ flex: 1 }}
              disabled={!isEditing}
            />
          </InfoRow>
          <InfoRow label="Quantity*">
            <TextField
              type="number"
              id={`${PAGE_NAME}_quantity_textfield`}
              placeholder="Input Quantity"
              error={saveButtonClicked && !remainingWork}
              value={remainingWork}
              onChange={(e) => updateData({ remainingWork: Number(e.target.value) })}
              classes={{ flex: 1 }}
              disabled={!isEditing}
            />
          </InfoRow>
        </>
      )}
      <InfoRow label="Description">
        {!isEditing && (
          <Typography variant="body1" multiline sx={{ flex: 1 }}>
            <div dangerouslySetInnerHTML={{ __html: hyperText }} />
          </Typography>
        )}
        {isEditing && (
          <MultiLineInput
            id={`${PAGE_NAME}_description_multiLineInput`}
            placeholder="Provide a description of the task"
            value={description}
            size="small"
            onChange={(value) => updateData({ description: value })}
            style={{ flex: 1 }}
          />
        )}
      </InfoRow>
      <InfoRow label="Owner">
        <TypeaheadDropdown
          id={`${PAGE_NAME}_ownerDropdown`}
          value={ownedBy}
          options={detailStore.assignOptions}
          onChange={(value) => updateData({ ownedBy: value })}
          isDarkTheme
          disabled={!isEditing}
        />
      </InfoRow>
      <InfoRow label="Assigned to">
        <TypeaheadDropdown
          id={`${PAGE_NAME}_assignDropdown`}
          value={assignedTo}
          options={detailStore.assignOptions}
          onChange={(value) => updateData({ assignedTo: value })}
          isDarkTheme
          disabled={!isEditing}
        />
      </InfoRow>
      <InfoRow label="Status">
        <StatusSelect
          id={`${PAGE_NAME}_statusSelect`}
          value={state}
          options={userStore.states}
          onChange={(value) => updateData({ state: value })}
          isDarkTheme
          disabled={!isEditing}
        />
      </InfoRow>
      <InfoRow label="Creator">
        <Typography variant="body2">{createdByName}</Typography>
      </InfoRow>
      {objectType === ObjectType.case && externalStatus && (
        <InfoRow label="ExternalStatus">
          <AmazonStatus id={`${PAGE_NAME}_externalStatusSelect`} value={externalStatus} />
        </InfoRow>
      )}
      <InfoRow label="Workflow">
        <Typography variant="body2">{workflow}</Typography>
      </InfoRow>
      <InfoRow label="Automation">
        <AutomationLog automation={automation} isAdminUser />
      </InfoRow>
      <InfoRow label="Build Time">
        <Typography variant="body2">{automation?.automationTime}</Typography>
      </InfoRow>
      <InfoRow label="Build Error">
        <Typography variant="body2" className={styles.fullWidth}>
          {buildError}
        </Typography>
      </InfoRow>
    </div>
  );
});
