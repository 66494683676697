import PropTypes from 'prop-types';
import cx from 'clsx';
import { TextField } from '@mui/material';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DateTimePicker as MuiDateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import { makeStyles } from '@mui/styles';
import InfoIcon from '@mui/icons-material/Info';
import DateRangeIcon from '@mui/icons-material/DateRange';
import { theme } from '@/config';
import Tooltip from '../Tooltip';

const useStyles = makeStyles((theme) => ({
  inputRoot: {
    height: '46px',
    borderRadius: '8px',
    overflow: 'hidden',
    WebkitLineClamp: 1,
    WebkitBoxOrient: 'vertical',
    overflowWrap: 'break-word',
    marginTop: '25px !important',
    backgroundColor: '#FFFFFF',
    border: '1px solid #FFFFFF',
    padding: '0px 24px',
    width: '100%',
    '&:before': {
      borderBottom: 'none !important',
    },
    '&:after': {
      borderBottom: 'none !important',
    },
    '& .MuiFormLabel-root.Mui-focused': {
      color: theme.palette.primary.purple,
    },
    '& .MuiInput-root.Mui-focused  .MuiIconButton-root': {
      color: theme.palette.primary.purple,
    },
  },
  inputRootNoLabel: {
    marginTop: '0px !important',
  },
  smallWidth: {
    padding: '0px 12px',
  },
  textFieldBaseInput: {
    fontFamily: 'Open Sans !important',
    fontSize: '16px !important',
    fontWeight: '400 !important',
    lineHeight: '150% !important',
    letterSpacing: '0.15px !important',
    color: `${theme.palette.secondary.darkBlue} !important`,
  },
  smallSize: {
    fontSize: '14px !important',
  },
  textFieldInputFocus: {
    border: `1px solid ${theme.palette.primary.purple}`,
  },
  textFieldError: {
    border: `1px solid ${theme.palette.ui.error}`,
    '&:focus': {
      border: `1px solid ${theme.palette.ui.error}`,
    },
  },
  textFieldLabel: {
    fontFamily: 'Open Sans !important',
    fontWeight: '600 !important',
    fontSize: '14px !important',
    lineHeight: '19px !important',
    color: `${theme.palette.secondary.darkBlue} !important`,
    transform: 'none !important',
  },
  textFieldLabelError: {
    color: `${theme.palette.ui.error} !important`,
  },
  dateTimeHelperText: {
    fontFamily: 'Open Sans !important',
    fontWeight: '600 !important',
    fontSize: '14px !important',
    lineHeight: '19px !important',
    color: `${theme.palette.secondary.darkBlue} !important`,
    transform: 'none !important',
  },
  noBorder: {
    border: 'none !important',
  },
  paper: {
    marginBottom: '15px',
    marginTop: '15px',
    boxShadow: '0px 8px 25px rgb(0 0 0 / 15%) !important',
    borderRadius: '26px !important',
  },
  placeholder: {
    color: `${theme.palette.neutrals.greyMed} !important`,
  },
  tooltip: {
    maxWidth: '170px !important',
  },
  tooltipIcon: {
    paddingLeft: 8,
    fontSize: '13px !important',
  },
}));

export const DATE_TIME_FORMAT = 'MM/dd/yyyy hh:mm a';
export const DATE_FORMAT = 'MM/dd/yyyy';

const DateTimePicker = ({
  className,
  style,
  isDate,
  label,
  helperText,
  tooltipText,
  value,
  placeholder,
  isSmallSize,
  error,
  onChange,
  minDate,
  maxDate,
}) => {
  const styles = useStyles();

  const labelComponent = (
    <Tooltip placement="right" arrow title={tooltipText} className={styles.tooltip}>
      <div style={{ display: 'flex' }}>
        {label}
        {tooltipText && <InfoIcon className={styles.tooltipIcon} />}
      </div>
    </Tooltip>
  );

  if (isDate) {
    return (
      <div id="DateTimePicker_container_div" style={{ flex: 1, ...style }}>
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <DesktopDatePicker
            components={{
              OpenPickerIcon: DateRangeIcon,
            }}
            value={value}
            minDate={minDate}
            maxDate={maxDate}
            onChange={onChange}
            PaperProps={{
              className: styles.paper,
              sx: {
                '& .MuiButtonBase-root': {
                  '&.Mui-selected': {
                    backgroundColor: `${theme.palette.secondary.lightPurple} !important`,
                  },
                  ':hover': {
                    backgroundColor: '#E8E4F9',
                  },
                },
              },
            }}
            placeholder="Select Date"
            toolbarPlaceholder="Select Date"
            renderInput={(params) => (
              <TextField
                {...params}
                onKeyDown={(e) => e.preventDefault()}
                label={labelComponent}
                variant="standard"
                error={error}
                placeholder="Select Date"
                className={className}
                InputProps={{
                  ...params.InputProps,
                  classes: {
                    root: cx(styles.inputRoot, {
                      [styles.inputRootNoLabel]: !label,
                      [styles.smallWidth]: isSmallSize,
                    }),
                    input: cx(styles.textFieldBaseInput, {
                      [styles.placeholder]: !value,
                      [styles.smallSize]: isSmallSize,
                    }),
                    focused: styles.textFieldInputFocus,
                    error: styles.textFieldError,
                    notchedOutline: styles.noBorder,
                  },
                }}
                inputProps={{
                  ...params.inputProps,
                  value: !value ? placeholder : params.inputProps.value,
                }}
                InputLabelProps={{
                  classes: {
                    root: cx(styles.textFieldLabel, { [styles.textFieldLabelError]: error }),
                  },
                }}
                FormHelperTextProps={{
                  classes: { root: styles.dateTimeHelperText },
                }}
              />
            )}
          />
        </LocalizationProvider>
      </div>
    );
  }

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <MuiDateTimePicker
        components={{
          OpenPickerIcon: DateRangeIcon,
        }}
        value={value}
        minDate={minDate}
        maxDate={maxDate}
        onChange={onChange}
        PaperProps={{
          className: styles.paper,
          sx: {
            '& .MuiButtonBase-root': {
              '&.Mui-selected': {
                backgroundColor: `${theme.palette.secondary.lightPurple} !important`,
              },
              ':hover': {
                backgroundColor: '#E8E4F9',
              },
            },
            '& .MuiClockPicker-root': {
              '&.Mui-selected': {
                backgroundColor: `${theme.palette.secondary.lightPurple} !important`,
              },
            },
          },
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            onKeyDown={(e) => e.preventDefault()}
            placeholder={placeholder}
            label={labelComponent}
            variant="standard"
            error={error}
            className={className}
            inputProps={{
              ...params.inputProps,
              value: !value ? helperText : params.inputProps.value,
            }}
            InputProps={{
              ...params.InputProps,
              classes: {
                root: cx(styles.inputRoot, { [styles.inputRootNoLabel]: !label }),
                input: cx(styles.textFieldBaseInput, { [styles.placeholder]: !value }),
                focused: styles.textFieldInputFocus,
                error: styles.textFieldError,
                notchedOutline: styles.noBorder,
              },
            }}
            InputLabelProps={{
              classes: {
                root: cx(styles.textFieldLabel, { [styles.textFieldLabelError]: error }),
              },
            }}
          />
        )}
      />
    </LocalizationProvider>
  );
};

DateTimePicker.propTypes = {
  className: PropTypes.string,
  isDate: PropTypes.bool,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  error: PropTypes.bool,
  helperText: PropTypes.string,
};

DateTimePicker.defaultProps = {
  className: '',
  isDate: false,
  label: '',
  error: false,
  helperText: '',
};

export default DateTimePicker;
