import { useRef, useEffect, Fragment } from 'react';
import { debounce } from 'lodash';
import { observer } from 'mobx-react-lite';
import { MultiSelect, Checkbox, Typography, SearchField, Button } from '@/components';
import { useSettingsStore, userStore } from '@/store';
import { FilterType } from '@/utils/constants';

import styles from './FilterPanel.module.css';

const PAGE_NAME = 'Members_FilterPanel';

const FilterPanel = () => {
  const { accountStore } = useSettingsStore();
  const filterContainerRef = useRef(null);

  useEffect(() => {
    accountStore.setFilters(userStore.platformTypes);
  }, [userStore.platformTypes]);

  const renderValue = (selected, placeholder) => {
    if (selected.length === 0 && placeholder) {
      return <Typography variant="body1">{placeholder}</Typography>;
    }
    if (selected.length > 1) {
      return `${selected[0]} +${selected.length - 1} more`;
    }
    return selected[0];
  };

  return (
    <div className={styles.filtersGroup} ref={filterContainerRef} id={`${PAGE_NAME}_container`}>
      {Object.keys(accountStore.filters).map((filterKey) => {
        const { label, selectedValues, values, hasSelectAllClear } =
          accountStore.filters[filterKey];
        const options = values.map((item) => ({
          value: item.value,
          label: item.label,
          type: item.type,
        }));

        if (filterKey === FilterType.searchType) {
          return (
            <Fragment key={filterKey}>
              <SearchField
                id="organizations-search"
                placeholder="Search"
                value={accountStore.search}
                classes={{
                  root: styles.searchRoot,
                  container: styles.searchContainer,
                }}
                onChange={debounce((e) => accountStore.setSearch(e.target.value), 500)}
                helperText={`${accountStore.filteredOrganizations.length}/${accountStore.arrangedAccounts.length}`}
              />
              <Button
                id="btn-clear-filter"
                size="small"
                variant="tertiary"
                onClick={() => {
                  accountStore.expandAllNodes();
                }}
                sx={{
                  visibility:
                    accountStore.isEmptyFilter || accountStore.isSearchByPath
                      ? 'visible'
                      : 'hidden',
                }}
              >
                {accountStore.isExpandedAll ? 'Collapse All' : 'Expand All'}
              </Button>
            </Fragment>
          );
        }

        if (accountStore.isSearchByPath) return null;

        return (
          <div key={filterKey} className={styles.filterItem} id={`${PAGE_NAME}_filterDropdown`}>
            <MultiSelect
              value={selectedValues}
              key={filterKey}
              placeholder={label}
              options={options}
              hasSelectAllClear={hasSelectAllClear}
              style={{ width: '220px' }}
              id={`${PAGE_NAME}_paginationDropdown`}
              renderValue={renderValue}
              onChange={(value) => accountStore.updateFilterValues(filterKey, value)}
              MenuItemComponent={({ label, checked }) => {
                return <Checkbox label={label} checked={checked} id={`${PAGE_NAME}_checkbox`} />;
              }}
            />
          </div>
        );
      })}
    </div>
  );
};

export default observer(FilterPanel);
