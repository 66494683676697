import { useState } from "react";
import PropTypes from "prop-types";
import cx from "clsx";
import { Grid } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { Psychology } from "@mui/icons-material";
import { downloadFile, getFileType, isStorybook } from "@/utils";
import { DeleteIcon, SaveAltIcon } from "@/assets/icons";
import ImageIcon from "@/assets/icons/ImageFile.svg";
import Icon from "../Icon";
import LoadingIndicator from "../LoadingIndicator";

const useStyles = makeStyles((theme) => ({
  attachedFiles: {
    position: "relative",
    marginTop: 8,
    borderRadius: 16,
    background: "#fff",
    padding: "8px 24px 20px",
  },
  attachment: {
    marginTop: "8px",
    display: "flex",
    alignItems: "center",
    gap: "14px",
    paddingTop: "6px",
    paddingRight: "12px",
    overflow: "hidden",
    cursor: "pointer",
  },
  imageContainer: {
    position: "relative",
    height: "64px",
    width: "64px",
  },
  configIcon: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    background: theme.palette.neutrals.tableLightGray,
  },
  imgPreview: {
    width: 62,
    height: 62,
    border: "1px solid #E9EDF0",
    borderRadius: 12,
  },
  imgDefault: {
    width: 62,
    height: 62,
  },
  storybookPreview: {
    width: 64,
    height: 64,
    border: "1px solid #E9EDF0",
    borderRadius: 12,
  },
  deleteFileIcon: {
    position: "absolute",
    right: -8,
    top: -8,
    zIndex: 2,
    cursor: "pointer",
    color: "#929398 !important",
    "&:hover": {
      color: `${theme.palette.secondary.darkBlue} !important`,
    },
  },
  fileName: {
    fontWeight: 700,
    fontSize: "16px",
    lineHeight: "150%",
    letterSpacing: "0.15px",
    color: theme.palette.primary.purple,
    overflowWrap: "anywhere",
    whiteSpace: "normal",
    overflow: "hidden",
    textOverflow: "ellipsis",
    "-webkit-box-orient": "vertical",
    "-webkit-line-clamp": 2,
    height: "fit-content",
    display: "-webkit-box",
  },
  deleteFile: {
    position: "absolute",
    background: "rgba(0, 0, 0, 0.5)",
    zIndex: 2,
    width: 65,
    height: 64,
    borderRadius: 10,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    color: "#fff",
    fontSize: "13px",
  },
  uploadingFile: {
    maxWidth: "100px",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  uploadingLabel: {
    fontWeight: 600,
    fontSize: "14px",
    color: theme.palette.secondary.darkBlue,
    whiteSpace: "nowrap",
  },
  hoveredFile: {
    position: "absolute",
    top: 0,
    background: "rgba(0, 0, 0, 0.5)",
    width: 64,
    height: 64,
    borderRadius: 12,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  downloadIcon: {
    color: "#fff",
  },
}));

const PAGE_NAME = "AttachmentArea";
const MAX_WORDS = 25;

export function AttachmentArea({
  files,
  configFiles,
  addingFiles,
  deletingFiles,
  onDeleteFile,
  containerClassName,
}) {
  const styles = useStyles();
  const [hoveredId, setHoveredId] = useState(null);
  const allFiles = (files || []).concat(configFiles || []);

  const firstWords = (name) => {
    if (name.length >= MAX_WORDS) return name.substring(0, MAX_WORDS / 2);
    return name;
  };
  const secondWords = (name) => {
    if (name.length >= MAX_WORDS)
      return `...${name.substring(name.length + 3 - MAX_WORDS / 2)}`;
    return null;
  };

  const handleDownloadFile = (file) => {
    if (!file.id) {
      window.open(file.preview, "_blank");
    } else {
      downloadFile(file);
    }
  };

  return (
    <Grid container className={cx(styles.attachedFiles, containerClassName)}>
      {allFiles.map((file) => (
        <Grid key={file.id} item xs={6}>
          <div
            id={`${PAGE_NAME}_AttachedFiles_fileInfo`}
            className={styles.attachment}
          >
            <div
              className={styles.imageContainer}
              onMouseEnter={() => setHoveredId(file.id || file.name)}
              onMouseLeave={() => setHoveredId(null)}
              onClick={() => handleDownloadFile(file)}
            >
              {file.hasWorkflowContent && (
                <div className={cx(styles.imgPreview, styles.configIcon)}>
                  <Psychology fontSize="large" />
                </div>
              )}
              {getFileType(file.name) === "image" ? (
                <img
                  className={
                    isStorybook() ? styles.storybookPreview : styles.imgPreview
                  }
                  src={file.preview || file.url}
                  alt=" "
                  onError={(event) => {
                    event.target.src = ImageIcon;
                    event.onerror = null;
                    event.target.className = styles.imgDefault;
                  }}
                />
              ) : (
                <Icon type="doc" width={64} height={64} />
              )}

              {((file.id && hoveredId === file.id) ||
                (!file.id && hoveredId === file.name)) && (
                <div className={styles.hoveredFile}>
                  <SaveAltIcon className={styles.downloadIcon} />
                </div>
              )}

              {onDeleteFile && (
                <DeleteIcon
                  id={`${PAGE_NAME}_AttachedFiles_fileIcon`}
                  className={styles.deleteFileIcon}
                  onClick={(e) => {
                    e.stopPropagation();
                    onDeleteFile(file);
                  }}
                />
              )}
            </div>
            <span
              className={styles.fileName}
              onMouseEnter={() => setHoveredId(file.id || file.name)}
              onMouseLeave={() => setHoveredId(null)}
              onClick={() => handleDownloadFile(file)}
            >
              {file.name}
            </span>
            {deletingFiles?.find((item) => item.id === file.id) && (
              <div className={styles.deleteFile}>Deleting..</div>
            )}
          </div>
        </Grid>
      ))}

      {addingFiles?.map((addingFile, index) => (
        <Grid
          item
          xs={6}
          key={`${addingFile.name}_${index}`}
          className={styles.attachment}
        >
          <div id={`${PAGE_NAME}_addedFile`} className={styles.uploadingFile}>
            <LoadingIndicator width={60} />
            <span className={styles.uploadingLabel}>
              {firstWords(addingFile.name)}
            </span>
            {secondWords(addingFile.name) && (
              <span className={styles.uploadingLabel}>
                {secondWords(addingFile.name)}
              </span>
            )}
          </div>
        </Grid>
      ))}
    </Grid>
  );
}

AttachmentArea.propTypes = {
  files: PropTypes.arrayOf(PropTypes.shape({})),
  configFiles: PropTypes.arrayOf(PropTypes.shape({})),
  addingFiles: PropTypes.arrayOf(PropTypes.shape({})),
  deletingFiles: PropTypes.arrayOf(PropTypes.shape({})),
  onDeleteFile: PropTypes.func,
};

AttachmentArea.defaultProps = {
  files: [],
  configFiles: [],
  addingFiles: [],
  deletingFiles: [],
  onDeleteFile: undefined,
};
