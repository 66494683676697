import PropTypes from 'prop-types';
import TableCell from '@mui/material/TableCell';
import cx from 'clsx';
import { useStyles } from './sharedStyles';

export const TextLinkCell = ({ column, value, match, link }) => {
  const styles = useStyles();

  return (
    <TableCell key={column} className={cx(styles.bodyCell, styles.textCell)}>
      {Boolean(value) && (
        <a
          href={link}
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
            window.open(link, '_blank');
          }}
        >
          <div className={styles.textLabel}>
            {match ? (
              <>
                {value.slice(0, match[0])}
                <span style={{ fontWeight: 700, backgroundColor: '#E8E4F9' }}>
                  {value.slice(match[0], match[1])}
                </span>
                {value.slice(match[1])}
              </>
            ) : (
              value
            )}
          </div>
        </a>
      )}
    </TableCell>
  );
};

TextLinkCell.propTypes = {
  column: PropTypes.string.isRequired,
};
