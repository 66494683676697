import { useState } from 'react';
import cx from 'clsx';
import InputAdornment from '@mui/material/InputAdornment';
import { makeStyles } from '@mui/styles';
import TextField from '../TextField';
import { VisibilityOutlined, VisibilityOffOutlined } from '@mui/icons-material';
import InfoRow from '../InfoRow';

const useStyles = makeStyles((theme) => ({
  searchField: {
    position: 'absolute',
    right: '0px',
    marginTop: '-1px',
    color: `${theme.palette.secondary.darkBlue} !important`,
    '&:hover': {
      cursor: 'pointer',
    },
  },
}));

const PasswordField = ({
  label,
  defaultValue,
  disabled,
  onChange,
  setShowCode,
  children,
  ...rest
}) => {
  const styles = useStyles();
  const [showPassword, setShowPassword] = useState(false);

  const handleTogglePassword = () => {
    setShowPassword((prev) => !prev);
    if (setShowCode) setShowCode((prev) => !prev);
  };

  return (
    <InfoRow label={label} fullWidth>
      <TextField
        {...rest}
        type={showPassword ? '' : 'password'}
        onChange={onChange}
        disabled={disabled}
        endAdornment={
          <InputAdornment
            position="start"
            classes={{
              root: cx(styles.searchField),
            }}
          >
            {showPassword ? (
              <VisibilityOffOutlined fontSize="small" onClick={handleTogglePassword} />
            ) : (
              <VisibilityOutlined fontSize="small" onClick={handleTogglePassword} />
            )}
          </InputAdornment>
        }
      />
      {children}
    </InfoRow>
  );
};

export default PasswordField;
